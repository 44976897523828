/* eslint-disable react/jsx-props-no-spreading */
import { ICourseDto } from 'api/CourseDto';
import { ICourseGuestDto } from 'api/CourseGuestDto';
import { EFeatureFlagKeys, IFeatureFlagDto } from 'api/FeatureFlagsDto';
import {
  useIsLargeRoom,
  useIsLargeRoomFeatureFlagEnabled,
} from 'components/large-room/hooks';
import { useNotification } from 'components/notification';
import {
  GDM_OPTIONS_SCREENSHARE,
  STORAGE_PREVIOUS_MEDIA_DEVICE_PREFERENCE_KEY,
} from 'consts';
import { isFunction } from 'lodash';
import { EBroadcastMode, TBroadcastInfoDto } from 'models/BroadcastInfoDto';
import { path } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { isIOS, isMobile, isTablet } from 'react-device-detect';
import { store } from 'store';
import { EBroadcastCommands, useBroadcast } from 'util/broadcast';
import { trackError } from 'util/trackError';
import { useIsSmallDevice } from 'util/use-is-small-device';
import { useMappedState, useStoreValue } from 'util/use-mapped-state';
import { useStorePath } from 'util/use-store-path';

interface IBroadcastButtonProps {
  isScreenshare?: boolean;
}

export const BroadcastButton: React.FC<IBroadcastButtonProps> = ({
  isScreenshare,
  ...props
}) => {
  const isUpdatingBroadcast = useStoreValue(store, 'isUpdatingBroadcast');
  const { broadcast } = useBroadcast();
  const { showNotification } = useNotification();

  return (
    <button
      disabled={isUpdatingBroadcast}
      onClick={(ev) => {
        ev.preventDefault();
        store.setState({ isUpdatingBroadcast: true, popup: undefined });
        Promise.resolve(true)
          .then(() => {
            if (isScreenshare) {
              if (
                'getDisplayMedia' in navigator.mediaDevices &&
                isFunction(navigator.mediaDevices.getDisplayMedia)
              ) {
                return navigator.mediaDevices
                  .getDisplayMedia(GDM_OPTIONS_SCREENSHARE)
                  .then((stream) => {
                    store.setState({ screenshareStream: stream });
                    return broadcast({
                      type: EBroadcastCommands.Screenshare,
                      start: true,
                    });
                  });
              }
              throw new Error('Unsupported method');
            }
            return broadcast({
              type: EBroadcastCommands.Yourself,
              start: true,
            });
          })
          .then((res) => {
            if (res.isErr()) {
              throw res;
            }
            if (
              localStorage.getItem(
                STORAGE_PREVIOUS_MEDIA_DEVICE_PREFERENCE_KEY
              ) === null &&
              !isScreenshare
            ) {
              localStorage.setItem(
                STORAGE_PREVIOUS_MEDIA_DEVICE_PREFERENCE_KEY,
                JSON.stringify({
                  isVideoDisabled: store.getState().isVideoDisabled ?? false,
                })
              );
            }
            store.setState({
              isAudioDisabled: false,
              isVideoDisabled: false,
            });
          })
          .catch((err) => {
            store.setState({ screenshareStream: undefined });
            if (err?.message.includes('Permission denied by system')) {
              showNotification({
                type: 'error',
                message: i18n(
                  'error.unable_to_screenshare_privacy_preferences',
                  'Unable to screenshare, please enable screen sharing from your privacy preferences'
                ),
              });
              return;
            }
            if (!err.message.includes('Unsupported method')) {
              trackError('Broadcast: unable to start broadcast', err);
            } else {
              showNotification({
                type: 'error',
                message: i18n(
                  'error.unable_to_screenshare',
                  'Unable to screenshare, try again later.'
                ),
              });
            }
          })
          .finally(() => {
            store.setState({ isUpdatingBroadcast: false });
          });
      }}
      {...props}
    />
  );
};

export const useStopBroadcastingAction = ({
  meAsGuest,
  broadcastInfo,
  force = false,
  courseId,
}: {
  meAsGuest?: ICourseGuestDto;
  broadcastInfo?: TBroadcastInfoDto;
  force?: boolean;
  courseId?: string;
}) => {
  const { broadcast } = useBroadcast();
  const { isLargeRoom } = useIsLargeRoom();

  const stopRequest = useCallback(() => {
    if (
      !force &&
      (!meAsGuest ||
        !meAsGuest.user ||
        !(meAsGuest.host || meAsGuest.admin) ||
        !broadcastInfo)
    ) {
      return;
    }
    const iAmBroadcasting = broadcastInfo?.user_id === meAsGuest?.user.id;

    if (!iAmBroadcasting && !force) {
      return;
    }
    store.setState({ isUpdatingBroadcast: true });
    broadcast({ type: EBroadcastCommands.Stop }, courseId ?? meAsGuest?.course)
      .then((res) => {
        if (res.isErr()) {
          throw res.error;
        }
        store.setState({ popup: undefined });
        const prevMediaPreferencesJson = localStorage.getItem(
          STORAGE_PREVIOUS_MEDIA_DEVICE_PREFERENCE_KEY
        );
        if (prevMediaPreferencesJson && !isLargeRoom) {
          const prevMediaPreferences = JSON.parse(prevMediaPreferencesJson) as {
            isVideoDisabled: boolean;
            isAudioDisabled: boolean;
          };
          store.setState({
            isVideoDisabled: prevMediaPreferences.isVideoDisabled,
            isAudioDisabled: true,
          });
          localStorage.removeItem(STORAGE_PREVIOUS_MEDIA_DEVICE_PREFERENCE_KEY);
        }
      })
      .catch((err) => {
        if (
          !err.error.includes('There is currently no broadcast in progress')
        ) {
          trackError('Broadcast: unable to stop broadcast', err);
        }
      })
      .finally(() => {
        store.setState({ isUpdatingBroadcast: false });
      });
  }, [meAsGuest, broadcastInfo, force, broadcast, courseId, isLargeRoom]);

  return { stopRequest };
};

interface IStopBroadcastButtonForMenuProps {
  isVideo: boolean;
  broadcastInfo: TBroadcastInfoDto;
  courseId: string;
}

const StopBroadcastButtonForMenu: React.FC<IStopBroadcastButtonForMenuProps> =
  ({ isVideo = false, broadcastInfo, courseId }) => {
    const { isLargeRoomFeatureFlagEnabled } =
      useIsLargeRoomFeatureFlagEnabled(courseId);

    const stopBroadcastText = useMemo(() => {
      if (broadcastInfo?.broadcast_mode === EBroadcastMode.Vimeo) {
        return i18n('text.stop_broadcast', 'Stop Broadcast');
      }
      if (broadcastInfo?.broadcast_mode === EBroadcastMode.Screenshare) {
        return i18n('text.stop_screenshare', 'Stop Screen Share');
      }
      return i18n('text.stop_spotlight', 'Stop Spotlight');
    }, [broadcastInfo]);

    return (
      <span className="row">
        <span className={`icon ${isVideo ? 'video-live' : 'broadcast-live'}`} />
        <span className="text">
          {isLargeRoomFeatureFlagEnabled
            ? stopBroadcastText
            : i18n('text.stop_broadcasting', 'Stop Broadcasting')}
        </span>
      </span>
    );
  };

interface IStopBroadcastButtonProps {
  meAsGuest: ICourseGuestDto;
  broadcastInfo: TBroadcastInfoDto;
  className?: string;
  isInHeader?: boolean;
}

export const StopBroadcastButton: React.FC<IStopBroadcastButtonProps> = ({
  meAsGuest,
  broadcastInfo,
  isInHeader,
  children,
  ...props
}) => {
  const [isUpdatingBroadcast] = useStorePath<boolean>(store, [
    'isUpdatingBroadcast',
  ]);
  const { stopRequest } = useStopBroadcastingAction({
    meAsGuest,
    broadcastInfo,
  });
  return (
    <button
      data-testid={
        isInHeader
          ? 'Page_Video_Call_button_stop_broadcasting'
          : 'Page_Video_Call_button_stop_broadcasting_menu_item'
      }
      disabled={isUpdatingBroadcast}
      onClick={(ev) => {
        ev.preventDefault();
        stopRequest();
      }}
      {...props}
    >
      {children}
    </button>
  );
};

interface IBroadcastButtonsProps {
  course: ICourseDto;
  broadcastInfo: TBroadcastInfoDto;
  meAsGuest: ICourseGuestDto;
}

export const BroadcastButtons: React.FC<IBroadcastButtonsProps> = ({
  course,
  broadcastInfo,
  meAsGuest,
}) => {
  const [isUpdatingBroadcast] = useStorePath<boolean>(store, [
    'isUpdatingBroadcast',
  ]);
  const iAmBroadcasting = broadcastInfo.user_id === meAsGuest.user.id;
  const someoneElseIsBroadcasting = broadcastInfo.user_id && !iAmBroadcasting;
  const isBroadcastingYourself =
    iAmBroadcasting && broadcastInfo.broadcast_mode === 'yourself';
  const isBroadcastingScreenshare =
    iAmBroadcasting && broadcastInfo.broadcast_mode === 'screenshare';
  const isBroadcastingVideo =
    iAmBroadcasting && broadcastInfo.broadcast_mode === 'vimeo';
  const { isLargeRoomFeatureFlagEnabled } = useIsLargeRoomFeatureFlagEnabled(
    course.id
  );
  const isSmallDevice = useIsSmallDevice();

  const featureFlags = useMappedState<IFeatureFlagDto[]>(
    store,
    path(['featureFlags'])
  );
  const screenshareFlag = featureFlags?.find(
    (flag) => flag.name === EFeatureFlagKeys.Screenshare
  );
  const storeVideoState = useCallback(() => {
    if (
      localStorage.getItem(STORAGE_PREVIOUS_MEDIA_DEVICE_PREFERENCE_KEY) ===
      null
    ) {
      localStorage.setItem(
        STORAGE_PREVIOUS_MEDIA_DEVICE_PREFERENCE_KEY,
        JSON.stringify({
          isVideoDisabled: store.getState().isVideoDisabled ?? false,
        })
      );
    }
  }, []);
  return (
    <>
      {iAmBroadcasting && (
        <StopBroadcastButton
          meAsGuest={meAsGuest}
          broadcastInfo={broadcastInfo}
        >
          <StopBroadcastButtonForMenu
            isVideo={isBroadcastingVideo}
            broadcastInfo={broadcastInfo}
            courseId={course.id}
          />
        </StopBroadcastButton>
      )}
      {(!isBroadcastingScreenshare || someoneElseIsBroadcasting) &&
        !isSmallDevice &&
        !isTablet &&
        !isIOS &&
        !isMobile &&
        screenshareFlag?.isActive && (
          <BroadcastButton
            isScreenshare
            data-testid="Page_Video_Call_button_broadcast_screenshare"
          >
            <span className="row">
              <span className="icon broadcast-screenshare" />
              <span className="text">
                {i18n('text.broadcast_screenshare', 'Screen Share')}
              </span>
            </span>
          </BroadcastButton>
        )}
      {(!isBroadcastingYourself || someoneElseIsBroadcasting) && (
        <BroadcastButton data-testid="Page_Video_Call_button_broadcast_yourself">
          <span className="row">
            <span className="icon broadcast-offline" />
            <span className="text">
              {isLargeRoomFeatureFlagEnabled
                ? i18n('text.spotlight', 'Spotlight')
                : i18n('text.broadcast_self', 'Broadcast Yourself')}
            </span>
          </span>
        </BroadcastButton>
      )}
      {(!isBroadcastingVideo || someoneElseIsBroadcasting) && (
        <button
          data-testid="Page_Video_Call_button_broadcast_video"
          disabled={isUpdatingBroadcast}
          onClick={() => {
            store.setState({
              popup: undefined,
              modal: 'VideoUrlModal',
              modalState: { course, me: meAsGuest.user },
            });
            storeVideoState();
          }}
        >
          <span className="row">
            <span className="icon video-offline" />
            <span className="text">
              {i18n('text.broadcast_video', 'Broadcast Video')}
            </span>
          </span>
        </button>
      )}
    </>
  );
};
