import { API_URL } from 'consts';
import { Result } from 'neverthrow';
import { IFeatureFlagDto } from './FeatureFlagsDto';
import { TApiError, Fetcher } from './Fetcher';

export const requestFeatureFlags: () => Promise<
  Result<IFeatureFlagDto[], TApiError>
> = async () => {
  return Fetcher.makeRequest<IFeatureFlagDto[]>(`${API_URL}/featureflags`, {
    noAuthToken: true,
    method: 'GET',
  });
};
