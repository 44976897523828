/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRollbar } from '@rollbar/react';
import { ICourseGuestDto } from 'api/CourseGuestDto';
import { IFeatureFlagDto } from 'api/FeatureFlagsDto';
import { Fetcher } from 'api/Fetcher';
import {
  useIsLargeRoom,
  useIsLargeRoomFeatureFlagEnabled,
} from 'components/large-room/hooks';
import { useNotification } from 'components/notification';
import {
  DOMINANT_SPEAKER_CHANGE_MS,
  GRID_VIEW_MAX,
  MOBILE_GRID_VIEW_MAX,
  SPEAKER_VIEW_MAX,
} from 'consts';
import { throttle } from 'lodash';
import { EBroadcastMode, TBroadcastInfoDto } from 'models/BroadcastInfoDto';
import { useRouter } from 'next/router';
import { path } from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isIOS } from 'react-device-detect';
import { useLatest, useMountedState, usePrevious } from 'react-use';
import { clearRequests, dispatch, store } from 'store';
import * as Video from 'twilio-video';
import { expiredTokenUpdate } from 'util/expired-token-update';
import { withTimeout } from 'util/timeout-promise';
import { trackError } from 'util/trackError';
import { twilioIdentity as identity } from 'util/twilio-identity';
import { url } from 'util/url';
import { useAutoplayTrack } from 'util/use-autoplay-track';
import { useCreateOrGetLocalAudioTrack } from 'util/use-create-or-get-local-audio-track';
import { useCreateOrGetLocalVideoTrack } from 'util/use-create-or-get-local-video-track';
import { useIsSmallDevice } from 'util/use-is-small-device';
import { useMappedState, useStoreValue } from 'util/use-mapped-state';
import { useRequest } from 'util/use-request';
import { useStorePath, useStoreState } from 'util/use-store-path';
import { useUnmountedRef } from 'util/use-unmounted-ref';
import { useTrackDimensions } from './useTrackDimensions';

const useProcessKickedUser = () => {
  const router = useRouter();
  const { showNotification } = useNotification();

  const processKickedUser = useCallback(
    (err) => {
      if (err.message === 'Failed to fetch') return;
      showNotification({
        message: i18n(
          'error.youre_not_enrolled',
          'You are not enrolled in this Alpha.'
        ),
      });
      dispatch(clearRequests((uuid: string) => uuid.indexOf('courses') > -1));
      router.push(url('guest.dashboard'));
    },
    [router, showNotification]
  );

  return { processKickedUser };
};

export const useToken = ({
  courseId,
  isLargeRoom,
}: {
  courseId: string;
  isLargeRoom?: boolean;
}) => {
  const [token, setToken] = useStoreState(store, 'videoToken');
  const [shouldUpdateToken, setShouldUpdateToken] = useStoreState(
    store,
    'shouldUpdateVideoToken'
  );
  const isLargeRoomPrev = usePrevious(isLargeRoom);
  const isLargeRoomLatest = useLatest(isLargeRoom);

  const { processKickedUser } = useProcessKickedUser();

  useEffect(() => {
    if (isLargeRoom !== isLargeRoomPrev) {
      setToken(undefined);
      setShouldUpdateToken(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLargeRoom, isLargeRoomPrev]);

  useEffect(() => {
    if (!shouldUpdateToken) {
      return;
    }
    if (shouldUpdateToken) {
      setShouldUpdateToken(false);
    }
    let urlString = url('api.courseVideoGrantToken', { args: { courseId } });
    if (isLargeRoom) {
      urlString = url('api.largeRoomVideoGrantToken', { args: { courseId } });
    }
    Fetcher.makeRequest<{ result: string }>(urlString, {
      method: 'POST',
      data: {},
    }).then((res) => {
      if (res.isErr()) {
        trackError('Error during getting video call token', res.error);
        processKickedUser(res.error.error);
      } else {
        if (isLargeRoomLatest.current !== isLargeRoom) {
          return;
        }
        setToken(res.value.result);
        expiredTokenUpdate(res.value.result, () => setShouldUpdateToken(true));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    courseId,
    isLargeRoom,
    processKickedUser,
    shouldUpdateToken,
    isLargeRoomLatest,
  ]);
  return token;
};

export const useBreakoutRoomToken = ({
  breakoutRoomId,
}: {
  breakoutRoomId?: string;
}) => {
  const [token, setToken] = useStorePath<string | undefined>(store, [
    'breakoutRoomToken',
  ]);
  const { processKickedUser } = useProcessKickedUser();
  useEffect(() => {
    if (!breakoutRoomId) {
      setToken(undefined);
      return;
    }
    if (token) return;
    Fetcher.makeRequest<{ token: string }, { result: string }>(
      url('api.breakoutRoomVideoToken'),
      {
        method: 'POST',
        data: {
          id: breakoutRoomId,
        },
      }
    ).then((res) => {
      if (res.isErr()) {
        if (
          res.error.code === 403 &&
          res.error.error?.result === 'guest has been kicked'
        ) {
          processKickedUser(new Error('guest has been kicked'));
        }
        setToken(undefined);
        trackError('Error during getting breakout room token', res.error);
      } else {
        setToken(res.value.token);
        expiredTokenUpdate(res.value.token, () => setToken(undefined));
      }
    });
  }, [breakoutRoomId, processKickedUser, setToken, token]);
  return token;
};

interface IVideoTrackProps {
  track: Video.RemoteVideoTrack | Video.LocalVideoTrack | null;
  onUpdatePortraitMode?: (isPortrait: boolean) => void;
  isScreenshare?: boolean;
}

export const VideoTrack: React.FC<IVideoTrackProps> = ({
  track,
  children,
  onUpdatePortraitMode,
  isScreenshare,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLMediaElement>();
  const { isPortrait } = useTrackDimensions(track);

  const { forceAutoplay } = useAutoplayTrack();

  useEffect(() => {
    if (!track) return;
    videoRef.current = track.attach();
    videoRef.current.muted = true;
    ref.current?.append(videoRef.current);
    forceAutoplay(videoRef.current);
  }, [forceAutoplay, track]);

  useEffect(() => {
    if (onUpdatePortraitMode && typeof onUpdatePortraitMode === 'function') {
      onUpdatePortraitMode(isPortrait);
    }
  }, [isPortrait, onUpdatePortraitMode]);

  return (
    <div
      className={`twilio-video-container${isScreenshare ? ' screenshare' : ''}${
        isPortrait ? ' portrait' : ''
      }`}
      ref={ref}
      // @ts-ignore
      key={track?.sid}
    >
      {children}
    </div>
  );
};

export const AudioTrack = ({
  track,
  activeSinkId,
}: {
  track: Video.RemoteAudioTrack;
  activeSinkId?: string;
}) => {
  const audioRef = useRef<HTMLMediaElement>();

  const { forceAutoplay } = useAutoplayTrack();

  useEffect(() => {
    audioRef.current = track.attach();
    document.body.appendChild(audioRef.current);
    forceAutoplay(audioRef.current);
    return () => {
      track.detach().forEach((el) => el.remove());
    };
  }, [forceAutoplay, track]);

  useEffect(() => {
    if (activeSinkId && path(['current', 'setSinkId'], audioRef)) {
      // @ts-ignore
      audioRef.current?.setSinkId(activeSinkId);
    }
  }, [activeSinkId]);

  return null;
};

export const useDominantSpeaker = ({
  room,
  courseId,
}: {
  room?: Video.Room;
  courseId: string;
}) => {
  const isMounted = useMountedState();
  const [dominantSpeaker, setDominantSpeaker] = useState(
    room && room.dominantSpeaker
  );
  const { isLargeRoomFeatureFlagEnabled } =
    useIsLargeRoomFeatureFlagEnabled(courseId);

  useEffect(() => {
    if (isLargeRoomFeatureFlagEnabled || dominantSpeaker) {
      store.setState({
        dominantSpeaker: dominantSpeaker
          ? identity(dominantSpeaker).id
          : dominantSpeaker,
      });
    }
  }, [dominantSpeaker, isLargeRoomFeatureFlagEnabled]);

  const onDominantSpeakerChanged = useCallback(
    (participant: Video.RemoteParticipant) => {
      if (isMounted()) {
        setDominantSpeaker(participant);
      }
    },
    [isMounted]
  );

  useEffect(() => {
    if (!room) return;
    let unmounted = false;

    const onParticipantDisconnected = (participant: Video.RemoteParticipant) =>
      !unmounted &&
      setDominantSpeaker((prevDominantSpeaker) =>
        prevDominantSpeaker === participant ? null : prevDominantSpeaker
      );
    room.on('dominantSpeakerChanged', onDominantSpeakerChanged);
    room.on('participantDisconnected', onParticipantDisconnected);
    return () => {
      unmounted = true;
      room.removeListener('dominantSpeakerChanged', onDominantSpeakerChanged);
      room.removeListener('participantDisconnected', onParticipantDisconnected);
    };
  }, [onDominantSpeakerChanged, room]);

  return dominantSpeaker;
};

export const useParticipants = ({
  room,
  broadcastInfo,
  courseId,
}: {
  room?: Video.Room;
  broadcastInfo?: TBroadcastInfoDto;
  courseId: string;
}) => {
  const dominantSpeaker = useDominantSpeaker({ room, courseId });
  const [participants, setParticipants] = useState<Video.RemoteParticipant[]>(
    []
  );
  const featureFlags = useMappedState<IFeatureFlagDto[]>(
    store,
    path(['featureFlags'])
  );
  const gridView = useStoreValue(store, 'gridView');
  const { isLargeRoom } = useIsLargeRoom();
  const isSmallDevice = useIsSmallDevice();
  const { isLargeRoomFeatureFlagEnabled } =
    useIsLargeRoomFeatureFlagEnabled(courseId);
  const [speakerViewMax = SPEAKER_VIEW_MAX] = useStorePath<number | undefined>(
    store,
    ['largeRoom', 'speakerViewMax']
  );

  useEffect(() => {
    if (!room) {
      setParticipants([]);
    }
  }, [broadcastInfo, room]);

  const handleChangeDominantParticipant = useMemo(() => {
    return throttle(
      (dominantSpeaker: Video.RemoteParticipant | null | undefined) => {
        if (!dominantSpeaker) {
          return;
        }
        setParticipants((participants) => [
          dominantSpeaker,
          ...participants.filter(
            (p) => identity(dominantSpeaker).id !== identity(p).id
          ),
        ]);
      },
      DOMINANT_SPEAKER_CHANGE_MS
    );
  }, []);

  useEffect(() => {
    if (
      dominantSpeaker &&
      participants.length > 0 &&
      dominantSpeaker !== participants[0] &&
      isLargeRoomFeatureFlagEnabled
    ) {
      if (isLargeRoom) {
        if (gridView) {
          if (!isSmallDevice) {
            if (
              participants
                .slice(0, GRID_VIEW_MAX - 1)
                .map((p) => identity(p).id)
                .includes(identity(dominantSpeaker).id)
            ) {
              return;
            }
          } else if (
            participants
              .slice(0, MOBILE_GRID_VIEW_MAX - 1)
              .map((p) => identity(p).id)
              .includes(identity(dominantSpeaker).id)
          ) {
            return;
          }
        } else if (!isSmallDevice) {
          const identities = participants.map((p) => identity(p).id);
          if (broadcastInfo?.broadcast_mode === EBroadcastMode.Screenshare) {
            if (
              identities
                .filter((i) => i !== broadcastInfo.user_id)
                .slice(0, speakerViewMax - 2)
                .includes(identity(dominantSpeaker).id)
            ) {
              return;
            }
          } else if (
            broadcastInfo?.broadcast_mode === EBroadcastMode.Yourself
          ) {
            if (identities.includes(broadcastInfo.user_id)) {
              if (
                identities
                  .filter((i) => i !== broadcastInfo.user_id)
                  .slice(0, speakerViewMax - 1)
                  .includes(identity(dominantSpeaker).id)
              ) {
                return;
              }
            } else if (
              identities
                .slice(0, speakerViewMax)
                .includes(identity(dominantSpeaker).id)
            ) {
              return;
            }
          }
        }
      }
      handleChangeDominantParticipant(dominantSpeaker);
    }
  }, [
    broadcastInfo,
    dominantSpeaker,
    featureFlags,
    gridView,
    handleChangeDominantParticipant,
    isLargeRoom,
    isLargeRoomFeatureFlagEnabled,
    isSmallDevice,
    participants,
    speakerViewMax,
  ]);

  useEffect(() => {
    if (!room) return;
    setParticipants(Array.from(room.participants.values()));
    const participantConnected = (participant: Video.RemoteParticipant) => {
      const participantsFiltered = Array.from(
        room.participants.values()
      ).filter((p) => identity(participant).id !== identity(p).id);
      setParticipants([...participantsFiltered, participant]);
    };
    const participantDisconnected = (participant: Video.RemoteParticipant) => {
      const participantsFiltered = Array.from(
        room.participants.values()
      ).filter((p) => identity(participant).id !== identity(p).id);
      setParticipants([...participantsFiltered]);
    };
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    return () => {
      room.removeListener('participantConnected', participantConnected);
      room.removeListener('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  return participants;
};

const useBroadcaster = ({
  participants,
  broadcastInfo,
}: {
  participants: Video.Participant[];
  broadcastInfo?: TBroadcastInfoDto;
}) => {
  const [broadcaster, setBroadcaster] = useState<Video.Participant | null>(
    null
  );
  useEffect(() => {
    if (broadcastInfo?.user_id) {
      const p = participants.find(
        (p) =>
          identity(p).id === broadcastInfo.user_id && identity(p).isBroadcasting
      );
      if (p) {
        setBroadcaster(p);
        return;
      }
    }
    setBroadcaster(null);
  }, [participants.length, broadcastInfo, participants]);
  return broadcaster;
};

export const useMainParticipant = ({
  participants,
  room,
  broadcastInfo,
  courseId,
}: {
  participants: Video.Participant[];
  room?: Video.Room;
  broadcastInfo: TBroadcastInfoDto;
  courseId: string;
}) => {
  const broadcaster = useBroadcaster({ participants, broadcastInfo });
  const dominantParticipant = useDominantSpeaker({ room, courseId });
  const localParticipant = room && room.localParticipant;

  return (broadcaster || dominantParticipant || localParticipant) as
    | Video.Participant
    | undefined
    | null;
};

// almost identical to useParticipants, consider standardizing
export const usePublications = ({
  participant,
  disabled = false,
}: {
  participant: Video.Participant;
  disabled?: boolean;
}) => {
  const [published, setPublished] = useState<Video.TrackPublication[]>([]);
  const unmounted = useUnmountedRef();

  useEffect(() => {
    if (!participant || disabled) {
      setPublished([]);
      return;
    }
    setPublished(Array.from(participant.tracks.values()));
    const publicationAdded = (p: Video.RemoteTrackPublication) => {
      if (unmounted.current) return;
      setPublished((publications) => [...publications, p]);
    };
    const publicationRemoved = (p: Video.RemoteTrackPublication) => {
      if (unmounted.current) return;
      setPublished((publications) => publications.filter((p2) => p !== p2));
    };
    participant.on('trackPublished', publicationAdded);
    participant.on('trackUnpublished', publicationRemoved);
    return () => {
      participant.removeListener('trackPublished', publicationAdded);
      participant.removeListener('trackUnpublished', publicationRemoved);
    };
  }, [disabled, participant, unmounted]);

  return published;
};

export const useAudioTrackGotDisabledV2 = ({
  track,
}: {
  track?: Video.RemoteAudioTrack | null;
}) => {
  const { isLargeRoom } = useIsLargeRoom();
  const [isEnabled, setIsEnabled] = useState(track && track.isEnabled);

  useEffect(() => {
    if (isLargeRoom) {
      setIsEnabled(true);
      return;
    }
    if (!track) {
      return;
    }
    setIsEnabled(track.isEnabled);
    track.on('enabled', () => setIsEnabled(true));
    track.on('disabled', () => setIsEnabled(false));
    return () => {
      track.removeListener('enabled', () => setIsEnabled(true));
      track.removeListener('disabled', () => setIsEnabled(false));
    };
  }, [isLargeRoom, track]);

  return { isEnabled };
};

export const useTrack = ({
  publication,
  priority = 'standard',
}: {
  publication: Video.RemoteTrackPublication;
  priority: string;
}) => {
  const [track, setTrack] = useState<
    Video.RemoteAudioTrack | Video.RemoteVideoTrack | null
  >(
    publication &&
      (publication.track as Video.RemoteAudioTrack | Video.RemoteVideoTrack)
  );
  const isMounted = useMountedState();

  const handleRemoveTrack = useCallback(() => {
    if (isMounted()) {
      setTrack(null);
    }
  }, [isMounted]);

  const handleSubscribed = useCallback(
    (track: Video.RemoteAudioTrack | Video.RemoteVideoTrack | null) => {
      if (isMounted()) {
        setTrack(track);
      }
    },
    [isMounted]
  );

  useEffect(() => {
    // Reset the track when the 'publication' variable changes.
    setTrack(
      publication &&
        (publication.track as
          | Video.RemoteAudioTrack
          | Video.RemoteVideoTrack
          | null)
    );

    if (publication) {
      publication.on('subscribed', handleSubscribed);
      publication.on('unsubscribed', handleRemoveTrack);
      return () => {
        publication.removeListener('subscribed', handleSubscribed);
        publication.removeListener('unsubscribed', handleRemoveTrack);
      };
    }
  }, [
    publication,
    publication.trackName,
    publication.track,
    handleSubscribed,
    handleRemoveTrack,
  ]);

  useEffect(() => {
    if (track && priority && track.setPriority) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      track.setPriority(priority as any);
    }
  }, [track, priority]);

  return track;
};

export const useIsMuted = ({
  publications,
  you,
}: {
  publications: Video.RemoteTrackPublication[];
  you?: boolean;
}) => {
  const [isMuted, setIsMuted] = useState(false);
  const isAudioDisabled = useStoreValue(store, 'isAudioDisabled');
  const audiosOn = publications.filter(
    (p) =>
      p.kind === 'audio' &&
      p.track &&
      !p.trackName.includes('screenshare') &&
      !(p.track as Video.RemoteAudioTrack).isSwitchedOff
  ) as Video.RemoteAudioTrackPublication[];

  const { isEnabled } = useAudioTrackGotDisabledV2({
    track: audiosOn && audiosOn.length ? audiosOn[0].track : undefined,
  });

  useEffect(() => {
    if (you && isAudioDisabled) {
      setIsMuted(true);
      return;
    }
    if (audiosOn.length > 0 && isEnabled) {
      setIsMuted(false);
    } else {
      setIsMuted(true);
    }
  }, [audiosOn, isAudioDisabled, isEnabled, publications, you]);

  return isMuted;
};

export const useTrackSwitchChange = ({
  track,
  isVideo,
  participant,
  courseId,
}: {
  track?: Video.RemoteAudioTrack | Video.RemoteVideoTrack | null;
  isVideo?: boolean;
  participant?: Video.Participant;
  courseId?: string;
}) => {
  const { isLargeRoom } = useIsLargeRoom();
  const [isSwitchedOn, setIsSwitchedOn] = useState(
    track && !track.isSwitchedOff
  );
  const rollbar = useRollbar();
  const { showNotification } = useNotification();
  const { result: meAsGuest } = useRequest<ICourseGuestDto>(
    store,
    url('api.courseMe', { args: { courseId } })
  );

  const handleTrackSwitchedOn = useCallback(() => {
    setIsSwitchedOn(true);
  }, []);

  const handleTrackSwitchedOff = useCallback(
    (track: any, reason: any) => {
      if (track.kind === 'video') {
        // TODO: remove later after mass testing
        // eslint-disable-next-line no-console
        console.log('VIDEO TRACK SWITCHED OFF', track, reason);
        rollbar.info(
          `VIDEO TRACK SWITCHED OFF: ${JSON.stringify({
            user_id: participant ? identity(participant).id : null,
            course_id: courseId,
            track_kind: track.kind,
            track_name: track.name,
            reason,
            switch_off_ts: new Date().toUTCString(),
          })}`
        );
        if (
          isLargeRoom &&
          participant &&
          identity(participant).id === meAsGuest?.user.id
        ) {
          showNotification({
            message: i18n(
              'text.internet_connection_lower',
              'Your internet connection is lower and affecting others from seeing your video'
            ),
            type: 'error',
          });
        }
      }
      setIsSwitchedOn(false);
    },
    [
      courseId,
      isLargeRoom,
      meAsGuest?.user.id,
      participant,
      rollbar,
      showNotification,
    ]
  );

  useEffect(() => {
    if (!isLargeRoom) {
      setIsSwitchedOn(true);
      return;
    }
    if (!track) {
      return;
    }
    if (!('isSwitchedOff' in track)) {
      setIsSwitchedOn(true);
      return;
    }
    setIsSwitchedOn(!track.isSwitchedOff);
    track.on('switchedOn', handleTrackSwitchedOn);
    track.on('switchedOff', handleTrackSwitchedOff);
    return () => {
      track.removeListener('switchedOn', handleTrackSwitchedOn);
      track.removeListener('switchedOff', handleTrackSwitchedOff);
    };
  }, [
    handleTrackSwitchedOff,
    handleTrackSwitchedOn,
    isLargeRoom,
    isVideo,
    track,
  ]);

  return { isSwitchedOn };
};

export const VideoPublication = ({
  publication,
  mute,
  priority,
  children,
  onUpdatePortraitMode,
  isScreenshare,
  participant,
  courseId,
}: {
  publication: Video.RemoteVideoTrackPublication;
  mute?: boolean;
  priority: string;
  children: React.ReactNode;
  onUpdatePortraitMode: (isPortrait: boolean) => void;
  isScreenshare?: boolean;
  participant?: Video.Participant;
  courseId?: string;
}) => {
  const track = useTrack({
    publication,
    priority,
  }) as Video.RemoteVideoTrack | null;
  useTrackSwitchChange({
    track,
    isVideo: true,
    participant,
    courseId,
  });

  if (!track || mute) {
    return null;
  }

  if (track.kind === 'video') {
    return (
      <VideoTrack
        key={track.name}
        track={track}
        onUpdatePortraitMode={onUpdatePortraitMode}
        isScreenshare={isScreenshare}
      >
        {children}
      </VideoTrack>
    );
  }
  return null;
};

export const useAudio = ({
  isAudioDisabled,
  room,
  deviceId,
}: {
  isAudioDisabled?: boolean;
  room?: Video.Room;
  deviceId: string;
}) => {
  const [isCreatingPublication, setIsCreatingPublication] = useState(false);
  const [publication, setPublication] =
    useState<Video.LocalAudioTrackPublication>();
  const unmountedRef = useUnmountedRef();
  const [failedDate, setFailedDate] = useState<number>();
  const { showNotification } = useNotification();
  const hasCreatedIOSTrackRef = useRef(false);

  const { createOrGetLocalAudioTrack, localTrackRef } =
    useCreateOrGetLocalAudioTrack({
      deviceId,
    });

  useEffect(() => {
    // This is a fix for iOS devices as for some reason to hear other participants it is
    // required to create a local audio track
    if (!unmountedRef.current && isIOS && !hasCreatedIOSTrackRef.current) {
      Video.createLocalAudioTrack({
        ...(deviceId ? { deviceId: { exact: deviceId } } : {}),
      });
      hasCreatedIOSTrackRef.current = true;
    }
  }, [deviceId, unmountedRef]);

  useEffect(() => {
    if (unmountedRef.current || !room) {
      return;
    }
    // wait at least 10 seconds before trying to create a track if it failed before
    if (!isAudioDisabled && failedDate && failedDate > Date.now() - 10000) {
      store.setState({ isAudioDisabled: true });
      showNotification({
        type: 'error',
        message: i18n(
          'error.unable_to_use_microphone',
          'Unable to use microphone, try again later.'
        ),
      });
      return;
    }
    if (publication && !isAudioDisabled && !isCreatingPublication) {
      publication.track.enable();
      return;
    }
    if (isCreatingPublication || isAudioDisabled || publication) {
      return;
    }
    setIsCreatingPublication(true);
    createOrGetLocalAudioTrack()
      .then((newTrack) => {
        if (
          store.getState().isAudioDisabled ||
          unmountedRef.current ||
          !newTrack
        ) {
          return;
        }
        if (!room.localParticipant.audioTracks.size) {
          return room.localParticipant.publishTrack(newTrack);
        }
      })
      .then((publication) => {
        if (!publication) {
          return;
        }
        if (unmountedRef.current || store.getState().isAudioDisabled) {
          room.localParticipant.unpublishTrack(publication.track);
          room.localParticipant.emit('trackUnpublished', publication);
          return;
        }
        setPublication(publication as Video.LocalAudioTrackPublication);
      })
      .catch((err) => {
        if (!err.message.includes('Caught error')) {
          trackError('Error using audio for local participant', err);
          store.setState({ isAudioDisabled: true });
          setPublication(undefined);
          localTrackRef.current?.stop();
          localTrackRef.current = undefined;
        }
        setFailedDate(Date.now());
      })
      .finally(() => {
        setIsCreatingPublication(false);
      });
  }, [
    room,
    isAudioDisabled,
    isCreatingPublication,
    publication,
    failedDate,
    showNotification,
    deviceId,
    unmountedRef,
    createOrGetLocalAudioTrack,
    localTrackRef,
  ]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      localTrackRef.current?.stop();
      if (room && localTrackRef.current && publication) {
        room.localParticipant.unpublishTrack(localTrackRef.current);
        room.localParticipant.emit('trackUnpublished', publication);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAudioDisabled && publication) {
      localTrackRef.current?.disable();
    }
  }, [isAudioDisabled, localTrackRef, publication]);
};

export const useVideo = ({
  isVideoDisabled,
  room,
  deviceId,
}: {
  isVideoDisabled?: boolean;
  room?: Video.Room;
  deviceId: string;
}) => {
  const [isCreatingPublication, setIsCreatingPublication] = useState(false);
  const mode = useStoreValue(store, 'mode');
  const isAudioOnlyMode = mode === 'audio';
  const [publication, setPublication] =
    useState<Video.LocalVideoTrackPublication | null>();
  const unmountedRef = useUnmountedRef();
  const [failedDate, setFailedDate] = useState<number>();
  const { showNotification } = useNotification();
  const { createOrGetLocalVideoTrack, localTrackRef } =
    useCreateOrGetLocalVideoTrack({ deviceId });

  const handleResetPublication = useCallback(() => {
    publication?.track.stop();
    localTrackRef.current?.stop();
    if (room && room.localParticipant) {
      if (publication) {
        room.localParticipant.unpublishTrack(publication.track);
        room.localParticipant.emit('trackUnpublished', publication);
      } else if (localTrackRef.current) {
        room.localParticipant.unpublishTrack(localTrackRef.current);
      }
    }
    localTrackRef.current = undefined;
    setPublication(null);
    setIsCreatingPublication(false);
  }, [localTrackRef, publication, room]);

  useEffect(() => {
    // wait at least 10 seconds before trying to create a track if it failed before
    if (!isVideoDisabled && failedDate && failedDate > Date.now() - 10000) {
      store.setState({ isVideoDisabled: true });
      showNotification({
        type: 'error',
        message: i18n(
          'error.unable_to_use_camera',
          'Unable to use camera, try again later.'
        ),
      });
      return;
    }
    if (
      !room ||
      isCreatingPublication ||
      isVideoDisabled ||
      publication ||
      isAudioOnlyMode
    ) {
      return;
    }
    setIsCreatingPublication(true);
    createOrGetLocalVideoTrack()
      .then(() => {
        if (
          unmountedRef.current ||
          store.getState().isVideoDisabled ||
          !localTrackRef.current
        ) {
          localTrackRef.current?.stop();
          return;
        }
        return withTimeout(
          2000,
          room.localParticipant.publishTrack(localTrackRef.current)
        );
      })
      .then((publication) => {
        if (!publication) {
          localTrackRef.current?.stop();
          return;
        }
        if (unmountedRef.current || store.getState().isVideoDisabled) {
          (publication as Video.LocalVideoTrackPublication).track.stop();
          room.localParticipant.unpublishTrack(publication.track);
          room.localParticipant.emit('trackUnpublished', publication);
          return;
        }
        setPublication(publication as Video.LocalVideoTrackPublication);
      })
      .catch((err) => {
        store.setState({ isVideoDisabled: true });
        if (err.message.includes('Timed out after')) {
          handleResetPublication();
          setFailedDate(Date.now());
          return;
        }
        localTrackRef.current?.stop();
        localTrackRef.current = undefined;
        if (!err.message.includes('Caught error')) {
          trackError('Error using video for local participant', err);
        }
        setFailedDate(Date.now());
      })
      .finally(() => {
        setIsCreatingPublication(false);
      });
  }, [
    room,
    isVideoDisabled,
    isCreatingPublication,
    publication,
    isAudioOnlyMode,
    showNotification,
    failedDate,
    deviceId,
    unmountedRef,
    createOrGetLocalVideoTrack,
    localTrackRef,
    handleResetPublication,
  ]);

  useEffect(
    () => () => {
      handleResetPublication();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (isVideoDisabled && publication) {
      handleResetPublication();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVideoDisabled, publication]);
};
