import { EAppConfigsKeys } from 'api/AppConfigsDto';
import { ICourseGuestUserDto } from 'api/CourseGuestUserDto';
import { EFeatureFlagKeys } from 'api/FeatureFlagsDto';
import { Fetcher } from 'api/Fetcher';
import { VideoModule } from 'components/video-module';
import { VideoModuleMock } from 'components/video-module/video-module-mock';
import {
  LARGE_ROOM_COMMAND_DEADLINE_TS_STORAGE_KEY,
  MOVE_COMMANDS,
  STORAGE_LAST_ROOM_IS_LARGE_KEY,
  VIDEO_MODULE_LOADED_WIDTH,
} from 'consts';
import { TBroadcastInfoDto } from 'models/BroadcastInfoDto';
import { ICourseSessionDto } from 'models/CourseSessionDto';
import {
  ELargeRoomSyncType,
  TLargeRoomSyncBroadcastDto,
  TLargeRoomSyncDto,
  TLargeRoomSyncUnmodifiedDto,
} from 'models/LargeRoomSyncDto';
import { TVideoCallUsers } from 'models/Twilio';
import router, { useRouter } from 'next/router';
import { path } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { store } from 'store';
import * as Video from 'twilio-video';
import { useCurrentCourseSessionData } from 'util/course-session-hooks';
import { trackError } from 'util/trackError';
import { twilioIdentity as identity } from 'util/twilio-identity';
import { url } from 'util/url';
import { useIsActiveSession } from 'util/use-is-active-session';
import { useMappedState, useStoreValue } from 'util/use-mapped-state';
import { useStorePath, useStoreState } from 'util/use-store-path';
import { useTwilioSyncSubscription } from 'util/use-twilio-sync-subscription';
import { useLargeRoomStatePreservation } from 'util/useLargeRoomStatePreservation';
import { IVideoModulePosition } from './components/large-room-all-video-modules';

export const useIsLargeRoomFeatureFlagEnabled = (courseId?: string) => {
  const featureFlags = useStoreValue(store, 'featureFlags');
  const appConfigs = useStoreValue(store, 'appConfigs');

  const largeRoomFlag = featureFlags?.find(
    (flag) => flag.name === EFeatureFlagKeys.LargeRoom
  )?.isActive;
  if (largeRoomFlag) {
    return {
      isLargeRoomFeatureFlagEnabled: true,
    };
  }
  if (!courseId) {
    return {
      isLargeRoomFeatureFlagEnabled: false,
    };
  }
  const courseIdsJson = appConfigs?.find(
    (config) =>
      config.name === EAppConfigsKeys.LARGE_ROOM_COURSE_IDS_ENABLED_FOR
  )?.value;
  if (courseIdsJson) {
    try {
      const courseIds = JSON.parse(courseIdsJson) as string[];
      if (courseIds.includes(courseId)) {
        return {
          isLargeRoomFeatureFlagEnabled: true,
        };
      }
      return {
        isLargeRoomFeatureFlagEnabled: false,
      };
    } catch (e) {
      return {
        isLargeRoomFeatureFlagEnabled: false,
      };
    }
  }
  return {
    isLargeRoomFeatureFlagEnabled: false,
  };
};

export const useIsLargeRoom = () => {
  const courseId = router.query.courseId as string;
  const { isLargeRoomFeatureFlagEnabled } =
    useIsLargeRoomFeatureFlagEnabled(courseId);
  const [isCurrentlyInLargeRoom, setIsCurrentlyInLargeRoom] = useStoreState(
    store,
    'isCurrentlyInLargeRoom'
  );
  const courseSessionData = useCurrentCourseSessionData();

  const isLargeRoom = useMemo(() => {
    return (
      isLargeRoomFeatureFlagEnabled &&
      !!(
        isCurrentlyInLargeRoom ||
        courseSessionData?.are_all_in_large_room ||
        localStorage.getItem(STORAGE_LAST_ROOM_IS_LARGE_KEY)
      )
    );
  }, [
    courseSessionData?.are_all_in_large_room,
    isCurrentlyInLargeRoom,
    isLargeRoomFeatureFlagEnabled,
  ]);

  useEffect(() => {
    setIsCurrentlyInLargeRoom(isLargeRoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLargeRoom]);

  return {
    isLargeRoom,
  };
};

export const useLargeRoomVideoToken = ({ courseId }: { courseId: string }) => {
  const [token, setToken] = useState<string>();
  const { isLargeRoomFeatureFlagEnabled } =
    useIsLargeRoomFeatureFlagEnabled(courseId);

  useEffect(() => {
    if (!isLargeRoomFeatureFlagEnabled) {
      return;
    }
    Fetcher.makeRequest<{ result: string }>(
      url('api.largeRoomVideoGrantToken', { args: { courseId } }),
      {
        method: 'POST',
        data: {},
      }
    ).then((result) => {
      if (result.isOk()) {
        setToken(result.value.result);
      } else {
        trackError('Could not get video token for large room', result.error);
      }
    });
  }, [courseId, isLargeRoomFeatureFlagEnabled, setToken]);
  return token;
};

export const useBroadcasterParticipant = ({
  participants,
  broadcastInfo,
  isBroadcaster,
  isBroadcasting,
  localParticipant,
  isSpeakerMode = false,
}: {
  participants: Video.Participant[];
  broadcastInfo: TBroadcastInfoDto;
  isBroadcaster: boolean;
  isBroadcasting: boolean;
  localParticipant?: Video.LocalParticipant;
  isSpeakerMode?: boolean;
}) => {
  const broadcastingParticipant = useMemo(() => {
    if (!isBroadcasting) {
      return null;
    }
    if (isBroadcaster && isSpeakerMode) {
      return localParticipant;
    }
    if (isBroadcaster && !isSpeakerMode) {
      return null;
    }
    const broadcastingParticipant = participants.find(
      (p) => identity(p).id === broadcastInfo.user_id
    );
    if (!broadcastingParticipant) {
      return null;
    }
    return broadcastingParticipant;
  }, [
    isBroadcaster,
    isBroadcasting,
    participants,
    broadcastInfo,
    localParticipant,
    isSpeakerMode,
  ]);

  return { broadcastingParticipant };
};

export const useMapParticipantToVideoModule = ({
  isHost,
  dominantSpeaker,
  users,
  shouldUseMobileSpeakerUI = false,
  shouldUseMobileCentralSpeakerUI = false,
  scrollPosition,
  localParticipant,
  broadcastingUserId,
  noHighlightParticipant = false,
  isScreenshareInProgress,
}: {
  isHost: boolean;
  dominantSpeaker?: number | null;
  users: TVideoCallUsers;
  shouldUseMobileSpeakerUI?: boolean;
  shouldUseMobileCentralSpeakerUI?: boolean;
  scrollPosition?: number;
  localParticipant?: Video.Participant;
  broadcastingUserId?: number;
  noHighlightParticipant?: boolean;
  isScreenshareInProgress?: boolean;
}) => {
  const mapVideoModule = useCallback(
    (
      p: Video.Participant,
      options?: {
        position?: IVideoModulePosition;
        priority?: Video.Track.Priority;
      }
    ) => {
      let newStyle: React.CSSProperties = {};
      if (options?.position) {
        newStyle = {
          position: 'absolute',
          top: options.position.y,
          left: options.position.x,
          width: options.position.width,
          height: options.position.height,
        };
      }

      return (
        <div id={p.identity} className="item" key={p.identity} style={newStyle}>
          {(p as Video.Participant & { isMock?: boolean }).isMock ? (
            <VideoModuleMock
              participant={p}
              isLandscapeMode={shouldUseMobileSpeakerUI}
              scrollPosition={scrollPosition}
            />
          ) : (
            <VideoModule
              isHost={isHost}
              key={`vm-${p.identity}`}
              participant={p}
              you={localParticipant?.identity === p.identity}
              active={!!(dominantSpeaker && dominantSpeaker === identity(p).id)}
              isInSpotlight={identity(p).id === broadcastingUserId}
              user={users[identity(p).id]}
              isLargeRoomSpeakerMobileView={
                shouldUseMobileSpeakerUI || shouldUseMobileCentralSpeakerUI
              }
              priority={options?.priority}
              scrollPosition={scrollPosition}
              isLargeRoomSpeakerMobileBottomTile={shouldUseMobileSpeakerUI}
              noHighlightParticipant={noHighlightParticipant}
              broadcastingUserId={broadcastingUserId}
              isScreenshareInProgress={isScreenshareInProgress}
            />
          )}
        </div>
      );
    },
    [
      shouldUseMobileSpeakerUI,
      scrollPosition,
      isHost,
      localParticipant?.identity,
      dominantSpeaker,
      broadcastingUserId,
      users,
      shouldUseMobileCentralSpeakerUI,
      noHighlightParticipant,
      isScreenshareInProgress,
    ]
  );

  return { mapVideoModule };
};

export const useSpeakerModeCentralParticipant = ({
  dominantSpeaker,
  participants,
  localParticipant,
  broadcastingParticipant,
}: {
  dominantSpeaker?: number | null;
  participants: Video.Participant[];
  localParticipant: Video.LocalParticipant;
  broadcastingParticipant?: Video.Participant | Video.RemoteParticipant | null;
}) => {
  const centralParticipant = useMemo(() => {
    if (!localParticipant) {
      return null;
    }
    if (broadcastingParticipant) {
      return broadcastingParticipant;
    }
    if (!dominantSpeaker) {
      if (participants.length === 0) {
        return localParticipant;
      }
      return participants[0];
    }
    const dominantSpeakerParticipant = participants.find(
      (p) => dominantSpeaker === identity(p).id
    );
    if (!dominantSpeakerParticipant) {
      return participants[0];
    }
    return dominantSpeakerParticipant;
  }, [
    dominantSpeaker,
    participants,
    localParticipant,
    broadcastingParticipant,
  ]);

  return { centralParticipant };
};

export const useLargeRoomMoveCommands = ({
  largeRoomSync,
  meAsGuest,
  isBreakoutRoomActive,
}: {
  largeRoomSync?: TLargeRoomSyncDto;
  meAsGuest?: ICourseGuestUserDto;
  isBreakoutRoomActive?: boolean;
}) => {
  const router = useRouter();
  const { isLargeRoomFeatureFlagEnabled } = useIsLargeRoomFeatureFlagEnabled(
    meAsGuest?.course
  );
  const { preserveLargeRoomState } = useLargeRoomStatePreservation({});

  useEffect(() => {
    if (
      !largeRoomSync ||
      !meAsGuest ||
      largeRoomSync.type !== ELargeRoomSyncType.Action ||
      !isLargeRoomFeatureFlagEnabled ||
      isBreakoutRoomActive
    ) {
      return;
    }
    if (
      largeRoomSync.move_deadline_ts &&
      Date.now() / 1000 < largeRoomSync.move_deadline_ts &&
      localStorage.getItem(LARGE_ROOM_COMMAND_DEADLINE_TS_STORAGE_KEY) !==
        String(largeRoomSync.move_deadline_ts)
    ) {
      localStorage.setItem(
        LARGE_ROOM_COMMAND_DEADLINE_TS_STORAGE_KEY,
        String(largeRoomSync.move_deadline_ts)
      );
      if (largeRoomSync.move === MOVE_COMMANDS[1]) {
        preserveLargeRoomState(false);
      } else if (
        largeRoomSync.move === MOVE_COMMANDS[0] &&
        meAsGuest.group === largeRoomSync.group_id
      ) {
        preserveLargeRoomState(false);
      } else if (largeRoomSync.move === MOVE_COMMANDS[3]) {
        preserveLargeRoomState(true);
      } else if (
        largeRoomSync.move === MOVE_COMMANDS[2] &&
        meAsGuest.group === largeRoomSync.group_id
      ) {
        preserveLargeRoomState(true);
      }
    }
  }, [
    largeRoomSync,
    meAsGuest,
    router,
    isLargeRoomFeatureFlagEnabled,
    preserveLargeRoomState,
    isBreakoutRoomActive,
  ]);
};

export const useLargeRoomSync = ({
  token,
  courseId,
}: {
  token?: string;
  courseId: string;
}) => {
  const [largeRoomSync, setLargeRoomSync] = useStorePath<
    TLargeRoomSyncDto | undefined
  >(store, ['largeRoomSync']);

  const { isLargeRoomFeatureFlagEnabled } =
    useIsLargeRoomFeatureFlagEnabled(courseId);
  const document = useTwilioSyncSubscription<TLargeRoomSyncUnmodifiedDto>({
    token: isLargeRoomFeatureFlagEnabled ? token : undefined,
    documentName: courseId ? `course-${courseId}-large-room` : undefined,
    loggingPrefix: 'LARGE-ROOM',
  });

  const isDtoBroadcastingRelated = (
    data: TLargeRoomSyncUnmodifiedDto
  ): data is TLargeRoomSyncBroadcastDto => {
    return 'broadcast_mode' in data;
  };

  useEffect(() => {
    if (!document) {
      return;
    }
    if (isDtoBroadcastingRelated(document)) {
      const dataModified: TLargeRoomSyncDto = {
        type: ELargeRoomSyncType.Broadcast,
        ...document,
      };
      setLargeRoomSync(dataModified);
    } else {
      const dataModified: TLargeRoomSyncDto = {
        type: ELargeRoomSyncType.Action,
        ...document,
      };
      setLargeRoomSync(dataModified);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  return largeRoomSync;
};

export const useScrollIndicatorHide = ({ key }: { key: string }) => {
  const [, setShowSwipeIndicator] = useStorePath(store, [`show-${key}`]);
  const [, setHasShownSwipeIndicator] = useStorePath(store, [
    `has-shown-${key}`,
  ]);

  const scrollIndicatorHide = useCallback(() => {
    setShowSwipeIndicator(false);
    setHasShownSwipeIndicator(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { scrollIndicatorHide };
};

export const useVirtualizedScrollVideoModule = ({
  scrollPosition,
  you = false,
  videoModuleRef,
}: {
  scrollPosition?: number;
  you?: boolean;
  videoModuleRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const { isLargeRoom } = useIsLargeRoom();
  const [shouldLoadVideo, setShouldLoadVideo] = useState(!!isLargeRoom);

  useEffect(() => {
    if (!videoModuleRef.current || !isLargeRoom) {
      return;
    }
    if (scrollPosition === undefined || you) {
      setShouldLoadVideo(true);
      return;
    }
    if (
      scrollPosition - VIDEO_MODULE_LOADED_WIDTH <=
        videoModuleRef.current.offsetLeft &&
      videoModuleRef.current.offsetLeft <=
        scrollPosition + VIDEO_MODULE_LOADED_WIDTH
    ) {
      setShouldLoadVideo(true);
    } else {
      setShouldLoadVideo(false);
    }
  }, [isLargeRoom, scrollPosition, videoModuleRef, you]);

  return { shouldLoadVideo };
};

export const useCalculateMobileSpeakerBottomTilesDimensions = () => {
  const { isLargeRoom } = useIsLargeRoom();

  const portraitVideoDimensions = isLargeRoom
    ? {
        height: 'var(--speaker-others-mobile-height)',
        width: 'var(--speaker-others-mobile-width)',
      }
    : undefined;
  const landscapeVideoDimensions = isLargeRoom
    ? {
        height: 'var(--speaker-others-landscape-mobile-height)',
        width: 'var(--speaker-others-landscape-mobile-width)',
      }
    : undefined;

  return {
    portraitVideoDimensions,
    landscapeVideoDimensions,
  };
};

export const useVideoCallMixinsForLargeRoom = ({
  courseSessionData,
  courseId,
  meAsGuest,
  isLargeRoom,
  isBreakoutRoomActive,
}: {
  courseSessionData: ICourseSessionDto | undefined;
  courseId: string;
  meAsGuest?: ICourseGuestUserDto;
  isLargeRoom?: boolean;
  isBreakoutRoomActive?: boolean;
}) => {
  const largeRoomOpenable = useMappedState<boolean>(
    store,
    path(['largeRoomOpenable'])
  );
  const { isLargeRoomFeatureFlagEnabled } =
    useIsLargeRoomFeatureFlagEnabled(courseId);
  const largeRoomVideoToken = useLargeRoomVideoToken({ courseId });
  const largeRoomSync = useLargeRoomSync({
    token: largeRoomVideoToken,
    courseId,
  });
  const { preserveLargeRoomState } = useLargeRoomStatePreservation({});

  useEffect(() => {
    if (
      courseSessionData?.are_all_in_large_room !== undefined &&
      isLargeRoom !== courseSessionData?.are_all_in_large_room
    ) {
      preserveLargeRoomState(courseSessionData?.are_all_in_large_room);
    }
  }, [courseSessionData, isLargeRoom, preserveLargeRoomState]);

  useEffect(() => {
    if (!isLargeRoom) {
      return;
    }
    if (courseSessionData && largeRoomSync && !isLargeRoomFeatureFlagEnabled) {
      router.replace(url('guest.dashboard'));
    }
  }, [
    courseSessionData,
    isLargeRoom,
    isLargeRoomFeatureFlagEnabled,
    largeRoomOpenable,
    largeRoomSync,
  ]);

  useLargeRoomMoveCommands({
    largeRoomSync,
    meAsGuest,
    isBreakoutRoomActive,
  });

  useEffect(() => {
    if (isLargeRoom) {
      return;
    }
    if (!isLargeRoomFeatureFlagEnabled) {
      return;
    }
    if (
      largeRoomSync &&
      largeRoomSync.type === ELargeRoomSyncType.Broadcast &&
      largeRoomSync.broadcast_mode &&
      largeRoomOpenable &&
      !isBreakoutRoomActive
    ) {
      preserveLargeRoomState(true);
    }
  }, [
    largeRoomSync,
    largeRoomOpenable,
    courseId,
    isLargeRoomFeatureFlagEnabled,
    isLargeRoom,
    preserveLargeRoomState,
    isBreakoutRoomActive,
  ]);

  return { largeRoomVideoToken, largeRoomSync };
};
