import { API_URL } from 'consts';
import { Result } from 'neverthrow';
import { ICourseGuestDto } from './CourseGuestDto';
import { TApiError, Fetcher } from './Fetcher';
import { IPaginatedRequestDto } from './PaginatedRequestDto';
import { IUserDto } from './UserDto';

export type TChatGuestDto = Pick<ICourseGuestDto, 'id' | 'admin' | 'host'> & {
  userId: number;
  name: string;
} & Pick<IUserDto, 'avatar'>;

export const requestChatGuests: (args: {
  courseId: string;
  limit?: number;
}) => Promise<Result<IPaginatedRequestDto<TChatGuestDto>, TApiError>> = async ({
  courseId,
  limit = 1000,
}) => {
  return Fetcher.makeRequest<IPaginatedRequestDto<TChatGuestDto>>(
    `${API_URL}/courses/${courseId}/chat-guests?limit=${limit}`,
    { method: 'GET' }
  );
};
