import { API_URL } from 'consts';

export const urls = [
  {
    namespace: 'common',
    url: '',
    routes: {
      profileEdit: '/profile/settings',
    },
  },
  {
    namespace: 'guest',
    url: '',
    routes: {
      login: '/login/:courseId',
      videoCallPreview: '/video-call/:courseId/preview',
      videoCall: '/video-call/:courseId',
      forgotPassword: '/forgot-password/:courseId',
      resetPassword: '/reset-password/:resetToken/:userId',
      setPassword: '/set-password/:setToken/:userId/:courseId',
      dashboard: '/dashboard',
      waitingRoom: '/courses/:courseId/waiting',
    },
  },
  {
    namespace: 'admin',
    url: '/admin',
    routes: {
      login: '/login',
      courses: '/courses',
      createCourseRequest: '/create-course-request',
      course: '/courses/:courseId',
      settings: '/settings',
      forgotPassword: '/forgot-password',
    },
  },
  {
    namespace: 'api',
    url: API_URL,
    routes: {
      guestLogin: '/courses/:courseId/authenticate',
      login: '/auth/login',
      signup: '/auth/signup',
      forgotPassword: '/auth/forgot-password',
      resetPassword: '/auth/reset-password',
      changePassword: '/auth/change-password',
      refreshToken: '/api/token/refresh/',
      me: '/me',
      userConvertCourseAdmin: '/users/convert-course-admin/:token',
      updateUserName: '/users/:userId/name',
      courseVideoGrantToken: '/courses/:courseId/authorize',
      largeRoomVideoGrantToken: '/courses/:courseId/large-room/authorize',
      courseAdminVideoGrantTokens: '/courses/:courseId/authorize/admin',
      courses: '/courses',
      courseCreateMyAlpha: '/courses/myalpha-create-course/:token',
      guestCourses: '/guest/courses',
      course: '/courses/:courseId',
      courseVideoUrl: '/courses/:courseId/video-url',
      courseMe: '/courses/:courseId/me',
      courseSendInvites: '/courses/:courseId/send-invites',
      courseGroups: '/courses/:courseId/groups',
      courseGuests: '/courses/:courseId/guests',
      courseGuestKickOut: '/courses/:courseId/guests/:guestId/kick',
      coursePublicGuests: '/courses/:courseId/public-guests',
      courseGuest: '/courses/:courseId/guests/:guestId',
      courseGuestsBatch: '/courses/:courseId/guests/batch',
      courseGuestsAssign: '/courses/:courseId/groups/assign',
      courseGuestMuteSync: '/courses/:courseId/mute-sync',
      courseContact: '/courses/:courseId/email',
      courseInProgress: '/courses/:courseId/in-progress',
      groupInProgress: '/groups/:groupId/in-progress',
      group: '/groups/:groupId',
      groupBatch: '/courses/:courseId/groups/batch',
      files: '/files',
      networkQuality: '/network-quality',
      chatToken: '/chat-token',
      chatCreatePrivateConversation:
        '/courses/:courseId/chat-create-private-conversation',
      courseSessionToken: '/course-session/token',
      waitingRoomOpen: '/courses/:courseId/waiting-room-open',
      presenceToken: '/presence/token',
      broadcastYourself: '/courses/:courseId/broadcast-yourself',
      broadcastScreenshare: '/courses/:courseId/broadcast-screenshare',
      broadcastVideo: '/courses/:courseId/broadcast-vimeo',
      broadcastStop: '/courses/:courseId/broadcast-stop',
      largeRoomBroadcastYourself:
        '/courses/:courseId/large-room/broadcast-yourself',
      largeRoomBroadcastScreenshare:
        '/courses/:courseId/large-room/broadcast-screenshare',
      largeRoomBroadcastVideo: '/courses/:courseId/large-room/broadcast-video',
      largeRoomBroadcastStop: '/courses/:courseId/large-room/stop',
      largeRoomMoveParticipants:
        '/courses/:courseId/large-room/move-participants',
      breakoutRooms: '/breakout-rooms',
      breakoutRoomRespond: '/breakout-rooms/:breakoutRoomId/respond',
      breakoutRoomLeave: '/breakout-rooms/:breakoutRoomId/leave',
      breakoutRoomCancel: '/breakout-rooms/:breakoutRoomId/cancel',
      breakoutRoomVideoToken: '/breakout-room-token',
      featureFlags: '/featureflags',
      courseSessionAttendanceReport: '/courses/:courseId/attendance-report',
    },
  },
];
