/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { findIndex, assocPath, path as pathGet } from 'ramda';
import { IRequests } from '.';

// Actions for managing the requests cache in your global store.
export enum EReducerActions {
  CLEAR_REQUESTS = 'CLEAR_REQUESTS',
  PATCH_LIST_REQUEST = 'PATCH_LIST_REQUEST',
}

const without = (keys: string[], obj: Record<string, unknown>) =>
  Object.keys(obj || {}).reduce(
    (acc, k) => (keys.includes(k) ? acc : { ...acc, [k]: obj[k] }),
    {}
  );

const clearRequests = (predicate: (uuid: string) => boolean) => {
  return {
    type: EReducerActions.CLEAR_REQUESTS as EReducerActions.CLEAR_REQUESTS,
    payload: { predicate },
  };
};

type TClearRequestsReturnType = ReturnType<typeof clearRequests>;

const patchListRequest = ({
  endpointOrUid,
  dataToMerge,
  matchKey = 'id',
  path = ['results'],
}: {
  endpointOrUid: string;
  dataToMerge: any;
  matchKey?: string;
  path?: string[];
}) => ({
  type: EReducerActions.PATCH_LIST_REQUEST as EReducerActions.PATCH_LIST_REQUEST,
  payload: { endpointOrUid, dataToMerge, matchKey, path },
});

type TPatchListRequestReturnType = ReturnType<typeof patchListRequest>;

export const actions = {
  clearRequests,
  patchListRequest,
};

type TActionsReturnType =
  | TClearRequestsReturnType
  | TPatchListRequestReturnType;

export function requestsReducer(
  action: TActionsReturnType,
  state: IRequests<unknown>
) {
  const { type, payload } = action;
  if (type === EReducerActions.CLEAR_REQUESTS) {
    const { predicate } = payload;
    let matchedRequestKeys: string[] = [];
    if (state.requests) {
      matchedRequestKeys = Object.keys(state.requests).filter(predicate);
    }
    return {
      ...state,
      requests: without(matchedRequestKeys, state.requests),
    };
  }
  if (type === EReducerActions.PATCH_LIST_REQUEST) {
    const { endpointOrUid, dataToMerge, matchKey, path } = payload;
    const req = state.requests[endpointOrUid];
    if (!req) {
      // eslint-disable-next-line no-console
      console.warn(
        `${EReducerActions.PATCH_LIST_REQUEST}: Attempting to patch non-existent request, "${endpointOrUid}"`
      );
      return state;
    }
    const results =
      path && path.length ? pathGet(path)(req.result) : req.result;
    if (!results || (Array.isArray(results) && !results.length)) {
      // eslint-disable-next-line no-console
      console.warn(
        `${EReducerActions.PATCH_LIST_REQUEST}: Request result is not an array, "${endpointOrUid}"`
      );
      return state;
    }
    const idx = findIndex(
      () => _.isEqual([matchKey], dataToMerge[matchKey]),
      results as unknown[]
    );
    if (idx === undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        `${EReducerActions.PATCH_LIST_REQUEST}: Matching item not found, "${endpointOrUid}"`,
        results
      );
      return state;
    }
    const foundItem = (results as any)[idx];
    const patchedResults = assocPath(
      [idx],
      { ...foundItem, ...dataToMerge },
      results
    );
    let updatePath = ['requests', endpointOrUid, 'result'];
    if (path && path.length) {
      updatePath = updatePath.concat(path);
    }

    return assocPath(updatePath, patchedResults, state);
  }
  return state;
}
