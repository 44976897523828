import { ITwilioIdentityDto } from 'models/Twilio';
import * as Video from 'twilio-video';

export const twilioIdentity = (() => {
  const cache: Record<string, ITwilioIdentityDto> = {};
  const fn = (participant: Video.Participant) => {
    try {
      const cached = cache[participant.identity];
      if (cached) return cached;
      const info = JSON.parse(participant.identity) as ITwilioIdentityDto;
      cache[participant.identity] = info;
      return info;
    } catch (e) {
      return {} as ITwilioIdentityDto;
    }
  };
  return fn;
})();
