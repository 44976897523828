import { ICourseDto } from 'api/CourseDto';
import { IGuestCourseDto } from 'api/GuestCourseDto';
import { useStopBroadcastingAction } from 'components/broadcast-buttons';
import Form, { SubmitButton } from 'components/form';
import { IFormProps } from 'components/form/form';
import { useIsLargeRoomFeatureFlagEnabled } from 'components/large-room/hooks';
import { Modal } from 'components/modal';
import { useNotification } from 'components/notification';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import { store } from 'store';
import { trackError } from 'util/trackError';
import { url } from 'util/url';
import { useChatStateClear } from 'util/use-chat-state-clear';
import { useGetModalState } from './use-get-modal-state';

export const useEndAllSessionsForm = ({ courseId }: { courseId?: string }) => {
  const router = useRouter();
  const { showNotification } = useNotification();
  const { handleChatStateClear } = useChatStateClear();
  const { isLargeRoomFeatureFlagEnabled } =
    useIsLargeRoomFeatureFlagEnabled(courseId);
  const { stopRequest } = useStopBroadcastingAction({
    force: true,
    courseId,
  });

  const form: IFormProps<{ isWaitingRoomOpen: boolean; isLargeRoom: boolean }> =
    useMemo(() => {
      return {
        name: 'EndCourseSessions',
        action: url('api.waitingRoomOpen', { args: { courseId } }),
        initialData: {
          isWaitingRoomOpen: false,
          isLargeRoom: isLargeRoomFeatureFlagEnabled,
        },
        method: 'PATCH',
        onSuccess: () => {
          showNotification({
            type: 'success',
            message: i18n('text.alpha_sessions_ended', 'Alpha Sessions Ended'),
          });
          handleChatStateClear();
          stopRequest();
          router.push(url('guest.dashboard'));
          store.setState({ modal: undefined });
          store.setState({ modalState: undefined });
        },
        onFailure: ({ err }) => {
          trackError('Error during ending course sessions', err);
          store.setState({ modal: undefined });
          store.setState({ modalState: undefined });
          showNotification({
            message: i18n(
              'error.unable_to_end_sessions',
              'We were not able to end the Alpha sessions.'
            ),
          });
        },
      };
    }, [
      courseId,
      handleChatStateClear,
      isLargeRoomFeatureFlagEnabled,
      router,
      showNotification,
      stopRequest,
    ]);

  return form;
};

interface IEndAllSessionsForm {
  courseId: string;
}

const EndAllSessionsForm: React.FC<IEndAllSessionsForm> = ({ courseId }) => {
  const form = useEndAllSessionsForm({ courseId });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Form {...form}>
      <SubmitButton
        data-testid="Modals_End_All_Sessions_button_ok"
        formName={form.name}
        className="btn"
      >
        {i18n('text.end_for_all', 'End for All')}
      </SubmitButton>
    </Form>
  );
};

export interface IEndAllSessionsModalState {
  course: ICourseDto | IGuestCourseDto;
}

export const EndAllSessions: React.FC = () => {
  const modalState =
    useGetModalState<IEndAllSessionsModalState>('EndAllSessions');

  const onClickCancel = useCallback(
    () => store.setState({ modalState: undefined, modal: undefined }),
    []
  );

  if (!modalState) return null;

  const { course } = modalState;

  return (
    <Modal className="styled-modal small">
      <div className="modal-content centered end-session-container">
        <h2>{i18n('text.end_sessions', 'End Session')}</h2>
        <p>
          {i18n(
            'hint.end_sessions',
            'This will close the session and remove all participants in all groups.'
          )}
        </p>
        <div className="actions">
          <EndAllSessionsForm courseId={course.id} />
          <button
            data-testid="Modals_End_All_Sessions_button_cancel"
            type="button"
            className="btn btn-text"
            onClick={onClickCancel}
          >
            {i18n('text.cancel', 'Cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
