import { THttpMethod, Fetcher, TApiError } from 'api/Fetcher';
import { Result } from 'neverthrow';

export type TStorageType = typeof window.localStorage | null;

let storage: TStorageType = null;
let apiUrl = 'http://10.0.2.2:8000';

export const configure: (args: {
  storage: TStorageType;
  apiUrl: string;
}) => void = ({ storage: uStorage, apiUrl: uApiUrl }) => {
  if (uStorage) storage = uStorage;
  if (uApiUrl) apiUrl = uApiUrl;
};

export interface IRequestOptions<T> {
  endpoint?: string;
  url?: string;
  method?: THttpMethod;
  data?: T;
  noAuth?: boolean;
}

export const request = <RequestPayload, ResponsePayload>({
  endpoint,
  url,
  method = 'GET',
  data,
  noAuth = false,
}: IRequestOptions<RequestPayload>): Promise<
  Result<ResponsePayload, TApiError<unknown>>
> => {
  let newUrl: string | undefined = url;
  if (endpoint != null && endpoint.indexOf('http') === -1) {
    newUrl = `${apiUrl}/${endpoint}`;
  }
  if (url == null) {
    newUrl = endpoint;
  }
  if (!newUrl) {
    throw new Error('No url provided');
  }
  return Fetcher.makeRequest<ResponsePayload>(newUrl, {
    method,
    data: data || {},
    noAuthToken: noAuth,
  });
};

export default request;
