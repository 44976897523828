import { useState, useEffect } from 'react';
import { isIOS } from 'react-device-detect';

const orientationCalc = () => {
  if (window.innerHeight > window.innerWidth) {
    return 'portrait-primary';
  }
  return 'landscape-primary';
};

export const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(
    window?.screen?.orientation?.type ?? orientationCalc()
  );

  useEffect(() => {
    if (!isIOS) {
      const handleOrientationChange = () =>
        setOrientation(window?.screen?.orientation?.type);
      window.addEventListener('orientationchange', handleOrientationChange);
      return () =>
        window.removeEventListener(
          'orientationchange',
          handleOrientationChange
        );
    }
    const resizeListener = () => {
      setOrientation(orientationCalc());
    };
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  return orientation;
};
