import React, { useEffect } from 'react';
import { MODALS } from 'pages/_app';
import { useStorePath } from 'util/use-store-path';
import { store } from 'store';
import { trackError } from 'util/trackError';

// add no-scroll class to body when
// modal is visible
const useNoScrollClass = (modal: keyof typeof MODALS | undefined) => {
  useEffect(() => {
    if (!modal) {
      return;
    }
    document.body.classList.add('no-scroll');
    return () => document.body.classList.remove('no-scroll');
  }, [modal]);
};

export const Modals: (args: { modals: typeof MODALS }) => JSX.Element | null =
  ({ modals }) => {
    const [modalName] = useStorePath<keyof typeof MODALS | undefined>(store, [
      'modal',
    ]);
    useNoScrollClass(modalName);
    if (!modalName) {
      return null;
    }
    const Match = modals[modalName];
    if (!Match) {
      trackError('No modal named', modalName);
    }
    return <Match />;
  };

interface IModalProps {
  hideClose?: boolean;
  variant?: string;
  className?: string;
  onClickedOutside?: () => void;
}

export const Modal: React.FC<IModalProps> = ({
  hideClose,
  variant,
  className,
  children,
  onClickedOutside,
}) => {
  return (
    <div
      className={`modal-component-container ${className || ''}`}
      onClick={(ev) => {
        if (
          (ev.target as Element).classList.contains('modal-component-container')
        ) {
          onClickedOutside?.();
          store.setState({ modal: undefined });
        }
      }}
    >
      <div className={`modal-component-content ${variant || ''}`}>
        {!hideClose && (
          <div
            className="close"
            onClick={() => store.setState({ modal: undefined })}
          >
            close
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
