import { ICourseGuestDto } from 'api/CourseGuestDto';
import { Modal } from 'components/modal';
import { TBroadcastInfoDto } from 'models/BroadcastInfoDto';
import React, { useCallback } from 'react';
import { store } from 'store';

export interface IConfirmLeaveModalState {
  meAsGuest: ICourseGuestDto;
  broadcastInfo: TBroadcastInfoDto;
}

export const AudioAutoplayPolicyModal = () => {
  const handleAutoplayAllAudio = useCallback(() => {
    const audios = document.getElementsByTagName('audio');
    for (let i = 0; i < audios.length; i += 1) {
      const audioEl = audios[i];
      if (audioEl.paused) {
        audioEl.play();
      }
    }
    store.setState({ modal: null });
  }, []);

  return (
    <Modal className="styled-modal small">
      <div className="modal-content centered alert-container">
        <h2>
          {i18n('text.browser_autoplay_policy', 'Browser Autoplay Policy')}
        </h2>
        <p>
          {i18n(
            'text.your_browser_blocking_audio',
            'Your browser seems to block audio from autoplaying, please click OK to re-enable audio'
          )}
        </p>
        <div className="actions">
          <button className="btn min-width" onClick={handleAutoplayAllAudio}>
            {i18n('text.ok', 'OK')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
