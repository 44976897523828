import { ICourseSessionDto } from 'models/CourseSessionDto';
import { useEffect } from 'react';
import { store } from 'store';
import { loadPublicGuests } from './guest-utils';
import { useStoreValue } from './use-mapped-state';

export const useReloadSessionGuests = ({
  courseId,
  courseSessionData,
  isLargeRoom = false,
}: {
  courseId?: string;
  courseSessionData?: ICourseSessionDto;
  isLargeRoom?: boolean;
}) => {
  const users = useStoreValue(store, 'publicGuests');

  useEffect(() => {
    if (
      courseId &&
      courseSessionData?.guests?.length &&
      courseSessionData?.guests?.length > 0
    ) {
      loadPublicGuests({ isLargeRoom, courseId });
    }
  }, [courseId, courseSessionData?.guests?.length, isLargeRoom]);

  return {
    users,
    refreshGuestInfos: loadPublicGuests,
  };
};
