import { ICoursePublicGuestDto } from 'api/CoursePublicGuestsDto';
import Form, { SubmitButton } from 'components/form';
import { IFormProps } from 'components/form/form';
import { Modal } from 'components/modal';
import { useNotification } from 'components/notification';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { store } from 'store';
import { trackError } from 'util/trackError';
import { url } from 'util/url';
import { useGetModalState } from './use-get-modal-state';

export interface IKickOutModalState {
  user?: ICoursePublicGuestDto;
}

export const KickOutModal: React.FC = () => {
  const modalState = useGetModalState<IKickOutModalState>('KickOutModal');
  const router = useRouter();
  const { courseId } = router.query;
  const { showNotification } = useNotification();

  const onClickCancel = useCallback(
    () => store.setState({ modalState: undefined, modal: undefined }),
    []
  );

  if (!modalState) {
    return null;
  }

  const { user } = modalState;

  const form: IFormProps<{ kicked: boolean }> = {
    name: 'KickOut',
    action: url('api.courseGuestKickOut', {
      args: { courseId, guestId: user?.id },
    }),
    method: 'PATCH',
    initialData: { kicked: true },
    onSuccess: () => {
      store.setState({ modal: undefined });
      store.setState({ modalState: undefined });
    },
    onFailure: ({ err }) => {
      trackError('Error during removing user from Alpha session', err);
      store.setState({ modal: undefined });
      store.setState({ modalState: undefined });
      showNotification({
        type: 'error',
        message: i18n(
          'error.unable_to_remove_participant',
          'We were not able to remove these guests.'
        ),
      });
    },
  };

  return (
    <Modal className="styled-modal">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Form {...form}>
        <div className="modal-content centered">
          <h2>
            {i18n('text.remove_user_from_alpha', {
              name: user?.name,
              defaultValue: 'Remove {{name}} from Alpha?',
            })}
          </h2>
          <p>
            {i18n('text.remove_user_from_alpha_confirmation', {
              name: user?.name,
              defaultValue:
                'Are you sure you want to remove {{name}} from this Alpha?',
            })}
          </p>
          <div className="actions">
            <button
              data-testid="Modals_Remove_Participant_button_cancel"
              type="button"
              className="btn btn-text"
              onClick={onClickCancel}
            >
              {i18n('text.cancel', 'Cancel')}
            </button>
            <SubmitButton
              data-testid="Modals_Remove_Participant_button_ok"
              formName={form.name}
              className="btn"
            >
              {i18n('text.remove_user_from_alpha_action', 'Yes, remove them')}
            </SubmitButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
