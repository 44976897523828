import { ICourseGuestDto } from 'api/CourseGuestDto';
import { useStopBroadcastingAction } from 'components/broadcast-buttons';
import { Modal } from 'components/modal';
import { TBreakoutRoomDtoModified } from 'models/BreakoutRoomDto';
import { TBroadcastInfoDto } from 'models/BroadcastInfoDto';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { store } from 'store';
import { useBreakoutRoomInviteUpdate } from 'util/breakout-room-hooks';
import { url } from 'util/url';
import { useChatStateClear } from 'util/use-chat-state-clear';
import { useStorePath } from 'util/use-store-path';
import { useIsLargeRoom } from 'components/large-room/hooks';
import { TLargeRoomSyncDto } from 'models/LargeRoomSyncDto';
import { useGetModalState } from './use-get-modal-state';

export interface IConfirmLeaveModalState {
  meAsGuest: ICourseGuestDto;
  broadcastInfo: TBroadcastInfoDto;
}

export const ConfirmLeaveModal = () => {
  const router = useRouter();
  const { handleChatStateClear } = useChatStateClear();
  const modalState =
    useGetModalState<IConfirmLeaveModalState>('ConfirmLeaveModal');
  const meAsGuest = modalState?.meAsGuest;
  const broadcastInfo = modalState?.broadcastInfo;
  const isLargeRoom = useIsLargeRoom();
  const [largeRoomSync, setLargeRoomSync] = useStorePath<
    TLargeRoomSyncDto | undefined
  >(store, ['largeRoomSync']);
  const { stopRequest } = useStopBroadcastingAction({
    meAsGuest,
    broadcastInfo,
  });
  const [breakoutRoom] = useStorePath<TBreakoutRoomDtoModified>(store, [
    'breakoutRoom',
  ]);
  const { breakoutRoomInviteUpdate, breakoutRoomAcceptedUserLeave } =
    useBreakoutRoomInviteUpdate(breakoutRoom);

  const handleBreakoutRoomDeclineIfInvited = useCallback(() => {
    if (breakoutRoom?.status === 'waiting') {
      if (breakoutRoom.myStatus === 'invited') {
        breakoutRoomInviteUpdate(false);
      } else if (breakoutRoom.myStatus === 'accepted') {
        breakoutRoomAcceptedUserLeave();
      }
    }
  }, [
    breakoutRoom?.myStatus,
    breakoutRoom?.status,
    breakoutRoomAcceptedUserLeave,
    breakoutRoomInviteUpdate,
  ]);

  const handleLeaveSession = useCallback(() => {
    handleBreakoutRoomDeclineIfInvited();
    stopRequest();
    store.setState({
      modal: null,
      videoToken: undefined,
      gridView: true,
    });
    if (isLargeRoom && largeRoomSync) {
      setLargeRoomSync(undefined);
    }
    handleChatStateClear();
    router.push(url('guest.dashboard'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleBreakoutRoomDeclineIfInvited,
    stopRequest,
    isLargeRoom,
    largeRoomSync,
    handleChatStateClear,
    router,
  ]);

  return (
    <Modal className="styled-modal small">
      <div className="modal-content centered alert-container">
        <h2>{i18n('text.leave_session', 'Leave Session?')}</h2>
        <p>
          {i18n(
            'text.leave_session_confirmation',
            'Are you sure you want to leave?'
          )}
        </p>
        <div className="actions">
          <button
            data-testid="Modals_Leave_Session_button_yes"
            className="btn min-width"
            onClick={() => handleLeaveSession()}
          >
            {i18n('text.yes', 'Yes')}
          </button>
          <button
            data-testid="Modals_Leave_Session_button_cancel"
            type="button"
            className="btn btn-text"
            onClick={() => store.setState({ modal: null })}
          >
            {i18n('text.cancel', 'Cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
