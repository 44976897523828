import { TBroadcastInfoDto } from './BroadcastInfoDto';

export enum ELargeRoomSyncType {
  Broadcast = 'broadcast',
  Action = 'action',
}

export enum ELargeRoomMoveActions {
  YPSG = 'YPSG',
  APSG = 'APSG',
  YPLR = 'YPLR',
  APLR = 'APLR',
}

export interface ILargeRoomSyncMoveDto {
  move: ELargeRoomMoveActions;
  move_deadline_ts: number;
  group_id: number;
  course_id: string;
  is_large_room: true;
}

export type TLargeRoomSyncBroadcastDto = TBroadcastInfoDto & {
  is_large_room: true;
};

export type TLargeRoomSyncUnmodifiedDto =
  | ILargeRoomSyncMoveDto
  | TLargeRoomSyncBroadcastDto;

export type TLargeRoomSyncDto =
  | (TLargeRoomSyncBroadcastDto & { type: ELargeRoomSyncType.Broadcast })
  | (ILargeRoomSyncMoveDto & { type: ELargeRoomSyncType.Action });
