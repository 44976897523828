import { ICoursePublicGuestDto } from 'api/CoursePublicGuestsDto';
import { Avatar } from 'components/avatar';
import {
  useCalculateMobileSpeakerBottomTilesDimensions,
  useIsLargeRoom,
  useVirtualizedScrollVideoModule,
} from 'components/large-room/hooks';
import { ModeratorControls } from 'components/moderator-controls';
import {
  useIsMuted,
  usePublications,
  VideoPublication,
} from 'components/twilio';
import { useParticipantNetworkQualityLevel } from 'components/twilio/useParticipantNetworkQualityLevel';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { isIOS } from 'react-device-detect';
import { usePrevious } from 'react-use';
import { store } from 'store';
import * as Video from 'twilio-video';
import { twilioIdentity as identity } from 'util/twilio-identity';
import { useStoreValue } from 'util/use-mapped-state';
import { useReloadSessionGuests } from 'util/use-reload-session-guests';
import { useScreenOrientation } from 'util/use-screen-orientation';

interface IVideoModuleProps {
  name?: string;
  isHost?: boolean;
  className?: string;
  active?: boolean;
  id?: string;
  you?: boolean;
  user?: ICoursePublicGuestDto;
  participant: Video.Participant;
  mute?: boolean;
  priority?: Video.Track.Priority;
  isScreenshare?: boolean;
  isLargeRoomSpeakerMobileView?: boolean;
  scrollPosition?: number;
  isLargeRoomSpeakerMobileBottomTile?: boolean;
  forceDisabledVideo?: boolean;
  isInSpotlight?: boolean;
  noHighlightParticipant?: boolean;
  broadcastingUserId?: number;
  isScreenshareInProgress?: boolean;
}

export const VideoModule: React.FC<IVideoModuleProps> = ({
  name,
  isHost = false,
  className = '',
  active,
  id,
  you,
  user,
  participant,
  mute,
  priority = 'standard',
  isLargeRoomSpeakerMobileView = false,
  scrollPosition,
  isLargeRoomSpeakerMobileBottomTile = false,
  isScreenshare,
  forceDisabledVideo = false,
  isInSpotlight,
  noHighlightParticipant = false,
  broadcastingUserId,
  isScreenshareInProgress,
}) => {
  const router = useRouter();
  const courseId = router.query.courseId as string;
  const isAudioOnlyMode = useStoreValue(store, 'mode') === 'audio';
  const videoModuleRef = useRef<HTMLDivElement>(null);
  const { isLargeRoom } = useIsLargeRoom();
  const { shouldLoadVideo } = useVirtualizedScrollVideoModule({
    scrollPosition,
    you,
    videoModuleRef,
  });
  const isPortraitRef = useRef(false);

  const publications = usePublications({
    participant,
    disabled: (isLargeRoom && !shouldLoadVideo) || forceDisabledVideo,
  });

  const isMuted = useIsMuted({
    publications: publications as Video.RemoteTrackPublication[],
    you,
  });
  const isVideoMuted = !publications.find(
    (t) => t.kind === 'video' && !t.trackName.includes('screenshare')
  );
  const orientation = useScreenOrientation();
  const prevOrientation = usePrevious(orientation);
  const isAudioDisabled = useStoreValue(store, 'isAudioDisabled');

  const videoPublication = useMemo(() => {
    return publications.find(
      (p) =>
        p.kind === 'video' &&
        (!isScreenshare || p.trackName.includes('screenshare')) &&
        (isScreenshare || !p.trackName.includes('screenshare'))
    ) as Video.RemoteVideoTrackPublication | undefined;
  }, [isScreenshare, publications]);

  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  const nameMaxLength = 30;

  const onClickName = useCallback(() => {
    store.setState({
      modal: 'KickOutModal',
      modalState: {
        user,
      },
    });
  }, [user]);

  const { refreshGuestInfos } = useReloadSessionGuests({
    courseId,
    isLargeRoom,
  });

  const { portraitVideoDimensions, landscapeVideoDimensions } =
    useCalculateMobileSpeakerBottomTilesDimensions();

  const userAvatar = useMemo(() => {
    if (!user) return;
    if (user.avatar?.url) {
      return <Avatar src={user.avatar.url} fullName={user.name} />;
    }
    return <Avatar noAvatar fullName={user.name} />;
  }, [user]);

  const handleUpdatePortraitMode = useCallback(
    (isPortrait) => {
      isPortraitRef.current = isPortrait;
      if (
        !isLargeRoomSpeakerMobileView ||
        !videoModuleRef.current ||
        !isLargeRoom
      ) {
        return;
      }
      if (isPortrait) {
        videoModuleRef.current.style.height =
          !isLargeRoomSpeakerMobileBottomTile
            ? '100%'
            : portraitVideoDimensions?.height ?? '100%';
        setTimeout(() => {
          if (videoModuleRef.current && isPortrait === isPortraitRef.current) {
            if (videoModuleRef.current.clientHeight === 0) {
              return;
            }
            const newWidth = Math.floor(
              (videoModuleRef.current.clientHeight * 16) / 29
            );
            videoModuleRef.current.style.width = `${newWidth}px`;
          }
        }, 5);
      } else {
        videoModuleRef.current.style.width = !isLargeRoomSpeakerMobileBottomTile
          ? '100%'
          : landscapeVideoDimensions?.width ?? '100%';
        setTimeout(() => {
          if (videoModuleRef.current && isPortrait === isPortraitRef.current) {
            if (videoModuleRef.current.clientWidth === 0) {
              return;
            }
            const newHeight = Math.floor(
              (videoModuleRef.current.clientWidth / 16) * 9
            );
            videoModuleRef.current.style.height = `${newHeight}px`;
          }
        }, 5);
      }
    },
    [
      isLargeRoom,
      isLargeRoomSpeakerMobileBottomTile,
      isLargeRoomSpeakerMobileView,
      landscapeVideoDimensions?.width,
      portraitVideoDimensions?.height,
    ]
  );

  useEffect(() => {
    if (you && orientation && orientation !== prevOrientation) {
      handleUpdatePortraitMode(orientation === 'portrait-primary');
    }
  }, [
    orientation,
    handleUpdatePortraitMode,
    isLargeRoomSpeakerMobileBottomTile,
    prevOrientation,
    you,
    isLargeRoomSpeakerMobileView,
  ]);

  const Participant = useMemo(() => {
    let displayName = '';
    if (name) {
      displayName = name;
    } else if (user) {
      if (user.name.length > nameMaxLength) {
        // eslint-disable-next-line no-param-reassign
        user.name = `${user.name.slice(0, nameMaxLength - 1)}...`;
        displayName = user.name;
      } else {
        displayName = user.name;
      }
    }
    return (
      <div className="participant">
        <button
          disabled={!isHost || identity(participant).isAdmin || (you && isHost)}
          onClick={onClickName}
          className="name"
          key={`name-button-${id}`}
        >
          <div className="hint">{i18n('text.remove', 'Remove')}</div>
          <span className="display-name">{displayName}</span>
          {(isMuted || mute) && (
            <div className="indicator mic-off" key={`mute-${id}`} />
          )}
          {isVideoMuted && (
            <div className="indicator cam-off" key={`mute-video-${id}`} />
          )}
        </button>
      </div>
    );
  }, [
    id,
    isHost,
    isMuted,
    isVideoMuted,
    mute,
    name,
    onClickName,
    participant,
    user,
    you,
  ]);

  if (courseId && !(user || {}).name) {
    // since this function is debounced it's ok to call many times in render
    refreshGuestInfos({ isLargeRoom, courseId });
  }

  const isCurrentUserActiveSpeaker = useMemo(() => {
    if (noHighlightParticipant) {
      return false;
    }
    if (isInSpotlight) {
      return true;
    }
    if (isMuted || mute) {
      return false;
    }
    if (active) {
      return true;
    }
    if (you) {
      return !isAudioDisabled && !isScreenshare;
    }
    return true;
  }, [
    noHighlightParticipant,
    isInSpotlight,
    isMuted,
    mute,
    active,
    you,
    isAudioDisabled,
    isScreenshare,
  ]);

  const trackPriority: Video.Track.Priority = useMemo(() => {
    if (isScreenshare) {
      return 'high';
    }
    if (broadcastingUserId === user?.userId && isScreenshareInProgress) {
      return 'standard';
    }
    if (isMuted || mute) {
      return 'low';
    }
    return priority;
  }, [
    broadcastingUserId,
    isMuted,
    isScreenshare,
    isScreenshareInProgress,
    mute,
    priority,
    user?.userId,
  ]);

  return (
    <div
      className={`video-module ${
        isCurrentUserActiveSpeaker ? 'active' : ''
      } ${className} ${you && !isScreenshare ? 'you' : ''} ${
        isScreenshare ? 'screensharing' : ''
      }`}
      key={`vm-c-${participant.identity}`}
      ref={videoModuleRef}
    >
      <div className="camera-off-container">
        {userAvatar}
        {Participant}
      </div>
      {!isAudioOnlyMode && videoPublication && (
        <VideoPublication
          key={`pub-${videoPublication.trackName}`}
          publication={videoPublication}
          mute={mute}
          priority={trackPriority}
          onUpdatePortraitMode={handleUpdatePortraitMode}
          isScreenshare={isScreenshare}
          participant={participant}
          courseId={courseId}
        >
          {Participant}
        </VideoPublication>
      )}
      {!you && isHost && (
        <ModeratorControls
          courseId={courseId}
          isMuted={isMuted}
          isVideoMuted={isVideoMuted}
          participant={participant}
        />
      )}
      {networkQualityLevel !== undefined &&
        networkQualityLevel !== null &&
        networkQualityLevel < 2 && <span className="low-signal" />}
    </div>
  );
};
