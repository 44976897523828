/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import { stringify } from 'components/router/qs';

import { urls } from 'consts/urls';
import { trackError } from './trackError';

// Get the path string for the route with name `name`
// Best understood with an example:

// ```
// const routes = {
//   myRoute: '/some/:fancy/:route'
// }
// url('myRoute', {
//   args: {fancy: 12, route: 'r2d2'},
//   queries: {search: 'hi'}
// })
// > '/some/12/r2d2?search=hi'
// ```

export const url = (
  name: string,
  {
    args = {},
    queries = {},
  }: { args?: Record<string, any>; queries?: Record<string, any> } = {}
) => {
  let ns = '';
  if (name.indexOf('.') !== -1) {
    [ns, name] = name.split('.');
  }

  const scheme = urls.find(({ namespace }) => namespace === ns);
  if (!scheme) {
    trackError(`No route scheme with namespace`, ns);
    return '';
  }

  const rule = scheme.routes[name as keyof typeof scheme.routes];
  if (!rule) {
    trackError(`No route found for name`, name, ns);
    return '';
  }

  const replaced = Object.keys(args).reduce(
    (acc, k) => acc.replace(`:${k}`, args[k]),
    rule
  );
  const hasQueries = Object.keys(queries).length > 0;

  return `${scheme.url}${replaced}${
    !hasQueries ? '' : `?${stringify(queries)}`
  }`;
};

/**
 * Reads a URL that has been seeded with parts in {}, and replaces the elements in {} with different values.
 *
 * @example
 * // returns https://alpha.org/wp-json/wp/v2/pages/1234
 * buildUrlWithUriMap('https://alpha.org/wp-json/wp/v2/pages/{page_id}', {page_id: 1234})
 *
 * @param {string} url The URL to use as base
 * @param {{[key:string]: string}} uriMap The mapping for parts to replace
 * @param {RegExp} replacementWrapperRegex Optional regex to match replacements on, defaulting to {}
 */
export const buildUrlWithUriMap = (
  url: string,
  uriMap: Record<string, number>,
  replacementWrapperRegex = /{|}/
) => {
  const urlParts = url.split(replacementWrapperRegex);
  return urlParts
    .map((part) => {
      if (uriMap.hasOwnProperty(part)) {
        return uriMap[part];
      }
      return part;
    })
    .join('');
};
