/* eslint-disable camelcase */
import { ICourseGuestUserDto } from 'api/CourseGuestUserDto';
import { Fetcher } from 'api/Fetcher';
import {
  TBreakoutRoomDtoModified,
  TBreakoutRoomObjectDto,
} from 'models/BreakoutRoomDto';
import { useCallback, useEffect, useState } from 'react';
import { store } from 'store';
import { url } from 'util/url';
import { useStorePath } from 'util/use-store-path';
import { useStoreValue } from './use-mapped-state';
import { useTwilioSyncSubscription } from './use-twilio-sync-subscription';

export const useBreakoutRoomTwilioSync = ({
  groupId,
}: {
  groupId?: number;
}) => {
  const token = useStoreValue(store, 'videoToken');
  const document = useTwilioSyncSubscription<TBreakoutRoomObjectDto>({
    token: groupId ? token : undefined,
    documentName: groupId ? `breakout-room-for-group-${groupId}` : undefined,
    loggingPrefix: 'BREAKOUT-ROOM',
  });

  return document;
};

export const useBreakoutRoomUnavailableGuests = ({
  groupId,
}: {
  groupId?: number;
}) => {
  const [guests, setGuests] = useState<number[]>([]);
  const document = useBreakoutRoomTwilioSync({ groupId });

  useEffect(() => {
    if (!document) {
      return;
    }
    const unavailableGuests = Object.values(document).reduce(
      (acc, breakoutRoom) => {
        if (breakoutRoom.status === 'canceled') return acc;
        return [
          ...acc,
          ...breakoutRoom.guests
            .filter((g) => g.status !== 'left' && g.status !== 'declined')
            .map((g) => g.id),
        ];
      },
      [] as number[]
    );
    setGuests(unavailableGuests);
  }, [document]);
  return guests;
};

export const useBreakoutRoomData: (args: {
  token?: string;
  meAsGuest?: ICourseGuestUserDto;
}) => TBreakoutRoomDtoModified | undefined | false = ({ token, meAsGuest }) => {
  const [breakoutRoom, setBreakoutRoom] = useStorePath<
    TBreakoutRoomDtoModified | false
  >(store, ['breakoutRoom']);
  const document = useTwilioSyncSubscription<TBreakoutRoomObjectDto>({
    token: meAsGuest?.group ? token : undefined,
    documentName: meAsGuest?.group
      ? `breakout-room-for-group-${meAsGuest?.group}`
      : undefined,
    loggingPrefix: 'BREAKOUT-ROOM',
  });

  useEffect(() => {
    if (!document) {
      return;
    }
    const myBreakoutRooms = Object.keys(document)
      .map((id) => ({
        ...document[id],
        id,
        me: document[id].guests.find((g) => g.id === meAsGuest?.id),
      }))
      .filter(({ me }) => me)
      .sort(({ created_at }, { created_at: created_at_2 }) =>
        created_at > created_at_2 ? -1 : 1
      );
    const breakoutRoom = myBreakoutRooms[0];
    if (breakoutRoom && breakoutRoom.me) {
      setBreakoutRoom({
        ...breakoutRoom,
        myStatus: breakoutRoom.me.status,
      });
    } else {
      setBreakoutRoom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, meAsGuest?.id]);

  return breakoutRoom;
};

export const useBreakoutRoomInviteUpdate = (
  breakoutRoom?: TBreakoutRoomDtoModified
) => {
  const breakoutRoomInviteUpdate = useCallback(
    (accept) => {
      if (!breakoutRoom) {
        return;
      }
      return Fetcher.makeRequest(
        url('api.breakoutRoomRespond', {
          args: { breakoutRoomId: breakoutRoom.id },
        }),
        {
          method: 'PATCH',
          data: { accept },
        }
      );
    },
    [breakoutRoom]
  );

  const breakoutRoomAcceptedUserLeave = useCallback(() => {
    if (!breakoutRoom) {
      return;
    }
    return Fetcher.makeRequest(
      url('api.breakoutRoomLeave', {
        args: { breakoutRoomId: breakoutRoom.id },
      }),
      {
        method: 'PATCH',
        data: {},
      }
    );
  }, [breakoutRoom]);

  return { breakoutRoomInviteUpdate, breakoutRoomAcceptedUserLeave };
};
