/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

interface ISelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  name: string;
  label: string;
}

export const Select: React.FC<ISelectProps> = ({
  name,
  label,
  children,
  ...props
}) => (
  <div className="select-wrap">
    {label && <label>{label}</label>}
    <div className="select">
      <select name={name} {...props}>
        {children}
      </select>
    </div>
  </div>
);
