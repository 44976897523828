import React from 'react';

interface ICheckboxProps {
  id: string;
  name?: string;
  value?: string;
  label?: string;
  variant?: string;
  'data-testid'?: string;
}

export const Checkbox: React.FC<
  ICheckboxProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  id,
  name,
  value,
  label,
  variant,
  'data-testid': dataTestId,
  ...props
}) => (
  <div className={`checkbox-component ${variant || ''}`}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <input type="checkbox" name={name} value={value} id={id} {...props} />
    <label data-testid={dataTestId} htmlFor={id}>
      {label}
    </label>
  </div>
);
