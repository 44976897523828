import { useCallback, useEffect, useRef, useState } from 'react';
import { useMountedState, usePrevious } from 'react-use';
import { SyncClient, SyncDocument } from 'twilio-sync';

const DEV_IS_LOGGING_ENABLED = false;

interface ITwilioSubscriptionProps {
  token?: string;
  documentName?: string;
  loggingPrefix?: string;
}

// TODO: remove console.logs
export const useTwilioSyncSubscription = <T>({
  token,
  documentName,
  loggingPrefix,
}: ITwilioSubscriptionProps) => {
  const [syncClient, setSyncClient] = useState<SyncClient>();
  const prevToken = usePrevious(token);
  const [document, setDocument] = useState<T>();
  const documentRef = useRef<SyncDocument>();
  const isMounted = useMountedState();
  const [isSubscriptionDone, setIsSubscriptionDone] = useState(false);
  const isSubscriptionInProgress = useRef(false);
  const [shouldReconnect, setShouldReconnect] = useState<boolean>(false);

  const handleShutdownSyncClient = useCallback(async () => {
    if (!syncClient) {
      return;
    }
    if (DEV_IS_LOGGING_ENABLED) {
      console.log(`SHUTTING SYNC CLIENT ${loggingPrefix}`);
    }
    await syncClient.shutdown();
    setIsSubscriptionDone(false);
    setSyncClient(undefined);
  }, [loggingPrefix, syncClient]);

  const handleConnectSyncClient = useCallback(
    async (token: string) => {
      if (DEV_IS_LOGGING_ENABLED) {
        console.log(`CREATING SYNC CLIENT ${loggingPrefix}`);
      }
      const client = new SyncClient(token, { logLevel: 'warn' });
      setShouldReconnect(false);
      setSyncClient(client);
    },
    [loggingPrefix]
  );

  useEffect(() => {
    if (token && (prevToken !== token || shouldReconnect)) {
      if (syncClient) {
        handleShutdownSyncClient().then(() => handleConnectSyncClient(token));
        return;
      }
      handleConnectSyncClient(token);
    } else if (!token && syncClient) {
      handleShutdownSyncClient();
    }
  }, [
    handleConnectSyncClient,
    handleShutdownSyncClient,
    prevToken,
    syncClient,
    token,
    shouldReconnect,
  ]);

  const handleUpdateDocument: (args: { data: T }) => void = useCallback(
    ({ data }) => {
      if (isMounted()) {
        if (DEV_IS_LOGGING_ENABLED) {
          console.log(`UPDATING SYNC DOCUMENT ${loggingPrefix}`, data);
        }
        setDocument(data);
      }
    },
    [isMounted, loggingPrefix]
  );

  const handleSubscribeDocument = useCallback(() => {
    if (!syncClient) {
      return;
    }
    isSubscriptionInProgress.current = true;
    if (DEV_IS_LOGGING_ENABLED) {
      console.log(
        `CONNECTING SYNC DOCUMENT ${loggingPrefix} - ${documentName}`
      );
    }
    syncClient
      .document(documentName)
      .then((d) => {
        if (!isMounted()) {
          return;
        }
        if (DEV_IS_LOGGING_ENABLED) {
          console.log(
            `CONNECTED SYNC DOCUMENT ${loggingPrefix} - ${documentName}`,
            d,
            syncClient
          );
        }
        setIsSubscriptionDone(true);
        documentRef.current = d;
        d.on('updated', handleUpdateDocument);
        handleUpdateDocument(d as any);
      })
      .catch((err) => {
        console.error(
          `Twilio Sync document error ${loggingPrefix} - ${documentName}`,
          err
        );
        if (err.message.includes('Connection is not initialized')) {
          setShouldReconnect(true);
        }
      })
      .finally(() => {
        isSubscriptionInProgress.current = false;
      });
  }, [
    documentName,
    handleUpdateDocument,
    isMounted,
    loggingPrefix,
    syncClient,
  ]);

  useEffect(() => {
    if (!documentName || !syncClient) {
      if (documentRef.current) {
        documentRef.current.close();
      }
      setIsSubscriptionDone(false);
      setDocument(undefined);
      return;
    }
    if (
      syncClient &&
      !isSubscriptionDone &&
      !isSubscriptionInProgress.current
    ) {
      handleSubscribeDocument();
    }
  }, [
    documentName,
    handleSubscribeDocument,
    handleUpdateDocument,
    isMounted,
    isSubscriptionDone,
    loggingPrefix,
    syncClient,
  ]);

  useEffect(() => {
    if (DEV_IS_LOGGING_ENABLED) {
      console.log(`MOUNT ${loggingPrefix} - ${documentName}`);
    }
  }, [documentName, loggingPrefix]);

  useEffect(() => {
    return () => {
      if (DEV_IS_LOGGING_ENABLED) {
        console.log(`UMNOUNT ${loggingPrefix} - ${documentName}`);
      }
      if (syncClient) {
        handleShutdownSyncClient();
      }
      if (documentRef.current) {
        documentRef.current.removeListener('updated', handleUpdateDocument);
        documentRef.current.close();
      }
    };
  }, [
    documentName,
    handleShutdownSyncClient,
    handleUpdateDocument,
    loggingPrefix,
    syncClient,
  ]);

  return document;
};
