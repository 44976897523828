export function throttle<T>(
  fn: (...args: T[]) => void,
  wait: number,
  drop?: boolean
) {
  let shouldWait = false;
  let waitingArgs: T[] | undefined;
  const afterTimeout = () => {
    if (waitingArgs && !drop) {
      fn(...waitingArgs);
      waitingArgs = undefined;
      shouldWait = true;
      setTimeout(afterTimeout, wait);
    } else {
      shouldWait = false;
    }
  };
  return (...args: T[]) => {
    if (shouldWait) {
      waitingArgs = args;
    } else {
      fn(...args);
      shouldWait = true;
      setTimeout(afterTimeout, wait);
    }
  };
}
