/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import { Loading } from 'components/loading';
import en from 'public/i18n/en.json';
import languages from 'public/i18n/supported-languages.json';
import { SUPPORTED_LANGUAGES } from 'consts';
import 'moment/locale/es';
import moment from 'moment';

const i18nOptions = {
  returnEmptyString: false,
  lowerCaseLng: true,
  supportedLngs: languages.map((l) => l.code.toLowerCase()),
  keySeparator: false,
  fallbackLng: 'en',
  // debug: environment !== 'production',
  joinArrays: true,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    // defaultTransParent: 'div', // needed for preact
    wait: true,
    returnEmptyString: false,
  },
  resources: {
    en: {
      translation: en,
    },
  },
};

const getAppLanguage = () => {
  const appLanguage =
    (
      window.location.search
        .slice(1)
        .split('&')
        .find((x) => x.startsWith('lang=')) || ''
    ).slice(5) ||
    localStorage.getItem('language') ||
    navigator.language ||
    'en';
  const appLanguageFormatted = appLanguage.toLowerCase();
  let lang = appLanguageFormatted;
  const supportedLanguagesLowerCase = SUPPORTED_LANGUAGES.map((l) =>
    l.toLowerCase()
  );
  if (!supportedLanguagesLowerCase.includes(appLanguageFormatted)) {
    lang =
      supportedLanguagesLowerCase.find(
        (l) =>
          l.includes(appLanguageFormatted) || appLanguageFormatted.includes(l)
      ) || 'en';
  }
  return SUPPORTED_LANGUAGES.find((l) => l.toLowerCase() === lang) || 'en';
};

export const TranslationContainer: React.FC = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const lang = getAppLanguage();
    moment.locale(lang);
    localStorage.setItem('language', lang);
    const langLower = lang.toLowerCase();
    fetch(`/i18n/${lang}.json`)
      .then((res) => res.json())
      .then((json) => {
        return i18next
          .use(initReactI18next)
          .init({
            ...i18nOptions,
            resources: {
              en: {
                translation: en,
              },
              [langLower]: {
                translation: json,
              },
            },
            lng: langLower,
          })
          .then(() => {
            window.i18n = (...args) => i18next.t(...args);
          });
      })
      .catch(() => {
        return i18next
          .use(initReactI18next)
          .init(i18nOptions)
          .then(() => {
            window.i18n = (...args) => i18next.t(...args);
          });
      })
      .then(() => setLoaded(true));
  }, []);

  if (!loaded) return <Loading />;

  return children();
};
