import { useMappedState, useStoreValue } from 'util/use-mapped-state';
import { path as pathGet, assocPath as pathSet } from 'ramda';
import { IStore, IStoreAttributes } from 'store';

type TSetter<T> = (val: T) => void;

export function useStorePath<T>(store: IStore, path: string[]) {
  const value = useMappedState<T>(store, pathGet(path));
  const setter: TSetter<T> = (val) => {
    store.setState(pathSet(path, val, store.getState()));
  };

  return [value, setter] as [T, TSetter<T>];
}

export function useStoreState<K extends keyof IStoreAttributes>(
  store: IStore,
  key: K
): [IStoreAttributes[K], TSetter<IStoreAttributes[K]>] {
  const value = useStoreValue(store, key);
  const setter: TSetter<IStoreAttributes[K]> = (val) => {
    store.setState({
      [key]: val,
    });
  };

  return [value, setter];
}
