import { Modal } from 'components/modal';
import { EmbeddedContent } from 'components/embedded-content';
import React from 'react';

export function ConductModal() {
  return (
    <Modal className="styled-modal size-large">
      <div className="content">
        <EmbeddedContent page="code_of_conduct" className="terms-content" />
      </div>
    </Modal>
  );
}
