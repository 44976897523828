import { Fetcher } from 'api/Fetcher';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { store } from 'store';
import { url } from 'util/url';
import { useStorePath } from 'util/use-store-path';
import { expiredTokenUpdate } from './expired-token-update';
import { trackError } from './trackError';
import { useTwilioSyncSubscription } from './use-twilio-sync-subscription';

export const usePresenceToken: (args: {
  courseId?: string;
  userToken?: string;
}) => string | undefined = ({ courseId, userToken }) => {
  const router = useRouter();
  const [token, setToken] = useState<string>();
  const courseIdRef = useRef<string>();

  useEffect(() => {
    courseIdRef.current = courseId;
    const isPresenceTrackingRoute =
      router.pathname.includes('waiting') ||
      router.pathname.includes('video-call') ||
      router.pathname.includes('preview');
    if (!courseId || !userToken || !isPresenceTrackingRoute) {
      if (token) {
        setToken(undefined);
      }
      return;
    }
    if (token) return;
    Fetcher.makeRequest<{ token: string }>(url('api.presenceToken'), {
      method: 'POST',
      data: { courseId },
    }).then((res) => {
      if (res.isOk()) {
        if (courseIdRef.current === courseId) {
          setToken(res.value.token);
          expiredTokenUpdate(res.value.token, () => setToken(undefined));
        }
      } else {
        trackError('Error during trying to get presence token', res.error);
      }
    });
  }, [token, courseId, setToken, userToken, router.pathname]);

  return token;
};

type TPresenceDto = unknown;

export const usePresence = () => {
  const router = useRouter();
  const courseId = router.query.courseId as string | undefined;
  const [userToken] = useStorePath<string>(store, ['token']);
  const token = usePresenceToken({ courseId, userToken });
  const document = useTwilioSyncSubscription<TPresenceDto>({
    token,
    documentName: courseId ? `course-${courseId}` : undefined,
    loggingPrefix: 'PRESENCE',
  });

  return document;
};
