import LoadingIndicator from 'components/loading-indicator';
import { store } from 'store';
import { useStorePath } from 'util/use-store-path';
import React from 'react';

interface ISubmitButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  formName?: string;
  Loading?: React.FC | null;
  title?: string;
}

export const SubmitButton: React.FC<ISubmitButtonProps> = ({
  formName,
  disabled,
  Loading,
  title = i18n('text.submit', 'Submit'),
  children,
  ...props
}) => {
  const [submitting] = useStorePath<boolean>(store, [
    formName ?? 'non-existing',
    'submitting',
  ]);
  const loader = Loading ? <Loading /> : <LoadingIndicator />;
  return (
    <button
      type="submit"
      key={`${formName}-submit-button`}
      disabled={disabled || submitting}
      title={title}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {submitting && loader}
      {!submitting && (children || 'Submit')}
    </button>
  );
};

export default SubmitButton;
