import { useState, useEffect, useCallback } from 'react';

export const useIsSmallDevice = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleUpdateSize = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleUpdateSize);
    return () => window.removeEventListener('resize', handleUpdateSize);
  }, [handleUpdateSize]);

  return width < 767 || height < 500;
};
