import { environment } from 'util/environments';

export const DEBUG = environment !== 'production' && environment !== 'test';

export const WEB_URL = (() => {
  if (!process.browser) return '';
  const { href } = window.location;
  const path = window.location.pathname;
  if (path === '/') {
    return href.substring(0, href.length - 1);
  }
  return href.replace(path, '');
})();

const PROTOCOL = process.browser ? window.location.protocol : '';

export const API_URL =
  process.env.NEXT_PUBLIC_AOW_API_URL ??
  {
    test: `${PROTOCOL}//aoa-tdd.herokuapp.com`,
    local: `${PROTOCOL}//localhost:8000`,
    development: `${PROTOCOL}//aoa-dev.herokuapp.com`, // `${PROTOCOL}//aoadev.alphadevops.org`,
    loadTesting: 'https://input.ngrok.io',
    integration: `${PROTOCOL}//aoa-int.herokuapp.com`,
    production: `${PROTOCOL}//aoa-prod.herokuapp.com`, // `${PROTOCOL}//aoaprod.alphadevops.org`,
  }[environment ?? 'local'];

// should be square number
export const GRID_VIEW_MAX = 25;
export const MOBILE_GRID_VIEW_MAX = 4;
export const SPEAKER_VIEW_MAX = 6;
export const MOBILE_SPEAKER_VIEW_MAX = 3;

export const IS_MOCK_VIDEO_TRACKS = false;
export const MOCK_VIDEO_TRACKS_COUNT = 20;
export const IS_LANDSCAPE_MODE = true;

export const VIDEO_MODULE_LOADED_WIDTH =
  typeof window !== 'undefined' ? document.documentElement.clientWidth * 2 : 0;

export const LARGE_ROOM_PARTICIPANT_LIMIT = 50;

export const BROADCAST_COUNTDOWN = 5;

// How often should we post to the API with the video broadcasters
// current video time.
// This time is used by guests who join during a video broadcast
export const VIDEO_BROADCAST_TIME_UPDATE_INTERVAL_MS = 10000;

export const INTERCOM_APP_ID = 'zu5bgl2d';

export const TWILIO_MEDIA_REGIONS = {
  gll: 'Global Low Latency',
  au1: 'Australia',
  br1: 'Brazil',
  de1: 'Germany',
  ie1: 'Ireland',
  in1: 'India',
  jp1: 'Japan',
  sg1: 'Singapore',
  us1: 'US East Coast (Virginia)',
  us2: 'US West Coast (Oregon)',
};

export const ROLLBAR_SCRUB_FIELDS = [
  'newPassword',
  'confirmNewPassword',
  'oldPassword',
  'token',
  'refresh',
  'email',
  'fullName',
];

export enum ETranslations {
  En = 'en',
  Es419 = 'es-419',
  It = 'it',
  Ja = 'ja',
  Ko = 'ko',
  Mn = 'mn',
  Pl = 'pl',
  PtBr = 'pt-br',
  Pt = 'pt',
  Sw = 'sw',
  Th = 'th',
  ZhHant = 'zh-Hant',
  ZhHans = 'zh-Hans',
  Uk = 'uk',
  Vi = 'vi',
}

export const SUPPORTED_LANGUAGES_PROD = [
  ETranslations.ZhHans,
  ETranslations.ZhHant,
  ETranslations.En,
  ETranslations.It,
  ETranslations.Ja,
  ETranslations.Ko,
  ETranslations.Mn,
  ETranslations.Pl,
  ETranslations.PtBr,
  ETranslations.Pt,
  ETranslations.Es419,
  ETranslations.Sw,
  ETranslations.Th,
  ETranslations.Uk,
  ETranslations.Vi,
];
export const SUPPORTED_LANGUAGES_TEST = [
  ETranslations.ZhHans,
  ETranslations.ZhHant,
  ETranslations.En,
  ETranslations.It,
  ETranslations.Ja,
  ETranslations.Ko,
  ETranslations.Mn,
  ETranslations.Pl,
  ETranslations.PtBr,
  ETranslations.Pt,
  ETranslations.Es419,
  ETranslations.Sw,
  ETranslations.Th,
  ETranslations.Uk,
  ETranslations.Vi,
];
export const SUPPORTED_LANGUAGES =
  environment === 'production'
    ? SUPPORTED_LANGUAGES_PROD
    : SUPPORTED_LANGUAGES_TEST;

export const MYALPHA_INTEGRATION_COURSE_JWT_KEY =
  'myapha-integration-course-jwt-key';

export const ALPHA_SUPPORT_LINK_MAPPING: Record<string, string> = {
  en: 'https://alphanow.alpha.org/support/',
  'es-419': 'https://pruebaalpha.org/ayudaalphanow/',
};

export const PARTICIPANT_MINIMUM_FEATURE_FLAG = '3_participant_minimum_toggle';

export const SESSION_ATTENDANCE_REPORT_FLAG = 'session_attendance_report';

export const EMBEDDED_TERMS_FEATURE_FLAG = 'embed_terms';
export const TERMS_SRC_URL =
  'https://alpha.org/wp-json/wp/v2/pages/{page_id}?_fields=content';

export const TERMS_MAPPING: Record<string, Record<ETranslations, number>> = {
  terms_of_use: {
    [ETranslations.En]: 5168,
    [ETranslations.Es419]: 6087,
    [ETranslations.It]: 6183,
    [ETranslations.Ja]: 6200,
    [ETranslations.Ko]: 6208,
    [ETranslations.Mn]: 6215,
    [ETranslations.Pl]: 6223,
    [ETranslations.PtBr]: 6233,
    [ETranslations.Pt]: 6241,
    [ETranslations.Sw]: 6251,
    [ETranslations.Th]: 6259,
    [ETranslations.ZhHant]: 6267,
    [ETranslations.ZhHans]: 6483,
    [ETranslations.Uk]: 6275,
    [ETranslations.Vi]: 6284,
  },
  code_of_conduct: {
    [ETranslations.En]: 5170,
    [ETranslations.Es419]: 6097,
    [ETranslations.It]: 6197,
    [ETranslations.Ja]: 6206,
    [ETranslations.Ko]: 6213,
    [ETranslations.Mn]: 6221,
    [ETranslations.Pl]: 6228,
    [ETranslations.PtBr]: 6239,
    [ETranslations.Pt]: 6249,
    [ETranslations.Sw]: 6257,
    [ETranslations.Th]: 6265,
    [ETranslations.ZhHant]: 6273,
    [ETranslations.ZhHans]: 6497,
    [ETranslations.Uk]: 6282,
    [ETranslations.Vi]: 6291,
  },
};

export const STORAGE_LAST_DECLINED_BR_NOTIFICATION_SHOWN_KEY =
  'storage_last_declined_br_notification_shown_key';
export const BROADCAST_YOUTUBE_FEATURE_FLAG = 'broadcast_youtube';

export const IOS_APP_URL =
  'https://apps.apple.com/gb/app/alphanow/id1565818174';
export const ANDROID_APP_URL =
  'https://play.google.com/store/apps/details?id=org.alpha.mobile.android';

export const GROUP_ID_KICKED_USERS = -111;

export const VIDEO_BUFFER_TIME = 10;

export const LARGE_ROOM_COMMAND_DEADLINE_TS_STORAGE_KEY =
  'large_room_command_deadline_ts_storage_key';

export const MOVE_COMMANDS = ['YPSG', 'APSG', 'YPLR', 'APLR'];

export const SCROLL_INDICATOR_SPEAKER_MODE_KEY = 'speaker-view';
export const SCROLL_INDICATOR_GRID_MODE_KEY = 'grid-view';
export const LARGE_ROOM_ALPHA_MAX_PARTICIPANTS_COUNT = 100;
export const ROOM_ALPHA_MAX_PARTICIPANTS_COUNT = 500;
export const STORAGE_PREVIOUS_MEDIA_DEVICE_PREFERENCE_KEY =
  'storage_previous_media_device_preference_key';
export const STORAGE_USER_VIDEO_DISABLED_KEY =
  'storage_user_video_disabled_key';
export const STORAGE_USER_AUDIO_DISABLED_KEY =
  'storage_user_audio_disabled_key';
export const MUTE_DEADLINE_TS_STORAGE_KEY = 'mute_deadline_ts_storage_key';

export const GDM_OPTIONS_SCREENSHARE: DisplayMediaStreamConstraints = {
  video: true,
  audio: {
    echoCancellation: false,
  },
};

export const ROLLBAR_TOKEN = '4de11566739f43d9b39bcaacb0b1b74e';

export const DOMINANT_SPEAKER_CHANGE_MS = 15 * 1000;

export const CHAT_MAX_INPUT_LENGTH = 500;

export const STORAGE_IS_BROWSER_NOT_SUPPORTED_MODAL_SHOWN =
  'storage_is_browser_not_supported_modal_shown';

export const ALPHA_EXPIRATION_MONTHS_DEFAULT = 6;

export const STORAGE_IS_NEEDS_COUNTDOWN_FROM_SMALL_GROUP_KEY =
  'storage_is_needs_countdown_from_small_group_key';

export const STORAGE_LAST_ROOM_IS_LARGE_KEY = 'storage_last_room_is_large_key';

export const SPEAKER_VIEW_GRID_COLUMN_GAP = 8; // 0.5rem as in --grid-column-gap
