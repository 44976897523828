import React from 'react';

export const LoadingIndicator: React.FC = () => {
  return (
    <span className="loading-ellipsis">
      <span>&bull;</span>
      <span>&bull;</span>
      <span>&bull;</span>
    </span>
  );
};

export default LoadingIndicator;
