import { useCallback } from 'react';
import { setState } from 'store';

export const useChatStateClear = () => {
  const handleChatStateClear = useCallback(() => {
    setState({
      mostRecentMessage: { sid: null },
      chatDrawerOpen: undefined,
      unreadMessageCount: undefined,
    });
  }, []);

  return { handleChatStateClear };
};
