import { Result } from 'neverthrow';
import { TBroadcastCommandPayload } from 'util/broadcast';
import { url } from 'util/url';
import { Fetcher, TApiError } from './Fetcher';

export const requestBroadcastCommand: (args: {
  urlName: string;
  courseId: string;
  data: TBroadcastCommandPayload;
}) => Promise<Result<Record<string, unknown>, TApiError>> = async ({
  urlName,
  courseId,
  data,
}) => {
  return Fetcher.makeRequest<Record<string, unknown>>(
    url(urlName, { args: { courseId } }),
    {
      data,
      method: 'PATCH',
    }
  );
};
