import { useNotification } from 'components/notification';
import { useCallback, useRef } from 'react';
import { store } from 'store';
import * as Video from 'twilio-video';
import { trackError } from './trackError';
import { broadcastVideoOptions, roomVideoOptions } from './twilio-settings';

export const useCreateOrGetLocalVideoTrack = ({
  deviceId,
  isBroadcasting,
}: {
  deviceId?: string;
  isBroadcasting?: boolean;
}) => {
  const localTrackRef = useRef<Video.LocalVideoTrack | undefined>();
  const { showNotification } = useNotification();

  const createOrGetLocalVideoTrack = useCallback(
    async (trackNamePrefix?: string) => {
      if (localTrackRef.current) {
        if (
          !localTrackRef.current.isEnabled ||
          !localTrackRef.current.isStarted ||
          localTrackRef.current.isStopped
        ) {
          localTrackRef.current.stop();
          // eslint-disable-next-line no-param-reassign
          localTrackRef.current = undefined;
        } else {
          return localTrackRef.current;
        }
      }
      return Video.createLocalVideoTrack({
        ...(deviceId ? { deviceId: { exact: deviceId } } : {}),
        ...(isBroadcasting ? broadcastVideoOptions : roomVideoOptions),
        name: trackNamePrefix ? `${trackNamePrefix}${Date.now()}` : undefined,
      })
        .then((track) => {
          if (track.isStopped) {
            track.restart();
          }
          // eslint-disable-next-line no-param-reassign
          localTrackRef.current = track;
          return track;
        })
        .catch((err) => {
          store.setState({ isVideoDisabled: true });
          if (localTrackRef.current) {
            localTrackRef.current.stop();
            localTrackRef.current = undefined;
          }
          if (err.name === 'NotReadableError') {
            showNotification({
              type: 'error',
              message: i18n(
                'error.video_feed_used_by_another_app',
                'Your video can’t be seen likely due to another app also using the camera.'
              ),
            });
            return undefined;
          }
          if (err.name === 'NotAllowedError') {
            store.setState({ videoAccess: false });
            throw new Error('Caught error');
          }
          trackError(
            `${isBroadcasting ? 'Broadcast: ' : ''}Error creating video track`,
            err
          );
          throw new Error('Caught error');
        });
    },
    [deviceId, isBroadcasting, showNotification]
  );
  return { createOrGetLocalVideoTrack, localTrackRef };
};
