import React from 'react';

interface LogoProps {
  size?: number;
}

export const Logo: React.FC<LogoProps> = ({ size = 100 }) => (
  <div className="alpha-logo" style={{ fontSize: `${size}%` }}>
    <img src="/images/logo.png" alt="AlphaNow" />
  </div>
);
