import { ICourseGuestUserDto } from 'api/CourseGuestUserDto';
import { ICourseSessionDto } from 'models/CourseSessionDto';
import { useEffect, useState } from 'react';

interface IProps {
  courseSessionData?: ICourseSessionDto;
  meAsGuest?: ICourseGuestUserDto;
}

export const useIsActiveSession = ({
  courseSessionData,
  meAsGuest,
}: IProps) => {
  const [isActiveSession, setIsActiveSession] = useState<boolean>(false);

  useEffect(() => {
    if (
      courseSessionData &&
      meAsGuest &&
      courseSessionData.is_waiting_room_open &&
      courseSessionData.started_groups?.[meAsGuest.group] &&
      !courseSessionData.ended_groups?.[meAsGuest.group]
    ) {
      setIsActiveSession(true);
    } else {
      setIsActiveSession(false);
    }
  }, [courseSessionData, meAsGuest]);

  return { isActiveSession };
};
