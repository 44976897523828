import { useEffect, useState } from 'react';
import { store } from 'store';
import { TModalStates } from './TModalStates';

type TModalNames = Exclude<TModalStates['modal'], undefined | null>;

type TModalProperStates = Exclude<TModalStates['modalState'], undefined | null>;

export const useGetModalState = <T extends TModalProperStates>(
  modalName: TModalNames
) => {
  const [modalState, setModalState] = useState<T>();

  useEffect(() => {
    const storeState = store.getState();
    if (storeState.modal === modalName) {
      setModalState(storeState.modalState as T);
    }
  }, [modalName]);

  return modalState;
};
