import { Fetcher } from 'api/Fetcher';
import LoadingIndicator from 'components/loading-indicator';
import { Modal } from 'components/modal';
import React, { useEffect } from 'react';
import { store } from 'store';
import { url } from 'util/url';
import { useStorePath } from 'util/use-store-path';

export interface IBreakoutRoomHostLeftModalState {
  breakoutRoomId: string;
}

export const BreakoutRoomHostLeftModal = () => {
  const [modalState] = useStorePath<IBreakoutRoomHostLeftModalState>(store, [
    'modalState',
  ]);
  const breakoutRoomId = modalState?.breakoutRoomId;

  useEffect(() => {
    const timer = setTimeout(() => {
      Fetcher.makeRequest(
        url('api.breakoutRoomLeave', { args: { breakoutRoomId } }),
        {
          method: 'PATCH',
          data: {},
        }
      );
      store.setState({ modal: null });
    }, 3000);
    return () => clearTimeout(timer);
  }, [breakoutRoomId]);

  return (
    <Modal className="styled-modal small" hideClose>
      <div className="modal-content centered alert-container">
        <h2>
          {i18n(
            'text.breakout_room_host_left',
            'Your host/admin has left, we’re taking you back to your small group'
          )}
        </h2>
        <div className="actions">
          <LoadingIndicator />
        </div>
      </div>
    </Modal>
  );
};
