import { requestBroadcastCommand } from 'api/BroadcastApi';
import { useIsLargeRoomFeatureFlagEnabled } from 'components/large-room/hooks';
import { VIDEO_BROADCAST_TIME_UPDATE_INTERVAL_MS } from 'consts';
import router from 'next/router';
import { useCallback, useMemo } from 'react';
import { throttle } from './throttle';
import { trackError } from './trackError';

export enum EBroadcastCommands {
  Video = 'video',
  Yourself = 'yourself',
  Screenshare = 'screenshare',
  Stop = 'stop',
}

const BROADCAST_API_ROUTES = {
  [EBroadcastCommands.Video]: 'api.broadcastVideo',
  [EBroadcastCommands.Yourself]: 'api.broadcastYourself',
  [EBroadcastCommands.Screenshare]: 'api.broadcastScreenshare',
  [EBroadcastCommands.Stop]: 'api.broadcastStop',
};

const LARGE_ROOM_BROADCAST_API_ROUTES = {
  [EBroadcastCommands.Video]: 'api.largeRoomBroadcastVideo',
  [EBroadcastCommands.Yourself]: 'api.largeRoomBroadcastYourself',
  [EBroadcastCommands.Screenshare]: 'api.largeRoomBroadcastScreenshare',
  [EBroadcastCommands.Stop]: 'api.largeRoomBroadcastStop',
};

export interface IBroadcastYourselfCommandPayload {
  type: EBroadcastCommands.Yourself;
  start: boolean;
}

export interface IBRoadcastScreenshareCommandPayload {
  type: EBroadcastCommands.Screenshare;
  start: boolean;
}

export interface IBroadcastVideoCommandPayload {
  type: EBroadcastCommands.Video;
  videoIsPlaying?: boolean;
  videoTime: number;
  videoSeeked?: boolean;
}

export interface IBroadcastStopCommandPayload {
  type: EBroadcastCommands.Stop;
}

export type TBroadcastCommandPayload =
  | IBroadcastYourselfCommandPayload
  | IBroadcastVideoCommandPayload
  | IBRoadcastScreenshareCommandPayload
  | IBroadcastStopCommandPayload;

export const useBroadcast = () => {
  const courseId = router.query.courseId as string | undefined;
  const { isLargeRoomFeatureFlagEnabled } =
    useIsLargeRoomFeatureFlagEnabled(courseId);

  const broadcast = useCallback(
    (data: TBroadcastCommandPayload, additionalCourseId?: string) => {
      const affectedCourseId = courseId ?? additionalCourseId;
      if (!affectedCourseId) {
        throw new Error('No courseId present, impossible to broadcast');
      }
      const types = isLargeRoomFeatureFlagEnabled
        ? LARGE_ROOM_BROADCAST_API_ROUTES
        : BROADCAST_API_ROUTES;
      const urlName = types[data.type];
      if (!urlName) {
        trackError(`No broadcast type found called: ${data.type}`);
      }
      return requestBroadcastCommand({
        urlName,
        courseId: affectedCourseId,
        data,
      });
    },
    [courseId, isLargeRoomFeatureFlagEnabled]
  );

  const throttledBroadcast = useMemo(
    () =>
      throttle(
        (data: TBroadcastCommandPayload) => broadcast(data),
        VIDEO_BROADCAST_TIME_UPDATE_INTERVAL_MS,
        true
      ),
    [broadcast]
  );

  return { broadcast, throttledBroadcast };
};
