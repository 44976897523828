import { useCallback, useRef } from 'react';
import { store } from 'store';

export const useAutoplayTrack = () => {
  const modalShownRef = useRef(false);

  const playIfAutoplayNotWorking = useCallback((mediaEl: HTMLMediaElement) => {
    if (!mediaEl.paused) {
      return Promise.resolve(false);
    }
    if (mediaEl.hasAttribute('autoplay')) {
      return Promise.race([
        new Promise((resolve) => {
          // eslint-disable-next-line no-param-reassign
          mediaEl.onplay = resolve;
        }),
        new Promise((resolve) => {
          setTimeout(resolve, 500);
        }),
      ]).then(() => {
        return mediaEl.paused;
      });
    }
    return mediaEl.play().catch((error) => {
      return error.name === 'NotAllowedError';
    });
  }, []);

  const forceAutoplay = useCallback(
    (mediaEl: HTMLMediaElement) => {
      playIfAutoplayNotWorking(mediaEl).then((isRequired) => {
        if (isRequired) {
          mediaEl.play().catch((err) => {
            if (err.name === 'NotAllowedError' && !modalShownRef.current) {
              modalShownRef.current = true;
              store.setState({
                modal: 'AudioAutoplayPolicyModal',
                modalState: null,
              });
            }
          });
        }
      });
    },
    [playIfAutoplayNotWorking]
  );

  return { playIfAutoplayNotWorking, forceAutoplay };
};
