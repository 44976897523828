import { equals } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { IStore, IStoreAttributes } from 'store';

type TMapper<T> = (obj: any) => T | undefined;

export function useMappedState<T>(
  store: IStore,
  mapper: TMapper<T>
): T | undefined {
  const initialState = mapper(store.getState());
  const [value, setVal] = useState<T | undefined>(initialState);
  const ref = useRef<{
    value?: T;
    mapper?: TMapper<T>;
  }>({ value });
  ref.current.mapper = mapper;

  useEffect(() => {
    const checkForUpdates: () => void = () => {
      const nextState = ref.current.mapper?.(store.getState());
      if (!equals(nextState, ref.current.value)) {
        ref.current.value = nextState;
        setVal(nextState);
      }
    };

    checkForUpdates();
    return store.subscribe(checkForUpdates);
  }, [store]);

  return value;
}

// TODO: refactor all useMappedState calls into useMappedState to facilitate better typing
export function useStoreValue<K extends keyof IStoreAttributes>(
  store: IStore,
  key: K
) {
  const initialState = store.getState()[key];
  const [value, setVal] = useState(initialState);
  const ref = useRef<{
    value?: IStoreAttributes[K];
  }>({ value });

  useEffect(() => {
    const checkForUpdates: () => void = () => {
      const nextState = store.getState()[key];
      if (!equals(nextState, ref.current.value)) {
        ref.current.value = nextState;
        setVal(nextState);
      }
    };

    checkForUpdates();
    return store.subscribe(checkForUpdates);
  }, [key, store]);

  return value;
}
