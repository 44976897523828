import { ICourseGroupDto } from 'api/CourseGroupDto';
import { IPaginatedRequestDto } from 'api/PaginatedRequestDto';
import { ICourseSessionDto } from 'models/CourseSessionDto';
import { store } from 'store';
import { url } from 'util/url';
import { useRequest } from './use-request';

export const useEmptySmallGroupExists: (args: {
  courseSessionData?: ICourseSessionDto;
  groupId: number | null;
  courseId: string | undefined;
}) => boolean = ({ courseSessionData, groupId, courseId }) => {
  const groupsRequest = useRequest<IPaginatedRequestDto<ICourseGroupDto>>(
    store,
    url('api.courseGroups', { args: { courseId } })
  );
  const groups = groupsRequest.result?.results;

  // No need to end session for all groups if there is only 1 group in the course
  if (!groups || groups.length === 1 || !courseSessionData) {
    return false;
  }

  // Checking if there is any other group that has been started and contains participants
  const groupIdList = groups?.map((group) => group.id);
  const activeGroupIds = groupIdList?.filter(
    (id) =>
      id !== groupId &&
      (courseSessionData?.started_groups?.[id] ||
        courseSessionData?.is_waiting_room_open) &&
      courseSessionData?.guests_grouped?.[id] &&
      courseSessionData?.guests_grouped?.[id]?.length > 0
  );

  if (activeGroupIds && activeGroupIds?.length > 0) {
    return false;
  }
  return true;
};
