/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ICourseGuestDto } from 'api/CourseGuestDto';
import { IUserDto } from 'api/UserDto';
import { useNotification } from 'components/notification';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { store } from 'store';
import { clearTokensAndPasswordStatus } from 'util/clear-tokens-and-password-status';
import { url } from 'util/url';
import { useStoreValue } from 'util/use-mapped-state';
import { useRequest } from 'util/use-request';

const NO_TOKEN_REQUIRED = [
  '/',
  url('guest.login', { args: { courseId: '[courseId]' } }),
  url('guest.setPassword', {
    args: {
      setToken: '[setToken]',
      userId: '[userId]',
      courseId: '[courseId]',
    },
  }),
  url('guest.resetPassword', {
    args: {
      resetToken: '[resetToken]',
      userId: '[userId]',
    },
  }),
  url('guest.forgotPassword', {
    args: {
      courseId: '[courseId]',
    },
  }),
  url('admin.createCourseRequest'),
  url('admin.login'),
  url('admin.forgotPassword'),
  '/404',
];

// @ts-ignore
export const Redirect: React.FC = ({ children }) => {
  const router = useRouter();
  const token = useStoreValue(store, 'token');
  const { pathname } = router;
  const courseId = router.query.courseId as string | undefined;
  const [requestId, setRequestId] = useState<number>(0);
  const { result: me, isLoading } = useRequest<IUserDto>(
    store,
    token ? url('api.me') : undefined,
    {
      requestId: String(requestId),
    }
  );
  const { result: meAsGuest, isLoading: isLoadingMeAsGuest } =
    useRequest<ICourseGuestDto>(
      store,
      token && pathname.includes('login') && courseId
        ? url('api.courseMe', { args: { courseId } })
        : undefined
    );

  const hasLoadedMe = token && !isLoading;
  const isCourseAdmin = me?.isCourseAdmin;
  const prevToken = usePrevious(token);
  const { showNotification } = useNotification();

  useEffect(() => {
    if (localStorage.getItem('initialUserInfo') === null && me !== undefined) {
      localStorage.setItem('initialUserInfo', JSON.stringify(me));
    }
  }, [me]);

  const initialUserInfo = localStorage.getItem('initialUserInfo')
    ? JSON.parse(localStorage.getItem('initialUserInfo') || '{}')
    : undefined;

  const userInfoChange = useCallback(
    (me: IUserDto) => {
      const userFieldsToTrack = [
        'isHostForAny',
        'isCourseAdmin',
        'isAdmin',
        'email',
      ];
      const userInfoChanged = userFieldsToTrack?.find(
        (field) => me[field as keyof IUserDto] !== initialUserInfo[field]
      );
      if (userInfoChanged) {
        return true;
      }
      return false;
    },
    [initialUserInfo]
  );

  useEffect(() => {
    if (initialUserInfo && me && userInfoChange(me) && pathname !== '/') {
      showNotification({
        message: i18n(
          'error.profile_details_changed',
          'Your profile details have changed recently, please login again'
        ),
      });
      clearTokensAndPasswordStatus();
      store.setState({ token: null, refresh: null });
      router.push('/');
    }
  }, [userInfoChange, initialUserInfo, me, pathname, router, showNotification]);

  useEffect(() => {
    if (meAsGuest && !isLoadingMeAsGuest) {
      router.push(url('guest.dashboard'));
    }
  }, [isLoadingMeAsGuest, meAsGuest, router]);

  useEffect(() => {
    if (prevToken !== token) {
      setRequestId(requestId + 1);
    }
  }, [prevToken, requestId, token]);

  // redirect root admin path to courses or login
  useEffect(() => {
    if (!me) {
      return;
    }
    if (router.asPath !== '/admin') {
      return;
    }
    if (token && me?.isTosAgreed) {
      router.push(url('guest.dashboard'));
    }
  }, [me, router, token]);

  // Make sure user accepts tos before using the app
  useEffect(() => {
    if (!me) {
      return;
    }
    if (me.isTosAgreed || router.asPath === '/') {
      return;
    }
    // logged in but not agreed to TOS yet
    // Redirect them to login to accept
    if (me.isCourseAdmin) {
      if (router.asPath !== url('guest.login', { args: { courseId } })) {
        router.push(url('admin.login', { queries: { tosNotAccepted: true } }));
      }
    } else if (courseId) {
      if (
        router.asPath !==
        url('guest.login', {
          args: { courseId },
          queries: { tosNotAccepted: true },
        })
      ) {
        router.push(
          url('guest.login', {
            args: { courseId },
            queries: { tosNotAccepted: true },
          })
        );
      }
    } else {
      router.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, courseId]);

  // Redirect if page requires auth and user is not authenticated
  useEffect(() => {
    if (
      !token &&
      !localStorage.getItem('token') &&
      !NO_TOKEN_REQUIRED.includes(pathname)
    ) {
      if (courseId) {
        router.push(url('guest.login', { args: { courseId } }));
      } else if (pathname.includes('admin')) {
        router.push(url('admin.login'));
      } else {
        router.push('/');
      }
    }
  }, [token, pathname, courseId, router]);

  // Admin login redirect if already logged in
  useEffect(() => {
    if (!me) {
      return;
    }
    if (
      token &&
      pathname === url('admin.login') &&
      hasLoadedMe &&
      me.isTosAgreed
    ) {
      router.push(url('guest.dashboard'));
    }
  }, [token, pathname, isCourseAdmin, hasLoadedMe, me, router]);

  // Redirect away from admin
  useEffect(() => {
    if (NO_TOKEN_REQUIRED.includes(pathname)) return;
    if (!pathname.includes('admin')) return;
    if (!isCourseAdmin && hasLoadedMe) {
      clearTokensAndPasswordStatus();
      router.push('/');
    }
  }, [isCourseAdmin, hasLoadedMe, pathname, router]);

  return children;
};
