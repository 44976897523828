import { requestRefreshToken } from 'components/refreshed-token-container/refreshed-token-container';
import { err, ok, Result } from 'neverthrow';
import { clearTokensAndPasswordStatus } from 'util/clear-tokens-and-password-status';

export const FAILED_REQUEST_RETRY_TIME = 5000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function retryRequestIfFails<T extends () => Promise<any>>(
  func: T
) {
  const res = await func().catch((err: Error) => {
    if (err.message.includes('Failed to fetch')) {
      return new Promise((resolve) => {
        setTimeout(() => {
          return resolve(retryRequestIfFails(func));
        }, FAILED_REQUEST_RETRY_TIME);
      });
    }
    throw err;
  });
  return res;
}

export type TApiError<T = unknown> = {
  code: number;
  error?: T;
};

export type THttpMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE';

export interface ICommonJsonRequestParams {
  noAuthToken?: boolean;
  method?: THttpMethod;
  setAcceptLanguageHeader?: boolean;
}

export interface IGetJsonRequestParams extends ICommonJsonRequestParams {
  method: 'GET';
}

export interface IPatchJsonRequestParams extends ICommonJsonRequestParams {
  method: 'PATCH';
  data: Record<string, any>;
}

export interface IPostJsonRequestParams extends ICommonJsonRequestParams {
  method: 'POST';
  data: Record<string, any>;
}

export interface IDeleteJsonRequestParams extends ICommonJsonRequestParams {
  method: 'DELETE';
  data: Record<string, any>;
}

// NOTE: Fetcher's advantages are:
//       1. It retries failed requests due to network conditions
//       2. It checks for the validity of access token and if it is not valid fetches a new one
//       3. It adds ts to successful/error responses
export class Fetcher {
  static requestAccessTokenIfNotExists({ force = false }: { force?: boolean }) {
    const refreshToken = localStorage.getItem('refresh');
    if (!refreshToken) {
      clearTokensAndPasswordStatus();
      window.location.href = '/';
      return;
    }
    const accessToken = localStorage.getItem('token');
    if (!accessToken || force) {
      return requestRefreshToken(refreshToken);
    }
  }

  static async makeRequest<TOkResponse, TErrorResponse = unknown>(
    url: string,
    params:
      | IGetJsonRequestParams
      | IPatchJsonRequestParams
      | IPostJsonRequestParams
      | IDeleteJsonRequestParams
  ): Promise<Result<TOkResponse, TApiError<TErrorResponse>>> {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };
    if (!params.noAuthToken) {
      await Fetcher.requestAccessTokenIfNotExists({});
      headers.Authorization = `Token ${localStorage.getItem('token')}`;
    }
    if (params.setAcceptLanguageHeader) {
      headers['Accept-Language'] = localStorage.getItem('language') ?? 'en';
    }
    let payload = {};
    if (
      params.method === 'PATCH' ||
      params.method === 'POST' ||
      params.method === 'DELETE'
    ) {
      payload = {
        body: JSON.stringify(params.data),
      };
    }
    return retryRequestIfFails(() =>
      fetch(url, {
        method: params.method,
        headers,
        ...payload,
      })
    ).then(async (res) => {
      if (res.status <= 299) {
        try {
          const result = (await res.json()) as TOkResponse;
          return ok(result);
        } catch (e) {
          return ok(undefined as any);
        }
      }
      if (res.status === 401 && !params.noAuthToken) {
        await Fetcher.requestAccessTokenIfNotExists({ force: true });
        headers.Authorization = `Token ${localStorage.getItem('token')}`;
        return retryRequestIfFails(() =>
          fetch(url, {
            method: params.method,
            headers,
            ...payload,
          })
        ).then(async (res) => {
          if (res.status <= 299) {
            const result = (await res.json()) as TOkResponse;
            return ok(result);
          }
          const errorJson = (await res.json()) as TErrorResponse;
          return err({
            code: res.status,
            error: errorJson,
          });
        });
      }
      try {
        const errorJson = (await res.json()) as TErrorResponse;
        return err({
          code: res.status,
          error: errorJson,
        });
      } catch (e) {
        return err({
          code: res.status,
          error: undefined as any,
        });
      }
    });
  }
}
