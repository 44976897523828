import { useEffect, useState } from 'react'

export const useParticipantNetworkQualityLevel = (participant) => {
  const [networkQualityLevel, setNetworkQualityLevel] = useState(participant.networkQualityLevel)

  useEffect(() => {
    const handleNewtorkQualityLevelChange = (newNetworkQualityLevel) =>
      setNetworkQualityLevel(newNetworkQualityLevel)

    setNetworkQualityLevel(participant.networkQualityLevel)
    participant.on('networkQualityLevelChanged', handleNewtorkQualityLevelChange)
    return () => {
      participant.removeListener('networkQualityLevelChanged', handleNewtorkQualityLevelChange)
    }
  }, [participant])

  return networkQualityLevel
}
