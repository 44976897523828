import React, { useCallback, useState } from 'react';

export interface ITextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  variant?: string;
  error?: string;
  passwordToggle?: boolean;
  truncate?: string;
  requiredErrorMessage?: string;
  label?: string;
}

export const TextInput: React.FC<ITextInputProps> = ({
  type = 'text',
  name,
  label,
  placeholder,
  variant,
  error,
  passwordToggle,
  truncate,
  requiredErrorMessage,
  title,
  ...props
}) => {
  const [revealPassword, setRevealPassword] = useState(false);
  const handleShowPasss = useCallback(
    (ev) => {
      ev.preventDefault();
      setRevealPassword(!revealPassword);
    },
    [revealPassword]
  );

  const handleRequiredValidationMessage = useCallback(
    (e) => {
      if (requiredErrorMessage) {
        e.target.setCustomValidity(requiredErrorMessage);
      }
    },
    [requiredErrorMessage]
  );

  return (
    <div title={truncate} className={`input-component ${variant || ''}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        className={truncate ? 'truncate' : ''}
        type={revealPassword ? 'text' : type}
        name={name}
        placeholder={placeholder}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        onInvalid={handleRequiredValidationMessage}
        onInput={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (e.target as any).setCustomValidity('');
        }}
      />

      {passwordToggle && (
        <button
          type="button"
          tabIndex={-1}
          className={`icon-password ${revealPassword ? 'on' : 'off'}`}
          onClick={handleShowPasss}
        >
          Toggle Password
        </button>
      )}

      {error && <span className="field-hint is-error">{error}</span>}

      {title && <span className="field-hint">{title}</span>}
    </div>
  );
};
