export const supportedLanguageNames = () => ({
  English: i18n('language.english', 'English'),
  'Spanish (LA & C)': i18n('language.spanishLAC', 'Spanish (Latin American)'),
  'Chinese (traditional)': i18n(
    'language.chineseTraditional',
    'Chinese (Traditional)'
  ),
  'Chinese (simplified)': i18n(
    'language.chineseSimplified',
    'Chinese (Simplified)'
  ),
  Italian: i18n('language.italian', 'Italian'),
  Japanese: i18n('language.japanese', 'Japanese'),
  Korean: i18n('language.korean', 'Korean'),
  Mongolian: i18n('language.mongolian', 'Mongolian'),
  Polish: i18n('language.polish', 'Polish'),
  Portuguese: i18n('language.portuguese', 'Portuguese (Portugal)'),
  'Portuguese (BR)': i18n('language.portugueseBR', 'Portuguese (Brazilian)'),
  Swahili: i18n('language.swahili', 'Swahili'),
  Thai: i18n('language.thai', 'Thai'),
  Ukrainian: i18n('language.ukrainian', 'Ukrainian'),
  Vietnamese: i18n('language.vietnamese', 'Vietnamese'),
});
