import Head from 'next/head';
import { Logo } from 'components/logo';
import { Header } from 'components/header';
import React from 'react';

interface IErrorPageProps {
  errorMessage: string;
  noHead?: boolean;
}

export const ErrorPage: React.FC<IErrorPageProps> = ({
  errorMessage,
  noHead,
}) => {
  return (
    <>
      <Head>
        <title>
          {i18n('error.something_went_wrong_generic', 'Something went wrong.')}
        </title>
      </Head>
      <div className="container">{!noHead && <Header noLogo />}</div>
      <div className="app-header pt-10 pb-2">
        <div className="container">
          <Logo />
        </div>
      </div>

      <div className="app-content">
        <div className="container">
          <h1>
            {i18n(
              'error.something_went_wrong_generic',
              'Something went wrong.'
            )}
          </h1>
          <p>
            {errorMessage}
            <br />
            {i18n(
              'text.please_try_again_or_contact_admin',
              'Please try again or contact application administrator'
            )}
          </p>
        </div>
      </div>
    </>
  );
};
