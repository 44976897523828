import { useState, useEffect } from 'react';

export const useWindowHeight = () => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (process.browser) {
      setHeight(window.innerHeight);
    }
    const update = () => {
      if (!process.browser) return;
      setHeight(window.innerHeight);
      // hack..
      // window.innerHeight is wrong after resize in iOS
      // so we need to update it on a timeout
      // https://bugs.webkit.org/show_bug.cgi?id=170595
      setTimeout(() => setHeight(window.innerHeight), 500);
    };

    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [process.browser]);
  return height;
};
