/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { Dropdown } from 'components/dropdown';
import languages from 'public/i18n/supported-languages.json';
import React from 'react';
import { supportedLanguageNames } from 'util/supported-language-names';
import { mapLanguageIfExists, useSupportedLanguages } from './utils';

export type TSupportedLanguages =
  | {
      name: string;
      code: string;
      translations: number;
      percentage: number;
      updated: string;
    }
  | {
      code: string;
      name: string;
    };

interface ILanguageDropdownTriggerProps {
  active: TSupportedLanguages;
}

export const LanguageDropdownTrigger: React.FC<ILanguageDropdownTriggerProps> =
  ({ active, ...props }) => {
    return (
      <button
        className="language-dropdown-trigger"
        {...props}
        data-testid="Page_Landing_button_dropdown_language"
      >
        <span>
          {mapLanguageIfExists(
            active?.name as unknown as keyof ReturnType<
              typeof supportedLanguageNames
            >
          )}
        </span>
      </button>
    );
  };

const supportedLanguages: TSupportedLanguages[] = languages;

export const LanguageDropdown: React.FC = () => {
  const { supportedLangs } = useSupportedLanguages();
  const active = (process.browser &&
    supportedLanguages.find(
      (l) => l.code === localStorage.getItem('language')
    )) || {
    code: 'en',
    name: 'English',
  };

  return (
    <div className="language-dropdown-container">
      <Dropdown
        uid="language"
        Trigger={(props) => (
          <LanguageDropdownTrigger active={active} {...props} />
        )}
      >
        <div className="language-dropdown">
          <h6>{i18n('text.change_language', 'Change Language')}</h6>
          <div className="languages">
            {supportedLangs.map((l) => (
              <button
                key={l.code}
                className={l.code === active.code ? 'active' : ''}
                onClick={() => {
                  localStorage.setItem('language', l.code);
                  window.location.href = `${window.location.pathname}?lang=${l.code}`;
                }}
              >
                {l.name}
              </button>
            ))}
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
