import { useEffect, useState } from 'react';
import * as Video from 'twilio-video';

export const useTrackDimensions = (
  track: Video.RemoteVideoTrack | Video.LocalVideoTrack | null
) => {
  const [trackDimensions, setTrackDimensions] = useState(
    track && { ...track.dimensions }
  );
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    if (track) {
      setTrackDimensions({ ...track.dimensions });
      const onStarted = () => setTrackDimensions({ ...track.dimensions });
      track.on('started', onStarted);
      track.on('dimensionsChanged', onStarted);
      return () => {
        track.removeListener('started', onStarted);
        track.removeListener('dimensionsChanged', onStarted);
      };
    }
  }, [track]);

  useEffect(() => {
    if (trackDimensions) {
      const { width, height } = trackDimensions;
      if (!width || !height) {
        return;
      }
      if (width < height) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
    }
  }, [trackDimensions]);
  return { dimensions: trackDimensions, isPortrait };
};
