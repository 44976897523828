import { Avatar } from 'components/avatar';
import { useVirtualizedScrollVideoModule } from 'components/large-room/hooks';
import React, { useMemo, useRef } from 'react';
import { twilioIdentity as identity } from 'util/twilio-identity';
import * as Video from 'twilio-video';

interface IVideoModuleMockProps {
  participant?: Video.Participant;
  isLandscapeMode?: boolean;
  scrollPosition?: number;
}

export const VideoModuleMock: React.FC<IVideoModuleMockProps> = ({
  participant,
  isLandscapeMode = false,
  scrollPosition,
}) => {
  const id = participant
    ? identity(participant).id
    : Math.floor(Math.random() * 100000);

  const videoModuleRef = useRef<HTMLDivElement | null>(null);
  const { shouldLoadVideo } = useVirtualizedScrollVideoModule({
    videoModuleRef,
    scrollPosition,
  });

  const Participant = useMemo(() => {
    return (
      <div className="participant">
        <button disabled className="name" key={`name-button-${id}`}>
          <div className="hint">{i18n('text.remove', 'Remove')}</div>
          <span className="display-name">
            Mock {shouldLoadVideo ? '(ON)' : '(OFF)'} {id}
          </span>
          <div className="indicator mic-off" key={`mute-${id}`} />
          <div className="indicator cam-off" key={`mute-video-${id}`} />
        </button>
      </div>
    );
  }, [id, shouldLoadVideo]);

  return (
    <div
      className={`video-module${
        isLandscapeMode ? ' video-module-mock-landscape' : ''
      }`}
      key={`vm-c-${id}`}
      ref={videoModuleRef}
    >
      <div className="camera-off-container">
        <Avatar src="/images/icons/icon-user-avatar.svg" fullName={`${id}`} />
        {Participant}
      </div>
    </div>
  );
};
