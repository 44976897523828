import { ICourseDto } from 'api/CourseDto';
import { ICourseGuestUserDto } from 'api/CourseGuestUserDto';
import { Checkbox } from 'components/checkbox';
import Form, { ErrorOrHint, SubmitButton } from 'components/form';
import { LoadingIndicator } from 'components/loading-indicator';
import { Modal } from 'components/modal';
import { useNotification } from 'components/notification';
import { TBroadcastInfoDto } from 'models/BroadcastInfoDto';
import React, { useCallback, useEffect, useState } from 'react';
import { TVideoCallUsers } from 'models/Twilio';
import { useRouter } from 'next/router';
import { assocPath } from 'ramda';
import { store } from 'store';
import { useBreakoutRoomUnavailableGuests } from 'util/breakout-room-hooks';
import { useCurrentCourseSessionData } from 'util/course-session-hooks';
import { url } from 'util/url';
import { useRequest } from 'util/use-request';
import { useStorePath } from 'util/use-store-path';
import { useIsActiveSession } from 'util/use-is-active-session';
import { IFormProps } from 'components/form/form';
import { trackError } from 'util/trackError';
import { useGetModalState } from './use-get-modal-state';

export interface IBreakoutRoomCreateModalState {
  users?: TVideoCallUsers;
  meAsGuest: ICourseGuestUserDto;
}

export const BreakoutRoomCreateModal = () => {
  const router = useRouter();
  const modalState = useGetModalState<IBreakoutRoomCreateModalState>(
    'BreakoutRoomCreateModal'
  );
  const meAsGuest = modalState?.meAsGuest;
  const users = modalState?.users;
  const courseId = router.query.courseId as string;
  const { showNotification } = useNotification();
  const course = useRequest<ICourseDto>(
    store,
    courseId && url('api.course', { args: { courseId } })
  ).result;
  const unavailableGuests = useBreakoutRoomUnavailableGuests({
    groupId: meAsGuest?.group,
  });
  const courseSessionData = useCurrentCourseSessionData();

  const { isActiveSession } = useIsActiveSession({
    courseSessionData,
    meAsGuest,
  });

  const form: IFormProps<{ course: string; guests: number[] }> = {
    name: 'BreakoutRoomCreateModal',
    action: url('api.breakoutRooms'),
    method: 'POST',
    initialData: { course: courseId, guests: [] },
    validations: {
      guests: (x) => {
        return !!(!x ||
        x.length <
          (courseSessionData?.minimum_participant_rule_turned_off ? 2 : 3)
          ? i18n('error.must_invite_minimum_participants', {
              count: courseSessionData?.minimum_participant_rule_turned_off
                ? 1
                : 2,
              defaultValue: 'You must invite at least 2 participants',
              defaultValue_plural: 'You must invite at least 3 participants',
            })
          : undefined);
      },
    },
    onSuccess: () => {
      store.setState({ modal: undefined, modalState: undefined });
    },
    onFailure: ({ err }) => {
      trackError('Error during creation of breakout room', err);
      showNotification({
        type: 'error',
        message: i18n(
          'error.unable_to_create_breakout_room',
          'We were not able to create the breakout room.'
        ),
      });
    },
  };

  const [selected, setSelected] = useState(
    meAsGuest?.id ? [meAsGuest?.id] : []
  );
  const [broadcastInfo] = useStorePath<TBroadcastInfoDto>(store, [
    'broadcastInfo',
  ]);
  const [isHostSelected, setIsHostSelected] = useState(false);
  const presentGuestIds = courseSessionData?.guests;

  const onChangeCheckbox = useCallback(
    (user) => {
      setSelected(
        selected.includes(user.id)
          ? selected.filter((id) => id !== user.id)
          : [...selected, user.id]
      );
    },
    [selected]
  );

  useEffect(() => {
    if (broadcastInfo && broadcastInfo.user_id) {
      store.setState({ modal: undefined, modalState: undefined });
    }
  }, [broadcastInfo]);

  // If you select someone who is then invited to another breakout room, we need to remove them from the selection
  useEffect(() => {
    if (!unavailableGuests || !selected) return;
    const withoutUnavailable = selected.filter(
      (g) => !unavailableGuests.includes(g)
    );
    if (withoutUnavailable.length !== selected.length) {
      setSelected(withoutUnavailable);
    }
  }, [selected, unavailableGuests]);

  useEffect(() => {
    if (!selected || !users) return;
    const selectedHosts = selected.filter((g) =>
      Object.keys(users).find((u) => users[u].id === g && users[u].host)
    );
    if (
      selectedHosts.length > 0 &&
      selected.length >=
        (courseSessionData?.minimum_participant_rule_turned_off ? 2 : 3)
    ) {
      setIsHostSelected(true);
      store.setState(
        assocPath([form.name, 'values', 'guests'], selected, store.getState())
      );
    } else {
      setIsHostSelected(false);
    }
  }, [selected, course, courseSessionData, users, form.name]);

  useEffect(
    () => () => store.setState({ [form.name]: undefined }),
    [form.name]
  );

  useEffect(() => {
    if (!unavailableGuests || !meAsGuest) return;
    if (unavailableGuests.includes(meAsGuest.id)) {
      store.setState({
        popup: undefined,
        modal: undefined,
        modalState: undefined,
      });
    }
  }, [meAsGuest, unavailableGuests]);

  useEffect(() => {
    // Only consider this if there are some users selected already for optimization
    if (selected.length) {
      const remaining = selected.filter(
        (guest) => presentGuestIds && presentGuestIds.indexOf(guest) > -1
      );
      if (remaining.length > 0) {
        setSelected(remaining);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentGuestIds]);

  return (
    <Modal className="styled-modal breakout-room-create">
      <div className="content">
        <h3>{i18n('text.breakout_room', 'Breakout Room')}</h3>
        {!unavailableGuests && <LoadingIndicator />}
        {unavailableGuests && (
          <>
            <p>
              {i18n('hint.minimum_participants_to_start_breakout_room', {
                count: courseSessionData?.minimum_participant_rule_turned_off
                  ? 1
                  : 2,
                defaultValue: `Select who you want to invite to this breakout room. You’ll need a minimum of two participants which includes one host to create the breakout room. Once enough participants have accepted, they will be moved to the room`,
                defaultValue_plural: `Select who you want to invite to this breakout room. You’ll need a minimum of three participants which includes one host to create the breakout room. Once enough participants have accepted, they will be moved to the room`,
              })}
            </p>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Form {...form}>
              <div className="checkboxes">
                {Object.values(users ?? [])
                  .filter(
                    (u) =>
                      u.group === meAsGuest?.group &&
                      u.name &&
                      presentGuestIds?.includes(u.id) &&
                      !unavailableGuests.includes(u.id)
                  )
                  .filter((u) => isActiveSession || u.host)
                  .sort(({ name: n }, { name: n2 }) =>
                    n.toLowerCase() > n2.toLowerCase() ? 1 : -1
                  )
                  .map((u) => (
                    <Checkbox
                      id={`breakout-${u.id}`}
                      checked={selected.includes(u.id)}
                      label={
                        u.host
                          ? `${i18n('tag.host', '(Host)')} ${u.name}`
                          : u.name
                      }
                      key={u.id}
                      onChange={() => onChangeCheckbox(u)}
                    />
                  ))}
              </div>
              <ErrorOrHint name="guests" formName={form.name} />
              <SubmitButton
                data-testid="Modals_Breakout_Create_button_create"
                disabled={!isHostSelected}
                formName={form.name}
                className="btn"
              >
                {i18n('text.create_breakout_room', 'Create Breakout Room')}
              </SubmitButton>
            </Form>
            <button
              data-testid="Modals_Breakout_Create_button_cancel"
              className="btn btn-text"
              onClick={() => store.setState({ modal: undefined })}
            >
              {i18n('text.cancel', 'Cancel')}
            </button>
          </>
        )}
      </div>
    </Modal>
  );
};
