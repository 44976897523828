import { Modal } from 'components/modal';
import { Trans } from 'react-i18next';
import React from 'react';
import { useGetModalState } from './use-get-modal-state';

export interface IPermissionsModalState {
  isVideo: boolean;
}

export function PermissionsModal() {
  const modalState =
    useGetModalState<IPermissionsModalState>('PermissionsModal');
  const isVideo = modalState?.isVideo;

  return (
    <Modal className="styled-modal size-large">
      <div className="content">
        <h3>{i18n('text.permission_needed', 'Permission needed')}</h3>
        {isVideo ? (
          <p>
            <Trans i18nKey="hint.grant_access_to_camera">
              To turn on your video, you'll need to grant AlphaNow access to
              your camera, then{' '}
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  window.location.reload();
                }}
              >
                reload this page.
              </a>{' '}
              Also, ensure you are using the latest version of your browser.
            </Trans>
          </p>
        ) : (
          <p>
            <Trans i18nKey="hint.grant_access_to_mic">
              To turn on your microphone, you’ll need to grant AlphaNow access
              to your microphone, then{' '}
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  window.location.reload();
                }}
              >
                reload this page.
              </a>{' '}
              Also, ensure you are using the latest version of your browser.
            </Trans>
          </p>
        )}
        <p>
          {i18n(
            'text.here_are_some_instructions_for_browsers',
            'Here are instructions for some popular browsers:'
          )}
        </p>
        <ul>
          {[
            [
              i18n('text.google', 'Google'),
              i18n(
                'text.google_instructions_link',
                'https://support.google.com/chrome/answer/2693767'
              ),
            ],
            [
              i18n('text.firefox', 'Firefox'),
              i18n(
                'text.firefox_instructions_link',
                'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions'
              ),
            ],
            [
              i18n('text.safari', 'Safari'),
              i18n(
                'text.safari_instructions_link',
                'https://support.apple.com/en-ca/guide/safari/ibrwe2159f50/mac'
              ),
            ],
            [
              i18n('text.edge', 'Edge'),
              i18n(
                'text.edge_instructions_link',
                'https://support.microsoft.com/en-us/windows/windows-10-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857'
              ),
            ],
          ].map(([s, l]) => (
            <li>
              <a target="_blank" href={l} rel="noreferrer">
                {s}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
}
