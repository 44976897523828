import { useRollbarPerson } from '@rollbar/react';
import { IUserDto } from 'api/UserDto';
import { path } from 'ramda';
import { store } from 'store';
import { url } from 'util/url';
import { useMappedState } from 'util/use-mapped-state';
import { useRequest } from 'util/use-request';
import _ from 'lodash';

export const useRollbarIdentity = () => {
  const token = useMappedState(store, path(['token']));
  const { result: me } = useRequest<IUserDto>(
    store,
    token ? url('api.me') : ''
  );

  const strippedMe = me
    ? _.pick(me, ['id', 'isAdmin', 'isCourseAdmin', 'isTosAgreed'])
    : undefined;

  useRollbarPerson(strippedMe ?? {});
};
