/* eslint-disable @typescript-eslint/no-explicit-any */
import { TApiError } from 'api/Fetcher';
import { Result } from 'neverthrow';
import { useEffect, useRef, useState } from 'react';
import { IStore } from 'store';
import { useMappedState } from 'util/use-mapped-state';
import { IRequestOptions, request as makeRequest } from './request';

export { actions, requestsReducer } from './reducer';

const OK_TIME = 30000;

// eslint-disable-next-line no-underscore-dangle
const _existing: Record<string, Promise<unknown> | undefined> = {};

const validCache = (ts: number | undefined, maxTime: number = OK_TIME) => {
  if (!ts) return false;
  const diff = Date.now() - ts;
  return diff < maxTime;
};

const requestPromise = <RequestPayload, ResponsePayload>({
  uid,
  opts,
}: {
  uid: string;
  opts: IRequestOptions<RequestPayload>;
}) => {
  if (_existing[uid]) {
    const promise = _existing[uid];
    return promise as Promise<Result<ResponsePayload, TApiError<unknown>>>;
  }
  const promise = makeRequest<RequestPayload, ResponsePayload>({
    ...opts,
  });
  _existing[uid] = promise;
  return promise;
};

type TUseRequestOptions<T> = {
  uid?: string;
  maxTime?: number;
  requestId?: string;
} & IRequestOptions<T>;

export interface IRequest<T, E = unknown> {
  result?: T;
  timestamp?: number;
  error?: null | E;
}

export interface IRequests<T, E = unknown> {
  requests: {
    [K: string]: IRequest<T, E>;
  };
}

export function useRequest<ResponsePayload, RequestPayload = unknown>(
  store: IStore,
  endpoint?: string,
  options?: TUseRequestOptions<RequestPayload> | undefined
) {
  const maxTime = options?.maxTime;
  const uid = options?.uid ?? endpoint;
  const { ...opts } = options;

  // Get existing request object in the global store, and stay in sync.
  const requestSelector = (state: IRequests<unknown>) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return (state.requests || {})[uid!] as unknown as
      | IRequest<ResponsePayload>
      | undefined;
  };

  const request = useMappedState<IRequest<ResponsePayload>>(
    store,
    requestSelector
  );

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(undefined);

  // We'll need to track if the component is mounted. We'll use
  // useRef which acts as instance variables without the class syntax.
  // And a useEffect call with no inputs, so it's only called once on mount.
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Only update local state if component is mounted
  const safeSetIsLoading = (value: boolean) =>
    mountedRef.current && setIsLoading(value);

  // And some functions to manage this request in the global store
  const cache = <ResponsePayload>(result: ResponsePayload) =>
    store.setState({
      requests: {
        ...(store.getState().requests || {}),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        [uid!]: { result, timestamp: Date.now(), error: null },
      },
    });

  const clear = () =>
    store.setState({
      requests: {
        ...(store.getState().requests || {}),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        [uid!]: undefined,
      },
    });

  const err = <E>(error: E) =>
    store.setState({
      requests: {
        ...(store.getState().requests || {}),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        [uid!]: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...((store.getState().requests || {})[uid!] || {}),
          error,
        },
      },
    });

  // Load data if a new uid/endpoint is passed down, or if timestamp changes.
  // ie. calling `clear()` will trigger this effect to call `load`.
  useEffect(() => {
    if (!uid) {
      safeSetIsLoading(false);
      return;
    }
    if (validCache(request?.timestamp, maxTime)) {
      safeSetIsLoading(false);
    } else if (endpoint && uid) {
      safeSetIsLoading(true);
      opts.endpoint = endpoint;
      const promise = requestPromise<RequestPayload, ResponsePayload>({
        uid,
        opts,
      });
      promise
        .then((result) => {
          if (result.isOk()) {
            cache(result.value);
            delete _existing[uid];
          } else {
            setError(result.error.error);
            err(result.error.error);
            delete _existing[uid];
          }
        })
        .finally(() => {
          safeSetIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, request?.timestamp, options?.requestId]);

  return {
    result: request?.result,
    clear,
    isLoading,
    error,
  };
}
