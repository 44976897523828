import { Modal } from 'components/modal';
import { Select } from 'components/select';
import { useAvailableDevices } from 'components/twilio/useDevices';
import { store } from 'store';
import { environment } from 'util/environments';
import { useStorePath } from 'util/use-store-path';
import React from 'react';

export function SettingsModal() {
  const [preferredDeviceIds = {}, setPreferredDeviceIds] = useStorePath<
    Record<string, string>
  >(store, ['preferredDeviceIds']);
  const [activeDeviceIds = {}] = useStorePath<Record<string, string>>(store, [
    'activeDeviceIds',
  ]);

  const devices = useAvailableDevices();
  const video = devices.filter((d) => d.kind === 'videoinput');
  const audioIn = devices.filter((d) => d.kind === 'audioinput');
  const audioOut = devices.filter((d) => d.kind === 'audiooutput');

  const onChange =
    (kind: string) => (ev: React.ChangeEvent<HTMLSelectElement>) => {
      setPreferredDeviceIds({
        ...preferredDeviceIds,
        [kind]: ev.target?.value,
      });
    };

  const onClickDone = () => {
    store.setState({ modal: undefined });
  };

  return (
    <Modal className="styled-modal settings-modal" hideClose>
      <div className="tabs-wrapper">
        <h2>{i18n('text.settings', 'Settings')}</h2>
        <form name="videoSettings">
          <div>
            <h3>{i18n('text.audio', 'Audio')}</h3>
            <Select
              name="microphone"
              label={i18n('text.microphone', 'Microphone')}
              onChange={onChange('audioinput')}
              value={activeDeviceIds.audioinput}
            >
              {audioIn.map((a) => (
                <option key={a.deviceId} value={a.deviceId}>
                  {a.label}
                </option>
              ))}
              {audioIn.length === 0 && (
                <option key="microphone-empty" value="">
                  {i18n('text.system_default_mic', 'System default microphone')}
                </option>
              )}
            </Select>

            <Select
              name="speaker"
              label={i18n('text.speaker', 'Speaker')}
              onChange={onChange('audiooutput')}
              value={activeDeviceIds.audiooutput}
            >
              {audioOut.map((a) => (
                <option key={a.deviceId} value={a.deviceId}>
                  {a.label}
                </option>
              ))}
              {audioOut.length === 0 && (
                <option key="speaker-empty" value="">
                  {i18n(
                    'text.system_default_speaker',
                    'System default speaker'
                  )}
                </option>
              )}
            </Select>
          </div>
          <div>
            <h3>{i18n('text.video', 'Video')}</h3>
            <Select
              name="camera"
              label={i18n('text.camera', 'Camera')}
              onChange={onChange('videoinput')}
              value={activeDeviceIds.videoinput}
            >
              {video.length === 0 && (
                <option>
                  {i18n('text.system_default_camera', 'System default camera')}
                </option>
              )}
              {video.map((v) => (
                <option key={v.deviceId} value={v.deviceId}>
                  {v.label}
                </option>
              ))}
            </Select>
          </div>

          <div className="actions">
            {environment !== 'production' && (
              <button
                className="btn btn-text"
                type="button"
                onClick={() => store.setState({ modal: 'DebugModal' })}
              >
                Debug
              </button>
            )}
            <button className="btn min-width" onClick={onClickDone}>
              {i18n('text.done', 'Done')}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
