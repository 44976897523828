import { Fetcher } from 'api/Fetcher';
import { Loading } from 'components/loading';
import React, { useEffect, useRef, useState } from 'react';
import { store } from 'store';
import { clearTokensAndPasswordStatus } from 'util/clear-tokens-and-password-status';
import { expiredTokenUpdate } from 'util/expired-token-update';
import { url } from 'util/url';
import { useStoreValue } from 'util/use-mapped-state';

type TRefreshRequestDto = {
  access: string;
};

export const requestRefreshToken = (refresh: string) => {
  return Fetcher.makeRequest<TRefreshRequestDto>(url('api.refreshToken'), {
    data: { refresh },
    noAuthToken: true,
    method: 'POST',
  }).then((res) => {
    if (res.isOk()) {
      const token = res.value.access;
      localStorage.setItem('token', token);
      const refresh = localStorage.getItem('refresh');
      store.setState({ token, refresh });
      return res;
    }
    if (res.error.code === 401) {
      clearTokensAndPasswordStatus();
      store.setState({ token: undefined, refresh: undefined });
    }
    return res;
  });
};

export const refreshToken = (
  refresh: string,
  setRefreshed?: (value: boolean) => void
) => {
  requestRefreshToken(refresh).then((res) => {
    if (res.isOk() || res.error.code === 401) {
      setRefreshed?.(true);
    } else {
      setTimeout(() => refreshToken(refresh, setRefreshed), 5000);
    }
  });
};

const useFreshToken = () => {
  const [refreshed, setRefreshed] = useState(false);
  const prevExpirationTimerRef = useRef<NodeJS.Timer>();
  const token = useStoreValue(store, 'token');

  useEffect(() => {
    const refresh = localStorage.getItem('refresh');
    const token = localStorage.getItem('token');
    if (refresh) {
      refreshToken(refresh, setRefreshed);
    } else if (token) {
      localStorage.removeItem('token');
      if (localStorage.getItem('initialUserInfo') !== null) {
        localStorage.removeItem('initialUserInfo');
      }
      store.setState({ token: undefined });
      window.location.href = '/';
    } else {
      setRefreshed(true);
    }
  }, []);

  useEffect(() => {
    if (refreshed && token) {
      const refresh = localStorage.getItem('refresh');
      const token = localStorage.getItem('token');
      if (token && refresh) {
        if (prevExpirationTimerRef.current) {
          clearTimeout(prevExpirationTimerRef.current);
        }
        prevExpirationTimerRef.current = expiredTokenUpdate(token, () => {
          setRefreshed(false);
          if (!localStorage.getItem('refresh')) {
            return;
          }
          refreshToken(refresh, setRefreshed);
        });
      }
    } else if (!token) {
      clearTimeout(prevExpirationTimerRef.current);
    }
  }, [refreshed, token]);

  return refreshed;
};

export const RefreshedTokenContainer: React.FC<any> = ({ children }) => {
  const refreshed = useFreshToken();
  if (!refreshed) {
    return <Loading />;
  }
  return children;
};
