import React, { useCallback, useEffect, useRef } from 'react'; // Can be aliased to Preact in your project
import { store } from 'store';
import { useStoreState } from 'util/use-store-path';

export const NOTIFICATION_STORE_KEY = 'show-notification';

export type INotificationType = 'error' | 'success' | 'info';

export interface INotificationData {
  open?: boolean;
  message?: string;
  type?: INotificationType;
  length?: number;
  isInfinite?: boolean;
}

export const useNotification = () => {
  const [prevNotification, setNotification] = useStoreState(
    store,
    NOTIFICATION_STORE_KEY
  );

  const showNotification = useCallback(
    (notification: INotificationData) => {
      if (notification) {
        setNotification({
          ...prevNotification,
          ...notification,
          open: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prevNotification]
  );

  const closeNotification = useCallback(() => {
    setNotification({
      message: '',
      open: false,
      isInfinite: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { showNotification, closeNotification };
};

export const Notification: React.FC = () => {
  const [notification, setNotification] = useStoreState(
    store,
    NOTIFICATION_STORE_KEY
  );
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (notification?.message && notification.open) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
      if (!notification.isInfinite) {
        timeoutRef.current = setTimeout(() => {
          setNotification({
            ...notification,
            open: false,
          });
        }, notification.length ?? 3000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    []
  );

  if (!notification?.message) {
    return null;
  }

  return (
    <div
      className={`notification-bar ${notification.type ?? 'error'} ${
        notification.open ? 'bar-open' : 'bar-closed'
      }`}
    >
      <span data-testid="Common_notification_text" className="text">
        {notification.message}
      </span>
      <div className="close-icon" />
    </div>
  );
};

export default Notification;
