import { useStorePath } from 'util/use-store-path';
import { TextInput as TextInputBase } from 'components/text-input';
import { store } from 'store';
import React, { useCallback, useEffect } from 'react';
import { ITextInputProps as ITextInputBaseProps } from 'components/text-input/text-input';

interface ITextInputpProps extends ITextInputBaseProps {
  formName: string;
  name: string;
  passwordToggle?: boolean;
  label?: string;
}

export const TextInput: React.FC<ITextInputpProps> = ({
  name,
  formName,
  ...props
}) => {
  const [error, setError] = useStorePath<string | undefined>(store, [
    formName,
    'errors',
    name,
  ]);
  const [value, setValue] = useStorePath<string>(store, [
    formName,
    'values',
    name,
  ]);

  useEffect(() => {
    return () => {
      setError(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      ev.preventDefault();
      setValue(ev.target.value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <TextInputBase
      value={value || ''}
      onChange={handleChange}
      name={name}
      error={error}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
