import { useRef, useEffect } from 'react';

export const useUnmountedRef = () => {
  const unmounted = useRef(false);
  useEffect(
    () => () => {
      unmounted.current = true;
    },
    []
  );
  return unmounted;
};
