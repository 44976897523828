import { Modal } from 'components/modal';
import { EmbeddedContent } from 'components/embedded-content';
import React from 'react';

export function TermsModal() {
  return (
    <Modal className="styled-modal size-large">
      <div className="content">
        <EmbeddedContent page="terms_of_use" className="terms-content" />
      </div>
    </Modal>
  );
}
