/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fetcher, TApiError, THttpMethod } from 'api/Fetcher';
import { assocPath, pathOr, pipe } from 'ramda';
import React, { useEffect } from 'react';
import { store } from 'store';
import { useStorePath } from 'util/use-store-path';

type TFormDoneArgs = { values?: any; errors?: any };
type TFormDone = (args: TFormDoneArgs) => void;

export interface IFormProps<
  T extends Record<string, any>,
  OkResponse = unknown,
  ErrorResponse = unknown
> {
  name: string;
  initialData?: Partial<T>;
  method?: THttpMethod;
  action?: string;
  validations?:
    | { [K in keyof T]?: (value: T[K], values: T) => boolean | string }
    | Record<string, never>;
  onSuccess?: (res: OkResponse) => void;
  onFailure?: ({
    err,
    done,
  }: {
    err: TApiError<ErrorResponse>;
    done?: TFormDone;
  }) => void;
  onSubmit?: ({ data, done }: { data: T; done: TFormDone }) => void;
  noAuth?: boolean;
  setAcceptLanguageHeader?: boolean;
}

export function Form<
  T extends Record<string, any>,
  OkResponse = unknown,
  ErrorResponse = unknown
>({
  name,
  initialData,
  validations = {},
  children,
  onSuccess,
  onFailure,
  onSubmit: customOnSubmit,
  action,
  method,
  noAuth,
  setAcceptLanguageHeader,
  ...props
}: React.PropsWithChildren<IFormProps<T, OkResponse, ErrorResponse>>) {
  const [submitting, setSubmitting] = useStorePath(store, [name, 'submitting']);
  useEffect(() => {
    store.setState(assocPath([name, 'values'], initialData, store.getState()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialData), name]);

  const onSubmit = (ev: any) => {
    ev.preventDefault();
    if (submitting) {
      return;
    }
    setSubmitting(true);

    const values = pathOr({}, [name, 'values'], store.getState()) as T;

    const errors = Object.keys(validations).reduce(
      (errs: { [K in keyof T]: boolean | string }, fieldName: keyof T) => {
        const validationResult = validations[fieldName]?.(
          values[fieldName],
          values
        );
        if (validationResult) {
          // eslint-disable-next-line no-param-reassign
          errs[fieldName] = validationResult;
        }
        return errs;
      },
      {} as { [K in keyof T]: boolean }
    );

    if (Object.keys(errors).length) {
      pipe(
        // @ts-ignore
        assocPath([name, 'errors'], errors),
        assocPath([name, 'submitting'], false),
        store.setState
      )(store.getState());
      return;
    }
    Object.keys(validations).forEach((validationField) => {
      store.setState(
        assocPath(
          [name, 'errors', validationField],
          undefined,
          store.getState()
        )
      );
    });

    const done = ({ errors = {}, values }: TFormDoneArgs = {}) =>
      store.setState(
        assocPath(
          [name],
          { errors, values, submitting: false },
          store.getState()
        )
      );
    setSubmitting(false);

    if (customOnSubmit) {
      return customOnSubmit({
        done,
        data: values,
      });
    }

    if (action) {
      Fetcher.makeRequest<OkResponse>(action, {
        method: method ?? 'GET',
        data: values,
        noAuthToken: noAuth,
        setAcceptLanguageHeader,
      })
        .then((r) => {
          if (r.isOk() && onSuccess) {
            onSuccess(r.value);
          } else if (r.isErr()) {
            if (onFailure) {
              const { error: apiError } = r;
              try {
                if (apiError.error && 'message' in apiError.error) {
                  // @ts-ignore
                  apiError.error = JSON.parse(apiError.error.message);
                }
                // eslint-disable-next-line no-empty
              } catch (e) {}
              onFailure({ err: apiError as TApiError<ErrorResponse>, done });
            }
          }
        })
        .finally(() => {
          store.setState(
            assocPath([name, 'submitting'], false, store.getState())
          );
        });
    }
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <form id={`Form-${name}`} key={name} onSubmit={onSubmit} {...props}>
      {children}
    </form>
  );
}

export default Form;
