import { ICourseGuestDto } from 'api/CourseGuestDto';
import { useStopBroadcastingAction } from 'components/broadcast-buttons';
import Form, { SubmitButton } from 'components/form';
import { IFormProps } from 'components/form/form';
import { useIsLargeRoom } from 'components/large-room/hooks';
import { Modal } from 'components/modal';
import { useNotification } from 'components/notification';
import { TBroadcastInfoDto } from 'models/BroadcastInfoDto';
import { TLargeRoomSyncDto } from 'models/LargeRoomSyncDto';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import { store } from 'store';
import { useCurrentCourseSessionData } from 'util/course-session-hooks';
import { trackError } from 'util/trackError';
import { url } from 'util/url';
import { useChatStateClear } from 'util/use-chat-state-clear';
import { useEmptySmallGroupExists } from 'util/use-empty-small-group-exists';
import { useStorePath } from 'util/use-store-path';
import { useEndAllSessionsForm } from './end-all-sessions';
import { useGetModalState } from './use-get-modal-state';

export const useEndGroupSessionForm = ({
  groupId,
  meAsGuest,
  broadcastInfo,
}: {
  groupId: number;
  meAsGuest?: ICourseGuestDto;
  broadcastInfo?: TBroadcastInfoDto;
}) => {
  const router = useRouter();
  const { showNotification } = useNotification();
  const { handleChatStateClear } = useChatStateClear();
  const { stopRequest } = useStopBroadcastingAction({
    meAsGuest,
    broadcastInfo,
  });

  const form: IFormProps<{ isInProgress: boolean }> = useMemo(() => {
    return {
      name: 'EndGroupSession',
      action: url('api.groupInProgress', { args: { groupId } }),
      initialData: { isInProgress: false },
      method: 'PATCH',
      onSuccess: () => {
        showNotification({
          type: 'success',
          message: i18n('text.session_ended', 'Session Ended'),
        });
        handleChatStateClear();
        stopRequest();
        router.push(url('guest.dashboard'));
        store.setState({ modal: undefined });
        store.setState({ modalState: undefined });
      },
      onFailure: ({ err }) => {
        trackError('Error during ending the session', err);
        store.setState({ modal: undefined });
        store.setState({ modalState: undefined });
        showNotification({
          message: i18n(
            'error.error.unable_to_end_session',
            'We were not able to end this session.'
          ),
        });
      },
    };
  }, [groupId, handleChatStateClear, router, showNotification, stopRequest]);

  return form;
};

interface IEndSessionForm {
  groupId: number;
  courseId: string | undefined;
  onClick: () => void;
  meAsGuest?: ICourseGuestDto;
  broadcastInfo?: TBroadcastInfoDto;
}

const EndSessionForm: React.FC<IEndSessionForm> = ({
  groupId,
  courseId,
  onClick,
  meAsGuest,
  broadcastInfo,
}) => {
  const endAllSessionsForm = useEndAllSessionsForm({ courseId });
  const endGroupSessionForm = useEndGroupSessionForm({
    groupId,
    meAsGuest,
    broadcastInfo,
  });
  const courseSessionData = useCurrentCourseSessionData();
  const emptySmallGroupExists = useEmptySmallGroupExists({
    courseSessionData,
    groupId,
    courseId,
  });

  if (emptySmallGroupExists) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Form {...endAllSessionsForm}>
        <SubmitButton
          data-testid="Modals_End_Group_Session_button_ok"
          formName={endAllSessionsForm.name}
          onClick={onClick}
          className="btn"
        >
          {i18n('text.end_my_small_group', 'End my small group')}
        </SubmitButton>
      </Form>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Form {...endGroupSessionForm}>
      <SubmitButton
        data-testid="Modals_End_Group_Session_button_ok"
        formName={endGroupSessionForm.name}
        onClick={onClick}
        className="btn"
      >
        {i18n('text.end_my_small_group', 'End my small group')}
      </SubmitButton>
    </Form>
  );
};

export interface IEndGroupSessionModalState {
  groupId?: number | null;
  meAsGuest?: ICourseGuestDto;
  courseId?: string;
  broadcastInfo?: TBroadcastInfoDto;
}

export const EndGroupSession: React.FC = () => {
  const modalState =
    useGetModalState<IEndGroupSessionModalState>('EndGroupSession');

  const groupId = modalState?.groupId;
  const courseId = modalState?.courseId;
  const meAsGuest = modalState?.meAsGuest;
  const isLargeRoom = useIsLargeRoom();
  const broadcastInfo = modalState?.broadcastInfo;
  const [largeRoomSync, setLargeRoomSync] = useStorePath<
    TLargeRoomSyncDto | undefined
  >(store, ['largeRoomSync']);
  const [gridView, setGridView] = useStorePath<boolean>(store, ['gridView']);
  const onClickCancel = useCallback(
    () => store.setState({ modalState: undefined, modal: undefined }),
    []
  );

  const handleEndGroupSessionClick = useCallback(() => {
    if (isLargeRoom) {
      if (largeRoomSync) {
        setLargeRoomSync(undefined);
      }
      if (!gridView) {
        setGridView(!gridView);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridView, isLargeRoom, largeRoomSync]);

  if (!modalState || !groupId) {
    return null;
  }

  return (
    <Modal className="styled-modal small">
      <div className="modal-content centered end-session-container">
        <h2>{i18n('text.end_your_small_group', 'End your small group')}</h2>
        <p>
          {i18n(
            'hint.this_will_remove_all_participants',
            'This will remove any remaining participants.'
          )}
        </p>
        <div className="actions">
          <EndSessionForm
            onClick={handleEndGroupSessionClick}
            groupId={groupId}
            courseId={courseId}
            broadcastInfo={broadcastInfo}
            meAsGuest={meAsGuest}
          />
          <button
            data-testid="Modals_End_Group_Session_button_cancel"
            type="button"
            className="btn btn-text"
            onClick={onClickCancel}
          >
            {i18n('text.cancel', 'Cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
