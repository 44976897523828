import { EFeatureFlagKeys, IFeatureFlagDto } from 'api/FeatureFlagsDto';
import { path } from 'ramda';
import { useCallback } from 'react';
import { store } from 'store';
import { useMappedState } from './use-mapped-state';

type TCheckVideoLinkFuntion = (
  value: string
) => undefined | 'vimeo' | 'youtube';
type TCheckVideoTimeParam = (value: string) => undefined | string;
type TCheckVideoTimeInSeconds = (timeParamValue: string) => undefined | number;

export const useCheckBroadcastVideoLink: () => {
  checkVideoLink: TCheckVideoLinkFuntion;
  checkTimeStamp: TCheckVideoTimeParam;
  getTimeStampInSeconds: TCheckVideoTimeInSeconds;
} = () => {
  const featureFlags = useMappedState<IFeatureFlagDto[]>(
    store,
    path(['featureFlags'])
  );
  const broadcastYoutubeFlag = featureFlags?.find(
    (flag) => flag.name === EFeatureFlagKeys.BroadcastYoutube
  );

  const checkVideoLink = useCallback(
    (value) => {
      if (/^(http(s)?:\/\/)?(www.)?(player.)?vimeo\.com/.test(value)) {
        return 'vimeo';
      }
      if (
        /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.test(
          value
        )
      ) {
        if (broadcastYoutubeFlag && broadcastYoutubeFlag.isActive) {
          return 'youtube';
        }
        return undefined;
      }
      return undefined;
    },
    [broadcastYoutubeFlag]
  );

  const checkTimeStamp = useCallback((value) => {
    const timeParamRegex = 't=([^#&\n\r]+)';
    const videoTime = value.match(timeParamRegex);
    return videoTime?.[1];
  }, []);

  const getTimeStampInSeconds = useCallback((timeParamValue) => {
    let hours = timeParamValue.match(/(\d+)h/);
    let minutes = timeParamValue.match(/(\d+)m/);
    let seconds = timeParamValue.match(/(\d+)s/);

    let totalTimeInSeconds = 0;

    if (hours) {
      hours = hours[0].replace('h', '');
      totalTimeInSeconds += hours * 60 * 60;
    }

    if (minutes) {
      minutes = minutes[0].replace('m', '');
      totalTimeInSeconds += minutes * 60;
    }

    if (seconds) {
      seconds = seconds[0].replace('s', '');
      totalTimeInSeconds += seconds * 1;
    }
    return totalTimeInSeconds;
  }, []);

  return { checkVideoLink, checkTimeStamp, getTimeStampInSeconds };
};
