/* eslint-disable prettier/prettier */
import { IUserDto } from 'api/UserDto';
import { Checkbox } from 'components/checkbox';
import { EmbeddedContent } from 'components/embedded-content';
import Form, { SubmitButton } from 'components/form';
import { IFormProps } from 'components/form/form';
import { Modal } from 'components/modal';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { store } from 'store';
import { clearTokensAndPasswordStatus } from 'util/clear-tokens-and-password-status';
import { trackError } from 'util/trackError';
import { url } from 'util/url';
import { useStorePath } from 'util/use-store-path';
import { useGetModalState } from './use-get-modal-state';

interface IAgreementTextTranslatedProps {
  openTermsOfUse: () => void;
  openConduct: () => void;
}

const AgreementTextTranslated: React.FC<IAgreementTextTranslatedProps> = ({
  openTermsOfUse,
  openConduct,
}) => {
  return (
    <Trans i18nKey="terms.i_agree_to_all_check">
      I accept the <a className="terms-link" onClick={openTermsOfUse} href="#">Terms of Use</a> of the AlphaNow app and <a className="terms-link" onClick={openConduct} href="#">Code of Conduct</a>
    </Trans>
  );
};

export interface ITermsOfUseModalState {
  onSuccess: (me: IUserDto) => void;
  me?: IUserDto;
}

export function TermsOfUseModal() {
  const modalState = useGetModalState<ITermsOfUseModalState>('TermsOfUseModal');
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [conduct, setConduct] = useState(false);
  const [termsConductChecked, setTermsConductChecked] = useState(false);

  const [_, setMe] = useStorePath<IUserDto>(store, [
    'requests',
    url('api.me'),
    'result',
  ]);

  const onChangeTermsConductAgree = useCallback(() => {
    setTermsConductChecked(!termsConductChecked);
  }, [termsConductChecked]);

  const onClickDisagree = useCallback(() => {
    clearTokensAndPasswordStatus();
    store.setState({ modal: undefined, token: undefined, refresh: undefined });
  }, []);

  const form: IFormProps<{fullName?: string, isTosAgreed: boolean}, IUserDto> = {
    name: 'termsAgree',
    action: url('api.me'),
    method: 'PATCH',
    initialData: {
      fullName: modalState?.me && modalState?.me?.fullName,
      isTosAgreed: termsConductChecked,
    },
    onSuccess: (me: IUserDto) => {
      setMe(me);
      modalState?.onSuccess(me);
    },
    onFailure: ({ err }) => {
      trackError("Error during agreeing to Terms of Use", err)
    },
  };

  const CloseButton = useMemo(() => {
    if (!termsOfUse && !conduct) {
      return (
        <div
          className="close"
          onClick={() => store.setState({ modal: undefined })}
        >
          Close
        </div>
      );
    }
    if (conduct) {
      return (
        <button
          className="component-back-button terms-custom-back"
          onClick={() => setConduct(false)}
        >
          Back
        </button>
      );
    }
    if (termsOfUse) {
      return (
        <button
          className="component-back-button terms-custom-back"
          onClick={() => setTermsOfUse(false)}
        >
          Back
        </button>
      );
    }
    return null;
  }, [conduct, termsOfUse]);

  return (
    <Modal
      className={`styled-modal terms-modal ${
        termsOfUse || conduct ? 'terms-wide' : ''
      }`}
      hideClose
      onClickedOutside={onClickDisagree}
    >
      <div className="modal-content small alert-container">
        {CloseButton}
        {!termsOfUse && !conduct && (
          <>
            <h2 className="align-left">
              {i18n(
                'text.terms_of_use_and_code_of_conduct',
                'Terms of Use & Code of Conduct'
              )}
            </h2>
            <p className="align-left terms-info">
              {i18n(
                'hint.must_be_over_18',
                ' You must be 18 or over to accept these terms and use the App. I have read the terms carefully, fully understand the content of them and voluntarily agree to them.'
              )}
            </p>

            <div className="agreement-box align-left">
              <Checkbox
                data-testid="Modals_Login_Terms_checkbox_accept"
                id="termsAgree"
                value="termsAgree"
                name="termsAgree"
                checked={termsConductChecked}
                onChange={onChangeTermsConductAgree}
              />
              <p className="small-font">
                <AgreementTextTranslated
                  openTermsOfUse={() => setTermsOfUse(true)}
                  openConduct={() => setConduct(true)}
                />
              </p>
            </div>
            <div className="actions terms-actions">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Form {...form}>
                <SubmitButton
                  data-testid="Modals_Login_Terms_button_accept"
                  disabled={!termsConductChecked}
                  formName={form.name}
                  className="btn btn-stretchy"
                >
                  {i18n('text.accept', 'Accept')}
                </SubmitButton>
              </Form>
              <button
                data-testid="Modals_Login_Terms_button_no"
                type="button"
                className="btn btn-text not-accept"
                onClick={onClickDisagree}
              >
                {i18n('text.i_do_not_accept', 'I do not accept')}
              </button>
            </div>
          </>
        )}
        {termsOfUse && (
          <div className="content">
            <EmbeddedContent page="terms_of_use" className="terms-content" />
          </div>
        )}
        {conduct && (
          <div className="content">
            <EmbeddedContent page="code_of_conduct" className="terms-content" />
          </div>
        )}
      </div>
    </Modal>
  );
}
