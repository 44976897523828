/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import '../styles/globals.css'
import 'core-js/actual/promise';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import {
  ErrorBoundary as RollbarErrorBoundary,
  Provider as RollbarProvider,
  ProviderProps as RollbarProviderProps,
} from '@rollbar/react';
import { MainAppContainer } from 'components/main-app-container';
import { Modals } from 'components/modal';
import { Alert } from 'components/modals/alert';
import { BreakoutRoomConfirmLeaveModal } from 'components/modals/breakout-room-confirm-leave-modal';
import { BreakoutRoomCreateModal } from 'components/modals/breakout-room-create-modal';
import { BreakoutRoomHostLeftModal } from 'components/modals/breakout-room-host-left-modal';
import { ConductModal } from 'components/modals/conduct-modal';
import React from 'react';
import { BrowserCompatibilityModal } from 'components/modals/browser-compatibility-warning';
import { ConfirmLeaveModal } from 'components/modals/confirm-leave-modal';
import { DebugModal } from 'components/modals/debug-modal';
import { EndAllSessions } from 'components/modals/end-all-sessions';
import { EndGroupSession } from 'components/modals/end-group-session';
import { KickOutModal } from 'components/modals/kick-out-modal';
import { TermsOfUseModal } from 'components/modals/login-terms-modal';
import { MinimumParticipantModal } from 'components/modals/minimum-participant-modal';
import { PermissionsModal } from 'components/modals/permissions-modal';
import { SettingsModal } from 'components/modals/settings-modal';
import { TermsModal } from 'components/modals/terms-modal';
import { VideoUrlModal } from 'components/modals/video-url-modal';
import Notification from 'components/notification';
import { Redirect } from 'components/redirect';
import { RefreshedTokenContainer } from 'components/refreshed-token-container';
import { TranslationContainer } from 'components/translation-container';
import { ROLLBAR_SCRUB_FIELDS, ROLLBAR_TOKEN } from 'consts';
import Head from 'next/head';
import { environment } from 'util/environments';
import { configure } from 'util/use-request/request';
import '../styles/index.less';
import { AudioAutoplayPolicyModal } from 'components/modals/audio-autoplay-policy-modal';
import Rollbar from 'rollbar';

const matomoInstance = createInstance({
  urlBase: 'https://analytics.alphadevops.org',
  siteId: 2,
});

configure({
  storage: process.browser ? localStorage : null,
} as any);

export const MODALS = {
  SettingsModal,
  VideoUrlModal,
  TermsModal,
  DebugModal,
  KickOutModal,
  ConfirmLeaveModal,
  EndAllSessions,
  EndGroupSession,
  Alert,
  PermissionsModal,
  BreakoutRoomCreateModal,
  BreakoutRoomConfirmLeaveModal,
  TermsOfUseModal,
  BreakoutRoomHostLeftModal,
  BrowserCompatibilityModal,
  MinimumParticipantModal,
  ConductModal,
  AudioAutoplayPolicyModal,
};

const rollbarConfig: RollbarProviderProps['config'] = {
  accessToken: ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: false,
  scrubFields: ROLLBAR_SCRUB_FIELDS,
  payload: {
    environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: `${process.env.NEXT_BUILD_ID}`,
        guess_uncaught_frames: true,
      },
    },
  },
};

export const rollbar = new Rollbar(rollbarConfig);

function MyApp({ Component, pageProps, router }: any) {
  return (
    <RollbarProvider instance={rollbar}>
      <RollbarErrorBoundary>
        <MatomoProvider value={matomoInstance}>
          <Head>
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/images/favicons/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/images/favicons/favicon-16x16.png"
            />
            <script id="osano" src={process.env.osanoUrl} />
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: process.env.scriptMatomo ?? '',
              }}
            />
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: process.env.scriptGeotargetly ?? '',
              }}
            />
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: process.env.internetExplorer ?? '',
              }}
            />
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{ __html: process.env.intercom ?? '' }}
            />
          </Head>
          <Notification />
          <TranslationContainer>
            {() => (
              <RefreshedTokenContainer>
                <Redirect>
                  <MainAppContainer>
                    <Modals modals={MODALS} />
                    <Component {...pageProps} {...router.query} />
                  </MainAppContainer>
                </Redirect>
              </RefreshedTokenContainer>
            )}
          </TranslationContainer>
        </MatomoProvider>
      </RollbarErrorBoundary>
    </RollbarProvider>
  );
}

export default MyApp;
