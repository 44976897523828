export enum EFeatureFlagKeys {
  SessionAttendanceReport = 'session_attendance_report',
  ThreeParticipantMinimumToggle = '3_participant_minimum_toggle',
  BroadcastYoutube = 'broadcast_youtube',
  EmbedTerms = 'embed_terms',
  Screenshare = 'screenshare_enabled',
  LargeRoom = 'large_room',
  ResumeVideo = 'resume_video',
}

export interface IFeatureFlagDto {
  name: EFeatureFlagKeys;
  isActive: boolean;
}
