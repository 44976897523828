/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Video from 'twilio-video';
import { trackError } from './trackError';

type TResolutions = Record<string, { width: number; height: number }>;

export const resolutions: TResolutions = [
  [128, 72],
  [256, 144],
  [384, 216],
  [480, 360],
  [512, 288],
  [640, 360],
  [640, 480],
  [768, 432],
  [896, 504],
  [900, 720],
  [1024, 576],
  [1152, 648],
  [1280, 720],
].reduce(
  (acc, [width, height]) => ({
    ...acc,
    [`${width} - ${height}`]: { width, height },
  }),
  {}
);

type TFrameRates = Record<string, number>;

const frameRates: TFrameRates = [4, 8, 12, 16, 20, 24, 28, 30].reduce(
  (acc, x) => ({ ...acc, [`${x}`]: x }),
  {}
);

export const connectOptions = {
  'Preferred Video Codec': {
    H264: ['H264'],
    VP8: [{ codec: 'VP8', simulcast: false }],
    'VP8 Simulcast': [{ codec: 'VP8', simulcast: true }],
  },
  'Max Subscription Bitrate': {
    unlimited: 0,
    high: 4000,
    2400: 2400,
    low: 500,
    extraLow: 250,
  },
  'Max Audio Bitrate': { unlimited: 0, low: 17 },
  'Bandwidth Profile Resolution High Priority': resolutions,
  'Bandwidth Profile Resolution Standard Priority': resolutions,
  'Bandwidth Profile Resolution Low Priority': resolutions,
  'Bandwidth Profile Video Mode': {
    collaboration: 'collaboration',
    grid: 'grid',
    presentation: 'presentation',
  },
};

const getConnectOptionsForTypeOfRoom = (type: 'Broadcast' | 'Room') => {
  return Object.keys(connectOptions).reduce(
    (acc, k) => ({
      ...acc,
      [`${type} - ${k}`]: connectOptions[k as keyof typeof connectOptions],
    }),
    {}
  ) as Record<string, any>;
};

export const options: Record<string, any> = {
  'Room - Video Capture Resolution': resolutions,
  'Room - Video Capture Frame Rate': frameRates,
  ...getConnectOptionsForTypeOfRoom('Room'),
  'Broadcast - Video Capture Resolution': resolutions,
  'Broadcast - Video Capture Frame Rate': frameRates,
  ...getConnectOptionsForTypeOfRoom('Broadcast'),
};

const defaultChoices = {
  'Room - Video Capture Resolution': '1280 - 720',
  'Room - Video Capture Frame Rate': '20',
  'Room - Preferred Video Codec': 'VP8 Simulcast',
  'Room - Bandwidth Profile Resolution High Priority': '1280 - 720',
  'Room - Bandwidth Profile Resolution Standard Priority': '900 - 720',
  'Room - Bandwidth Profile Resolution Low Priority': '640 - 360',
  'Room - Bandwidth Profile Video Mode': 'grid',
  'Room - Max Subscription Bitrate': 'high',
  'Room - Max Audio Bitrate': 'high',

  'Broadcast - Video Capture Resolution': '640 - 480',
  'Broadcast - Video Capture Frame Rate': '20',

  // With simulcast on, and capture resolution at 640 - 480 there will be 2 tracks
  // Turning off simulcast will ruin video quality for everyone if one person has
  // a poor connection.
  'Broadcast - Preferred Video Codec': 'VP8 Simulcast',
  'Broadcast - Bandwidth Profile Video Mode': 'presentation',
  'Broadcast - Max Subscription Bitrate': 'high',
  'Broadcast - Max Audio Bitrate': 'high',

  // these are irrelevant unless the ui is changed so broadcaster can
  // see other guests while broadcasting
  'Broadcast - Bandwidth Profile Resolution High Priority': '896 - 504',
  'Broadcast - Bandwidth Profile Resolution Standard Priority': '640 - 360',
  'Broadcast - Bandwidth Profile Resolution Low Priority': '384 - 216',
};

const lowDataChoices = {
  'Room - Video Capture Resolution': '384 - 216',
  'Room - Video Capture Frame Rate': '16',
  'Room - Preferred Video Codec': 'VP8 Simulcast',
  'Room - Bandwidth Profile Resolution High Priority': '640 - 480',
  'Room - Bandwidth Profile Resolution Standard Priority': '384 - 216',
  'Room - Bandwidth Profile Resolution Low Priority': '128 - 72',
  'Room - Bandwidth Profile Video Mode': 'grid',
  'Room - Max Subscription Bitrate': 'low',
  'Room - Max Audio Bitrate': 'low',
  'Broadcast - Video Capture Resolution': '480 - 360',
  'Broadcast - Video Capture Frame Rate': '16',
  'Broadcast - Preferred Video Codec': 'VP8 Simulcast',
  'Broadcast - Bandwidth Profile Resolution High Priority': '896 - 504',
  'Broadcast - Bandwidth Profile Resolution Standard Priority': '640 - 360',
  'Broadcast - Bandwidth Profile Resolution Low Priority': '384 - 216',
  'Broadcast - Bandwidth Profile Video Mode': 'presentation',
  'Broadcast - Max Subscription Bitrate': 'low',
  'Broadcast - Max Audio Bitrate': 'low',
};

const extraLowDataChoices = {
  'Room - Video Capture Resolution': '128 - 72',
  'Room - Video Capture Frame Rate': '16',
  'Room - Preferred Video Codec': 'VP8 Simulcast',
  'Room - Bandwidth Profile Resolution High Priority': '128 - 72',
  'Room - Bandwidth Profile Resolution Standard Priority': '128-72',
  'Room - Bandwidth Profile Resolution Low Priority': '128 - 72',
  'Room - Bandwidth Profile Video Mode': 'grid',
  'Room - Max Subscription Bitrate': 'extraLow',
  'Room - Max Audio Bitrate': 'low',
  'Broadcast - Video Capture Resolution': '128 - 72',
  'Broadcast - Video Capture Frame Rate': '16',
  'Broadcast - Preferred Video Codec': 'VP8 Simulcast',
  'Broadcast - Bandwidth Profile Resolution High Priority': '128 - 72',
  'Broadcast - Bandwidth Profile Resolution Standard Priority': '128 - 72',
  'Broadcast - Bandwidth Profile Resolution Low Priority': '128 - 72',
  'Broadcast - Bandwidth Profile Video Mode': 'presentation',
  'Broadcast - Max Subscription Bitrate': 'extraLow',
  'Broadcast - Max Audio Bitrate': 'low',
};

const dataMode = process.browser ? localStorage.getItem('mode') : 'normal';

const storedChoices = JSON.parse(
  (process.browser && localStorage.getItem('twilio-settings')) || '{}'
);

export const choices = (() => {
  if (Object.keys(storedChoices).length) {
    return { ...defaultChoices, storedChoices };
  }
  if (dataMode === 'low') {
    return lowDataChoices;
  }
  if (dataMode === 'extraLow') {
    return extraLowDataChoices;
  }
  return defaultChoices;
})();

const settings: Record<string, any> = Object.keys(options).reduce(
  (acc, k) => ({
    ...acc,
    [k]: choices[k as keyof typeof choices]
      ? options[k][choices[k as keyof typeof choices]]
      : trackError('No setting for', k),
  }),
  {}
);

export const roomVideoOptions = {
  ...settings['Room - Video Capture Resolution'],
  facingMode: 'user',
  frameRate: settings['Room - Video Capture Frame Rate'],
  preferredVideoCodecs: settings['Room - Preferred Video Codec'],
  aspectRatio: 1.3333333333,
  resizeMode: 'crop-and-scale',
};

export const broadcastVideoOptions = {
  ...settings['Broadcast - Video Capture Resolution'],
  facingMode: 'user',
  frameRate: settings['Broadcast - Video Capture Frame Rate'],
  preferredVideoCodecs: settings['Broadcast - Preferred Video Codec'],
};

export const roomConnectOptions = {
  networkQuality: { local: 3, remote: 3 },
  preferredVideoCodecs: settings['Room - Preferred Video Codec'],
  maxAudioBitrate: settings['Room - Max Audio Bitrate'],
  bandwidthProfile: {
    video: {
      mode: settings['Room - Bandwidth Profile Video Mode'],
      trackSwitchOffMode: 'detected',
      maxSubscriptionBitrate: settings['Room - Max Subscription Bitrate'],
      dominantSpeakerPriority: 'high',
      renderDimensions: {
        high: settings['Room - Bandwidth Profile Resolution High Priority'],
        standard:
          settings['Room - Bandwidth Profile Resolution Standard Priority'],
        low: settings['Room - Bandwidth Profile Resolution Low Priority'],
      },
    },
  },
  tracks: [],
};

export const largeRoomConnectOptions: Video.ConnectOptions = {
  networkQuality: { local: 3, remote: 3 },
  preferredVideoCodecs: settings['Room - Preferred Video Codec'],
  maxAudioBitrate: settings['Room - Max Audio Bitrate'],
  bandwidthProfile: {
    video: {
      mode: settings['Room - Bandwidth Profile Video Mode'],
      trackSwitchOffMode: 'detected',
      maxSubscriptionBitrate: settings['Room - Max Subscription Bitrate'],
      dominantSpeakerPriority: 'high',
      clientTrackSwitchOffControl: 'auto',
      contentPreferencesMode: 'auto',
    },
  },
  tracks: [],
  region: 'us1',
};

export const broadcastConnectOptions = {
  name: 'Broadcast',
  automaticSubscription: false,
  networkQuality: { local: 3, remote: 1 },
  preferredVideoCodecs: settings['Broadcast - Preferred Video Codec'],
  maxAudioBitrate: settings['Broadcast - Max Audio Bitrate'],
  bandwidthProfile: {
    video: {
      mode: settings['Broadcast - Bandwidth Profile Video Mode'],
      trackSwitchOffMode: 'predicted',
      maxSubscriptionBitrate: settings['Broadcast - Max Subscription Bitrate'],
      dominantSpeakerPriority: 'high',
      renderDimensions: {
        high: settings[
          'Broadcast - Bandwidth Profile Resolution High Priority'
        ],
        standard:
          settings[
            'Broadcast - Bandwidth Profile Resolution Standard Priority'
          ],
        low: settings['Broadcast - Bandwidth Profile Resolution Low Priority'],
      },
    },
  },
  tracks: [],
};
