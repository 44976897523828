import * as FeatureFlagsApi from 'api/FeatureFlagsApi';
import { assocPath } from 'ramda';
import { useEffect } from 'react';
import { store } from 'store';
import { trackError } from './trackError';

export const useFeatureFlags: () => void = () => {
  useEffect(() => {
    FeatureFlagsApi.requestFeatureFlags().then((res) => {
      if (res.isErr()) {
        trackError('Error during trying to retrieve feature flags', res.error);
        return;
      }
      store.setState(assocPath(['featureFlags'], res.value, store.getState()));
    });
  }, []);
};
