import { STORAGE_LAST_ROOM_IS_LARGE_KEY } from 'consts';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { store } from 'store';
import { url } from './url';

export const useLargeRoomStatePreservation = ({
  courseId,
}: {
  courseId?: string;
}) => {
  const router = useRouter();

  const preserveLargeRoomState = useCallback(
    (movingToLargeRoom?: boolean, shouldRedirect?: boolean) => {
      if (movingToLargeRoom) {
        localStorage.setItem(STORAGE_LAST_ROOM_IS_LARGE_KEY, 'true');
        store.setState({ isCurrentlyInLargeRoom: true });
      } else {
        localStorage.removeItem(STORAGE_LAST_ROOM_IS_LARGE_KEY);
        store.setState({ isCurrentlyInLargeRoom: false });
      }
      if (shouldRedirect && courseId) {
        router.push(url('guest.videoCall', { args: { courseId } }));
      }
    },
    [courseId, router]
  );

  return { preserveLargeRoomState };
};
