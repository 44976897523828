export enum EBroadcastMode {
  Vimeo = 'vimeo',
  Yourself = 'yourself',
  Screenshare = 'screenshare',
}

export type TBroadcastInfoVimeoDto = {
  broadcast_mode: EBroadcastMode.Vimeo;
  user_id: number;
  guest_id: number;
  start: boolean;
  video_is_playing: boolean;
  video_url: string;
  video_seeked: boolean;
  video_time: number;
};

export type TBroadcastInfoYourselfDto = {
  broadcast_mode: EBroadcastMode.Yourself;
  user_id: number;
  guest_id: number;
};

export type TBroadcastInfoScreenshareDto = {
  broadcast_mode: EBroadcastMode.Screenshare;
  user_id: number;
  guest_id: number;
};

export type TBroadcastInfoDto =
  | TBroadcastInfoYourselfDto
  | TBroadcastInfoVimeoDto
  | TBroadcastInfoScreenshareDto;
