import { ICourseSessionDto } from 'models/CourseSessionDto';
import { useRouter } from 'next/router';
import React, { createContext } from 'react';
import { useCourseSessionData } from 'util/course-session-hooks';
import { usePresence } from 'util/presence-hooks';
import { useAppConfigs } from 'util/use-app-configs';
import { useFeatureFlags } from 'util/use-feature-flags';
import { useRollbarIdentity } from 'util/use-rollbar-identity';
import { useWindowHeight } from 'util/use-window-height';

export const CourseSessionContext = createContext<
  ICourseSessionDto | undefined
>({});

export const MainAppContainer: React.FC = ({ children }) => {
  const router = useRouter();
  const courseId = router.query.courseId as string | undefined;
  usePresence();
  useAppConfigs();
  useFeatureFlags();
  useRollbarIdentity();
  const courseSessionData = useCourseSessionData({ courseId });

  const height = useWindowHeight();
  return (
    <CourseSessionContext.Provider value={courseSessionData}>
      <div
        className="main-app-container"
        style={{ '--windowHeight': `${height}px` } as React.CSSProperties}
      >
        {children}
      </div>
    </CourseSessionContext.Provider>
  );
};
