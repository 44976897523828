import jwtDecode from 'jwt-decode';

interface IJWTDecoded {
  exp: number;
}

export const expiredTokenUpdate: (
  token: string,
  onUpdate: () => void
) => NodeJS.Timeout | undefined = (token, onUpdate) => {
  if (token) {
    const decoded = jwtDecode(token) as IJWTDecoded;
    const update: (timeForUpdate: number) => NodeJS.Timeout = (
      timeForUpdate
    ) => {
      return setTimeout(() => {
        onUpdate();
      }, timeForUpdate);
    };
    if (decoded.exp * 1000 <= Date.now()) {
      return update(0);
    }
    return update(decoded.exp * 1000 - Date.now());
  }
};
