import { rollbar } from 'pages/_app';
import { store } from 'store';

export const trackError = (...args: any) => {
  const { isCurrentlyInLargeRoom } = store.getState();
  const largeRoomParam = window.location.href.includes('video-call')
    ? { isCurrentlyInLargeRoom }
    : {};

  rollbar.error(...args, largeRoomParam);
  // eslint-disable-next-line no-console
  console.error(...args, largeRoomParam);
};
