import { useCallback } from 'react';
import { ALPHA_SUPPORT_LINK_MAPPING } from 'consts';

const getSupportUrlByLanguage = (lang: string) => {
  return ALPHA_SUPPORT_LINK_MAPPING[lang] || ALPHA_SUPPORT_LINK_MAPPING.en;
};

export const useAlphaHelp = () => {
  const language = localStorage.getItem('language') || 'en';

  const handleClickContact = useCallback(() => {
    window.open(getSupportUrlByLanguage(language), '_blank');
  }, [language]);

  return {
    handleClickContact,
  };
};
