import React from 'react';

interface IAvatar {
  src?: string;
  fullName?: string;
  noAvatar?: boolean;
}

export const Avatar: React.FC<IAvatar> = ({
  src,
  fullName = '',
  noAvatar = false,
}) => (
  <div className={`avatar-wrap ${noAvatar ? 'no-avatar' : ''}`}>
    <div className="avatar">
      {src ? (
        <img src={src} alt={fullName[0]} />
      ) : (
        <div className="initial">{fullName[0]}</div>
      )}
    </div>
  </div>
);
