import { Modal } from 'components/modal';
import React, { useCallback } from 'react';
import { store } from 'store';
import { useGetModalState } from './use-get-modal-state';

export interface IAlertModalState {
  text: string;
  buttonText: string;
}

export const Alert: React.FC = () => {
  const modalState = useGetModalState<IAlertModalState>('Alert');

  const onClickCancel = useCallback(
    () => store.setState({ modalState: undefined, modal: undefined }),
    []
  );

  if (!modalState) {
    return null;
  }

  const { text, buttonText } = modalState;

  return (
    <Modal className="styled-modal small">
      <div className="modal-content centered alert-container">
        <h2 data-testid="Modals_Alert_text">{text}</h2>
        <div className="actions">
          <button
            data-testid="Modals_Alert_button_cancel"
            type="button"
            className="btn"
            onClick={onClickCancel}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};
