import { requestChatGuests } from 'api/ChatApi';
import { ICoursePublicGuestDto } from 'api/CoursePublicGuestsDto';
import { Fetcher } from 'api/Fetcher';
import { IPaginatedRequestDto } from 'api/PaginatedRequestDto';
import _ from 'lodash';
import { TVideoCallUsers } from 'models/Twilio';
import { assocPath } from 'ramda';
import { store } from 'store';
import { trackError } from './trackError';
import { url } from './url';

const userIdToGuestMap = (guests: ICoursePublicGuestDto[]) =>
  guests.reduce((acc: TVideoCallUsers, g) => {
    acc[g.userId] = g;
    return acc;
  }, {});

export const loadChatGuests = _.throttle(async (courseId) => {
  const res = await requestChatGuests({ courseId });
  if (res.isErr()) {
    trackError('Error during trying to retrieve chat guests', res.error);
    return undefined;
  }
  return res.value.results;
}, 3000);

export const loadPublicGuests = _.throttle(
  ({ courseId, isLargeRoom }: { courseId: string; isLargeRoom?: boolean }) => {
    const guestUrl = url('api.coursePublicGuests', {
      args: { courseId },
      queries: { limit: 1000, is_large_room: isLargeRoom },
    });
    Fetcher.makeRequest<IPaginatedRequestDto<ICoursePublicGuestDto>>(guestUrl, {
      method: 'GET',
    }).then((res) => {
      if (res.isErr()) {
        trackError(`Unable to update guests`, res.error);
      } else {
        store.setState(
          assocPath(
            ['requests', guestUrl, 'result'],
            res.value,
            store.getState()
          )
        );
        const guests = res.value?.results ?? [];
        const updatedUsers = userIdToGuestMap(guests);
        store.setState({ publicGuests: updatedUsers });
      }
    });
  },
  3000
);
