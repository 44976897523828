import { Modal } from 'components/modal';
import { Select } from 'components/select';
import React, { useCallback } from 'react';
import { choices, options } from 'util/twilio-settings';

export const DebugModal: React.FC = () => {
  const onChangeSetting = useCallback(
    (name: string, ev: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = ev.target;
      const settings = JSON.parse(
        localStorage.getItem('twilio-settings') || '{}'
      );
      localStorage.setItem(
        'twilio-settings',
        JSON.stringify({ ...settings, [name]: value })
      );
    },
    []
  );

  return (
    <Modal className="styled-modal settings-modal">
      <div className="tabs-wrapper">
        <form name="videoSettings" onSubmit={(ev) => ev.preventDefault()}>
          {Object.keys(options).map((k) => (
            <Select
              name={k}
              label={k}
              key={k}
              onChange={(e) => onChangeSetting(k, e)}
              value={choices[k as keyof typeof choices]}
            >
              {Object.keys(options[k as keyof typeof options]).map((o) => (
                <option value={o}>{o}</option>
              ))}
            </Select>
          ))}
          <div className="actions">
            <button
              type="button"
              className="btn btn-text"
              onClick={() => {
                localStorage.removeItem('twilio-settings');
                window.location.reload();
              }}
            >
              Reset all
            </button>
            <button
              type="button"
              className="btn min-width"
              onClick={() => window.location.reload()}
            >
              Apply changes
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
