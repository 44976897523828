import { useMatomo } from '@datapunt/matomo-tracker-react';
import { ICourseDto } from 'api/CourseDto';
import { EFeatureFlagKeys, IFeatureFlagDto } from 'api/FeatureFlagsDto';
import Form, { SubmitButton, TextInput } from 'components/form';
import { IFormProps } from 'components/form/form';
import { useIsLargeRoomFeatureFlagEnabled } from 'components/large-room/hooks';
import { Modal } from 'components/modal';
import { useNotification } from 'components/notification';
import { assocPath, path } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { store } from 'store';
import { trackError } from 'util/trackError';
import { url } from 'util/url';
import { useCheckBroadcastVideoLink } from 'util/use-check-broadcast-video-link';
import { useMappedState } from 'util/use-mapped-state';
import { useStorePath } from 'util/use-store-path';
import { useGetModalState } from './use-get-modal-state';

export interface IVideoUrlModalState {
  course: ICourseDto;
}

export const VideoUrlModal: React.FC = () => {
  const modalState = useGetModalState<IVideoUrlModalState>('VideoUrlModal');
  const course = modalState?.course;
  const { isLargeRoomFeatureFlagEnabled } = useIsLargeRoomFeatureFlagEnabled(
    course?.id
  );
  const featureFlags = useMappedState<IFeatureFlagDto[]>(
    store,
    path(['featureFlags'])
  );
  const broadcastYoutubeFlag = featureFlags?.find(
    (flag) => flag.name === EFeatureFlagKeys.BroadcastYoutube
  );
  const resumeVideoFlag = featureFlags?.find(
    (flag) => flag.name === EFeatureFlagKeys.ResumeVideo
  );
  const [, setShouldResumeVideo] = useStorePath(store, [
    'VideoURL',
    'values',
    'shouldResumeVideo',
  ]);
  const urlInput = useMappedState(
    store,
    path(['VideoURL', 'values', 'videoUrl'])
  );
  const { checkVideoLink } = useCheckBroadcastVideoLink();
  const { showNotification } = useNotification();
  const { trackEvent } = useMatomo();

  const previousVideoUrl =
    store.getState().previousBroadcastedVideoUrl ?? course?.videoUrl;

  const urlPath = isLargeRoomFeatureFlagEnabled
    ? 'api.largeRoomBroadcastVideo'
    : 'api.broadcastVideo';

  const sendMatomoVideoTrackEvent = useCallback(
    (url) => {
      if (checkVideoLink(url) === 'youtube') {
        trackEvent({
          category: 'Broadcast Video',
          action: 'Click Broadcast YouTube',
          name: url,
        });
      }
      if (checkVideoLink(url) === 'vimeo') {
        trackEvent({
          category: 'Broadcast Video',
          action: 'Click Broadcast Vimeo',
          name: url,
        });
      }
    },
    [checkVideoLink, trackEvent]
  );

  const form: IFormProps<{
    videoUrl?: string | null;
    videoIsPlaying: boolean;
    start: boolean;
    shouldResumeVideo: boolean;
  }> = {
    name: 'VideoURL',
    action: url(urlPath, { args: { courseId: course?.id } }),
    method: 'PATCH',
    initialData: {
      videoUrl: previousVideoUrl,
      videoIsPlaying: false,
      start: true,
      shouldResumeVideo: false,
    },
    validations: {
      videoUrl: (value?: string | null) => {
        if (!value) {
          return false;
        }
        if (checkVideoLink(value)) {
          return false;
        }
        if (broadcastYoutubeFlag && broadcastYoutubeFlag.isActive) {
          return i18n(
            'text.must_be_vimeo_or_youtube',
            'Must be a vimeo or youtube link'
          );
        }
        return i18n('text.must_be_vimeo', 'Must be a vimeo link');
      },
    },
    onSuccess: () => {
      store.setState(
        assocPath(
          [
            'requests',
            url('api.course', { args: { courseId: course?.id } }),
            'result',
          ],
          {
            ...course,
            videoUrl: store.getState().VideoURL.values.videoUrl,
          },
          store.getState()
        )
      );
      store.setState({
        modal: undefined,
        previousBroadcastedVideoUrl: store.getState().VideoURL.values.videoUrl,
        modalState: undefined,
      });
    },
    onFailure: ({ err }) => {
      trackError('Error during starting video broadcast', err);
      showNotification({
        message: i18n(
          'error.unable_to_start_broadcast',
          'We were not able to start the broadcast. Try again later.'
        ),
      });
    },
  };

  const videoUrlText = broadcastYoutubeFlag?.isActive
    ? i18n('text.paste_vimeo_youtube_link', 'Paste a Vimeo or YouTube URL link')
    : i18n('text.video_url', 'Video URL');

  const ActionButtons = useMemo(() => {
    if (!resumeVideoFlag?.isActive) {
      return (
        <div className="actions single">
          <SubmitButton
            data-testid="Modals_Video_Modal_button_start"
            formName={form.name}
            className="btn"
          >
            {i18n('text.start_video', 'Start Video')}
          </SubmitButton>
        </div>
      );
    }
    const isUrlsSame = previousVideoUrl === urlInput;
    return (
      <div className="actions single">
        <SubmitButton
          data-testid="Modals_Video_Modal_button_start"
          formName={form.name}
          className="btn btn-video-modal"
          onClick={() => {
            setShouldResumeVideo(false);
            sendMatomoVideoTrackEvent(urlInput);
          }}
        >
          {i18n('text.start_from_beginning', 'Start From Beginning')}
        </SubmitButton>
        <SubmitButton
          data-testid="Modals_Video_Modal_button_resume"
          formName={form.name}
          className="btn"
          disabled={!isUrlsSame}
          onClick={() => {
            setShouldResumeVideo(true);
            trackEvent({
              category: 'Broadcast Video',
              action: 'Click Resume Video',
            });
          }}
        >
          {i18n('text.resume', 'Resume')}
        </SubmitButton>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form.name,
    previousVideoUrl,
    resumeVideoFlag?.isActive,
    trackEvent,
    urlInput,
  ]);

  return (
    <Modal className="styled-modal">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Form {...form}>
        <div className="modal-content centered">
          <h2>Broadcast Video</h2>
          <TextInput
            data-testid="Modals_Video_Modal_input_url"
            label={videoUrlText}
            formName={form.name}
            name="videoUrl"
            placeholder={i18n('text.your_video_url', 'Your video url')}
            required
          />
          {ActionButtons}
        </div>
      </Form>
    </Modal>
  );
};
