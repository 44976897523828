import { Fetcher } from 'api/Fetcher';
import React, { useCallback } from 'react';
import * as Video from 'twilio-video';
import { twilioIdentity as identity } from 'util/twilio-identity';
import { url } from 'util/url';
import { useNotification } from 'components/notification';
import { trackError } from 'util/trackError';

interface IModeratorControlsProps {
  courseId: string;
  participant: Video.Participant;
  isVideoMuted: boolean;
  isMuted: boolean;
}

export const ModeratorControls: React.FC<IModeratorControlsProps> = ({
  courseId,
  participant,
  isMuted,
  isVideoMuted,
}) => {
  const { id } = identity(participant);
  const { showNotification } = useNotification();

  const onClickToggle = useCallback(
    ({ kind }: { kind: 'audio' | 'video' }) => {
      Fetcher.makeRequest(
        url('api.courseGuestMuteSync', { args: { courseId } }),
        {
          method: 'POST',
          data: {
            data: {
              userId: id,
              kind,
            },
          },
        }
      ).then((res) => {
        if (res.isErr()) {
          trackError(`Error during muting user's ${kind}`, res.error);
          showNotification({
            message: i18n(
              'error.something_went_wrong_generic',
              'Something went wrong.'
            ),
          });
          return;
        }
        return res.value;
      });
    },
    [courseId, id, showNotification]
  );

  return (
    <div className="moderator-controls">
      <button
        className={`mute ${isMuted ? 'off' : ''}`}
        disabled={isMuted}
        title={
          isMuted
            ? i18n('text.user_muted', "User's audio is muted.")
            : i18n('text.mute_user', "Mute user's audio")
        }
        onClick={() => onClickToggle({ kind: 'audio' })}
      />
      <button
        className={`video ${isVideoMuted ? 'off' : ''}`}
        disabled={isVideoMuted}
        title={
          isVideoMuted
            ? i18n('text.user_video_disabled', "User's video is disabled.")
            : i18n('text.disable_user_video', "Disable user's video.")
        }
        onClick={() => onClickToggle({ kind: 'video' })}
      />
    </div>
  );
};
