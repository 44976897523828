import Form, { SubmitButton } from 'components/form';
import { Modal } from 'components/modal';
import { store } from 'store';
import { url } from 'util/url';
import React from 'react';
import { IFormProps } from 'components/form/form';
import { useNotification } from 'components/notification';
import { trackError } from 'util/trackError';
import { useGetModalState } from './use-get-modal-state';

export interface IBreakoutRoomConfirmLeaveModalState {
  breakoutRoomId: string;
}

export const BreakoutRoomConfirmLeaveModal = () => {
  const { showNotification } = useNotification();
  const modalState = useGetModalState<IBreakoutRoomConfirmLeaveModalState>(
    'BreakoutRoomConfirmLeaveModal'
  );

  const breakoutRoomId = modalState?.breakoutRoomId;

  const form: IFormProps<never> = {
    name: 'BreakoutRoomLeave',
    action: url('api.breakoutRoomLeave', { args: { breakoutRoomId } }),
    method: 'PATCH',
    onSuccess: () => {
      store.setState({ modal: null });
    },
    onFailure: ({ err }) => {
      trackError('Error during leaving breakout room', err);
      showNotification({
        message: i18n(
          'error.something_went_wrong_generic',
          'Something went wrong.'
        ),
      });
    },
  };

  return (
    <Modal className="styled-modal small">
      <div className="modal-content centered alert-container">
        <h2>
          {i18n(
            'hint.sure_you_want_to_leave_breakout_room',
            'Are you sure you want to leave this breakout room?'
          )}
        </h2>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Form {...form}>
          <div className="actions">
            <SubmitButton
              data-testid="Modals_Breakout_Leave_button_leave"
              formName={form.name}
              className="btn min-width"
            >
              {i18n('text.leave_breakout_room', 'Leave Breakout Room')}
            </SubmitButton>
            <button
              data-testid="Modals_Breakout_Leave_button_cancel"
              type="button"
              className="btn btn-text"
              onClick={() => store.setState({ modal: null })}
            >
              {i18n('text.cancel', 'Cancel')}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
