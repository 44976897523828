import { Fetcher } from 'api/Fetcher';
import { CourseSessionContext } from 'components/main-app-container';
import { ICourseSessionDto } from 'models/CourseSessionDto';
import { useContext, useEffect, useState } from 'react';
import { useMountedState } from 'react-use';
import { expiredTokenUpdate } from 'util/expired-token-update';
import { url } from 'util/url';
import { trackError } from './trackError';
import { useTwilioSyncSubscription } from './use-twilio-sync-subscription';

export const useCourseSessionDataToken: (args: {
  courseId?: string;
}) => string | undefined = ({ courseId }) => {
  const [token, setToken] = useState<string>();
  const isMounted = useMountedState();

  useEffect(() => {
    if (!courseId) {
      setToken(undefined);
      return;
    }
    if (token || !localStorage.getItem('token')) {
      return;
    }
    Fetcher.makeRequest<{ token: string }>(url('api.courseSessionToken'), {
      method: 'POST',
      data: { courseId },
    }).then((res) => {
      if (res.isOk()) {
        if (isMounted()) {
          setToken(res.value.token);
          expiredTokenUpdate(res.value.token, () => setToken(undefined));
        }
      } else {
        trackError(
          'Error while getting token for course session sync',
          res.error
        );
      }
    });
  }, [token, courseId, isMounted]);

  return token;
};

export const useCourseSessionData: (args: {
  courseId?: string;
}) => ICourseSessionDto | undefined = ({ courseId }) => {
  const token = useCourseSessionDataToken({ courseId });
  const document = useTwilioSyncSubscription<ICourseSessionDto>({
    token,
    documentName: courseId ? `course-${courseId}` : undefined,
    loggingPrefix: 'COURSE',
  });

  return document;
};

export const useCurrentCourseSessionData = () => {
  const courseSessionData = useContext(CourseSessionContext);

  return courseSessionData;
};
