/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// This is our global store. Which is basically just an single, global JavaScript object,
// which is immutable. Meaning you cannot mutate the object directly, but instead must call
// `store.setState` or dispatch actions similar to redux.

import createStore from 'atom';
// @ts-ignore
import devtools from 'atom/devtools';
import {
  actions as requestActions,
  requestsReducer,
} from 'util/use-request/reducer';

import { Message } from '@twilio/conversations/lib/message';
import { IAppConfigsDto } from 'api/AppConfigsDto';
import { IFeatureFlagDto } from 'api/FeatureFlagsDto';
import {
  IGroupModified,
  TSelectedGroups,
  TSelectedGuests,
} from 'components/admin-course-form/small-group-manage-tab/types';
import { IMostRecentMessage } from 'components/chat/dropdown/dropdown';
import { TModalStates } from 'components/modals/TModalStates';
import {
  INotificationData,
  NOTIFICATION_STORE_KEY,
} from 'components/notification';
import { DEBUG } from 'consts';
import { TBreakoutRoomDtoModified } from 'models/BreakoutRoomDto';
import { TVideoCallUsers } from 'models/Twilio';
import { IRequest } from 'util/use-request';

interface IStoreKeys {
  isCurrentlyInLargeRoom?: boolean;
  token?: string | null;
  refresh?: string | null;
  activeConversationName?: string;
  mostRecentMessage?: IMostRecentMessage;
  guestDrawerOpen?: boolean;
  isAudioDisabled?: boolean;
  isVideoDisabled?: boolean;
  videoToken?: string;
  shouldUpdateVideoToken?: boolean;
  linkCopied?: boolean;
  [NOTIFICATION_STORE_KEY]?: INotificationData;
  screenshareStream?: MediaStream;
  audioAccess?: boolean;
  videoAccess?: boolean;
  preferredDeviceIds?: Record<string, string | undefined>;
  activeDeviceIds?: Record<string, string | undefined>;
  dominantSpeaker?: number | null;
  messages?: Record<string, Message[]>;
  browserUnsupportedModalHasShowed?: boolean;
  chatDrawerOpen?: boolean;
  previousBroadcastedVideoUrl?: string;
  appConfigs?: IAppConfigsDto[];
  featureFlags?: IFeatureFlagDto[];
  publicGuests?: TVideoCallUsers;
  gridView?: boolean;
  chatLoading?: boolean;
  returningBroadcastFromBreakoutRoom?: boolean;
  userId?: number;
  selectedGuests?: TSelectedGuests;
  selectedGroups?: TSelectedGroups;
  requests?: { [K: string]: IRequest<unknown, unknown> | undefined };
  unreadMessageCount?: Record<string, number>;
  breakoutRoomToken?: string;
  groupsAndParticipants?: IGroupModified[];
  showBreakoutRoomNotification?: boolean;
  isUpdatingBroadcast?: boolean;
  popup?: string;
  guestOffset?: number;
  sessionId?: string;
  clicks?: number;
  allLanguages?: boolean;
  currentPath?: string;
  mode?: string;
  isAudioOnlyMode?: boolean;
  breakoutRoom?: TBreakoutRoomDtoModified;
  participantCount?: number;
  largeRoom?: {
    currentPage: number;
    totalPages: number;
    speakerViewMax: number;
  };
  VideoURL: {
    values: {
      videoUrl: string;
    };
  };
  message: Record<string, string>;
}

export type IStoreAttributes = TModalStates & IStoreKeys;

// Define the global state on page load.
export const initialState: Partial<IStoreAttributes> = {
  sessionId: `${Math.random()}`,
  clicks: 0,
  guestDrawerOpen: false,
  gridView: true,
  token: process.browser ? window.localStorage.getItem('token') : '',
  allLanguages: process.browser
    ? window.location.search.includes('all-languages=true')
    : false,
  // In the browser, we initialize the currentPath prop, which is set
  // by [Router](https://github.com/inputlogic/components/tree/master/components/router)
  currentPath:
    typeof window !== 'undefined'
      ? window.location.pathname + window.location.search
      : '/',
  mode: process.browser ? localStorage.getItem('mode') || 'normal' : 'normal',
  isAudioOnlyMode: process.browser
    ? localStorage.getItem('mode') === 'audio'
    : false,
  featureFlags: [],
  chatLoading: true,
  returningBroadcastFromBreakoutRoom: false,
  modal: undefined,
};

// You can either define your reducers here, or add them later with:
// `store.addReducer(reducer)`
const reducers = [requestsReducer];

export type IStore = {
  getState: () => IStoreAttributes;
  setState: (val: Partial<IStoreAttributes>) => void;
  dispatch: (action: any) => void;
  subscribe: (listener: any) => any;
};

// If DEBUG is true, we will enable Redux devtools
export const store: IStore =
  typeof window !== 'undefined' && DEBUG
    ? // @ts-ignore
      devtools(createStore(reducers, initialState))
    : // @ts-ignore
      createStore(reducers, initialState);

export const { getState } = store;
export const { setState } = store;
export const { dispatch } = store;
export const { clearRequests } = requestActions;
export const { patchListRequest } = requestActions;

// This is a simple Provider used in the RootApp to provide the
// store instance on the React Context, so any child Component can
// access it.
export function Provider(props: any) {
  // @ts-ignore
  this.getChildContext = () => props;
}
Provider.prototype.render = (props: any) => props.children;
