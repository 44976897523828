import { useEffect, useState } from 'react';
import { store } from 'store';
import * as Video from 'twilio-video';
import { trackError } from 'util/trackError';
import { useStoreState } from 'util/use-store-path';

export const useMediaStreamTrack = (
  track?: Video.LocalAudioTrackPublication | Video.LocalVideoTrackPublication
) => {
  const [mediaStreamTrack, setMediaStreamTrack] = useState(
    track && track.track.mediaStreamTrack
  );

  useEffect(() => {
    setMediaStreamTrack(track && track.track.mediaStreamTrack);

    if (track) {
      const onStarted = () => setMediaStreamTrack(track.track.mediaStreamTrack);
      track.track.on('started', onStarted);
      return () => {
        track.track.removeListener('started', onStarted);
      };
    }
  }, [track]);

  return mediaStreamTrack;
};

export const useTracks = ({
  localParticipant,
}: {
  localParticipant?: Video.LocalParticipant | null;
}) => {
  const [tracks, setTracks] = useState<Video.LocalTrackPublication[]>([]);

  useEffect(() => {
    if (!localParticipant) return;
    const participantChange = () =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setTracks(Array.from(localParticipant.tracks, ([_a, b]) => b));
    localParticipant.on('trackPublished', participantChange);
    return () => {
      localParticipant.removeListener('trackPublished', participantChange);
    };
  }, [localParticipant]);
  return tracks;
};

const switchTrackToDeviceId = ({
  track,
  deviceId,
}: {
  track: Video.LocalVideoTrackPublication | Video.LocalAudioTrackPublication;
  deviceId: string;
}) =>
  track.track
    .restart(deviceId ? { deviceId: { exact: deviceId } } : {})
    .catch((err) => trackError('Unable to switch devices', err));

const usePreferredDevice = ({
  track,
  currentId,
  preferredId,
  availableIds,
}: {
  track?: Video.LocalVideoTrackPublication | Video.LocalAudioTrackPublication;
  currentId?: string;
  preferredId?: string;
  availableIds: string[];
}) => {
  useEffect(() => {
    if (!track || !currentId || !preferredId) {
      return;
    }
    if (
      preferredId &&
      currentId !== preferredId &&
      availableIds.includes(preferredId)
    ) {
      switchTrackToDeviceId({ track, deviceId: preferredId });
    } else if (availableIds.length && !availableIds.includes(currentId)) {
      switchTrackToDeviceId({ track, deviceId: availableIds[0] });
    } else if (
      !preferredId &&
      availableIds.length > 1 &&
      availableIds.indexOf(currentId) !== 0
    ) {
      switchTrackToDeviceId({ track, deviceId: availableIds[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, currentId, preferredId, availableIds.length]);
};

export const useAvailableDevices = () => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    const getDevices = () =>
      navigator.mediaDevices &&
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => setDevices(devices));
    if (navigator.mediaDevices) {
      navigator.mediaDevices.addEventListener('devicechange', getDevices);
    }
    getDevices();

    return () => {
      if (navigator.mediaDevices) {
        navigator.mediaDevices.removeEventListener('devicechange', getDevices);
      }
    };
  }, [setDevices]);
  return devices;
};

export const useDevices = ({
  localParticipant,
}: {
  localParticipant?: Video.LocalParticipant | null;
}) => {
  const [preferredDeviceIds = {}, setPreferredDeviceIds] = useStoreState(
    store,
    'preferredDeviceIds'
  );
  const [activeDeviceIds = {}, setActiveDeviceIds] = useStoreState(
    store,
    'activeDeviceIds'
  );
  const tracks = useTracks({ localParticipant });
  const audio = tracks.find((t) => t.kind === 'audio') as
    | Video.LocalAudioTrackPublication
    | undefined;
  const video = tracks.find((t) => t.kind === 'video') as
    | Video.LocalVideoTrackPublication
    | undefined;
  const audioTrack = useMediaStreamTrack(audio);
  const videoTrack = useMediaStreamTrack(video);
  const audioTrackId = audioTrack && audioTrack.getSettings().deviceId;
  const videoTrackId = videoTrack && videoTrack.getSettings().deviceId;
  const availableDevices = useAvailableDevices();
  const availableVideoIds = availableDevices
    .filter((d) => d.kind === 'videoinput')
    .map((d) => d.deviceId);
  const availableAudioIds = availableDevices
    .filter((d) => d.kind === 'audioinput')
    .map((d) => d.deviceId);
  useEffect(() => {
    setActiveDeviceIds({ ...activeDeviceIds, audioinput: audioTrackId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioTrackId]);
  useEffect(() => {
    setActiveDeviceIds({ ...activeDeviceIds, videoinput: videoTrackId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrackId]);

  // set preferred device ids to current ones if they aren't set.
  // otherwise device can randomly switch when going from
  // room to broadcasting and back
  useEffect(() => {
    if (!preferredDeviceIds.videoinput && videoTrackId) {
      setPreferredDeviceIds({
        ...preferredDeviceIds,
        videoinput: videoTrackId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrackId, preferredDeviceIds.videoinput]);

  useEffect(() => {
    if (!preferredDeviceIds.audioinput && audioTrackId) {
      setPreferredDeviceIds({
        ...preferredDeviceIds,
        audioinput: audioTrackId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioTrackId, preferredDeviceIds.audioinput]);

  usePreferredDevice({
    track: audio,
    currentId: audioTrackId,
    preferredId: preferredDeviceIds.audioinput,
    availableIds: availableAudioIds,
  });
  usePreferredDevice({
    track: video,
    currentId: videoTrackId,
    preferredId: preferredDeviceIds.videoinput,
    availableIds: availableVideoIds,
  });
};
