import { useNotification } from 'components/notification';
import { ETranslations, TERMS_MAPPING, TERMS_SRC_URL } from 'consts';
import parse from 'html-react-parser';
import React from 'react';
import { store } from 'store';
import { buildUrlWithUriMap } from 'util/url';
import { useRequest } from 'util/use-request';
import { ErrorPage } from './error-page';
import { Loading } from './loading';

/**
 * Uses the configured mapping to find the specific Wordpress page ID for the associated page and language.
 * @param {string} page The page name to look up
 * @param {string} lang The language code
 * @returns {number} The Wordpress page ID
 */
const getEmbedPageIdFromMapping = (
  page: keyof typeof TERMS_MAPPING,
  lang: ETranslations
): number => {
  return TERMS_MAPPING[page][lang];
};

interface EmbeddedContentProps {
  page: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback?: (arg: string) => any;
  className?: string;
}

interface IContentResponse {
  content: {
    protected: boolean;
    rendered: string;
  };
}

export const EmbeddedContent: React.FC<EmbeddedContentProps> = ({
  page,
  callback,
  className,
}) => {
  const language = (localStorage.getItem('language') || 'en') as ETranslations;
  const contentUrl = buildUrlWithUriMap(TERMS_SRC_URL, {
    page_id: getEmbedPageIdFromMapping(page, language),
  });
  const { showNotification } = useNotification();

  if (callback) {
    callback(contentUrl);
  }

  const request = useRequest<IContentResponse>(store, contentUrl, {
    noAuth: true,
  });
  const content = request.result?.content;

  if (request.error) {
    showNotification({
      type: 'error',
      message: i18n(
        'error.404_page',
        "We couldn't find the page you were looking for."
      ),
    });
    return (
      <ErrorPage
        noHead
        errorMessage={i18n(
          'error.404_page',
          "We couldn't find the page you were looking for."
        )}
      />
    );
  }

  if (!content) {
    return <Loading />;
  }

  return <div className={className}>{parse(content.rendered)}</div>;
};
