/* eslint react/jsx-fragments: "off"  */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';

interface ITriggerProps {
  className?: string;
  onClick: (ev: any) => void;
}

interface IDropdownProps {
  uid: string;
  buttonText?: string;
  Trigger?: React.FC<ITriggerProps>;
  shouldClose?: boolean;
  onChange?: (isOpen: boolean) => void;
}

export const Dropdown: React.FC<IDropdownProps> = ({
  uid,
  buttonText = 'Select',
  Trigger,
  children,
  shouldClose,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cls, setCls] = useState<string>('ae-dropdown-menu close');
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (isOpen) {
      setTimeout(() => {
        setIsOpen(false);
        setCls('ae-dropdown-menu close');
      }, 200);
    }
  });

  if (!uid) {
    throw new Error('<Dropdown> must include a uid prop.');
  }

  const handleClick = useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (!isOpen) {
        setCls('ae-dropdown-menu open');
        setIsOpen(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, uid]
  );

  useEffect(() => {
    if (shouldClose) {
      setCls('ae-dropdown-menu close');
      setIsOpen(false);
    }
  }, [shouldClose]);

  useEffect(() => {
    if (onChange) {
      onChange(isOpen);
    }
  }, [onChange, isOpen]);

  return (
    <>
      {Trigger === undefined ? (
        <button className="ae-btn-dropdown" onClick={handleClick}>
          {buttonText}
        </button>
      ) : (
        <Trigger className="ae-btn-dropdown" onClick={handleClick} />
      )}
      <div ref={ref} className={cls}>
        {children}
      </div>
    </>
  );
};
