import { API_URL } from 'consts';
import { Result } from 'neverthrow';
import { IAppConfigsDto } from './AppConfigsDto';
import { TApiError, Fetcher } from './Fetcher';

export const requestAppConfigs: () => Promise<
  Result<IAppConfigsDto[], TApiError>
> = async () => {
  return Fetcher.makeRequest<IAppConfigsDto[]>(`${API_URL}/appconfigs`, {
    noAuthToken: true,
    method: 'GET',
  });
};
