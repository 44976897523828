import { any, find } from 'ramda';
import { trackError } from './trackError';

const environments = {
  loadTesting: ['input-html.ngrok.io'],
  local: ['localhost:3000', 'localhost:3001', 'localhost:5000'],
  development: ['aoc-dev.herokuapp.com', 'aocdev.alphadevops.org'],
  integration: ['alpha-next-test.herokuapp.com', 'aoc-int.herokuapp.com'],
  production: [
    'online.alpha.org',
    'aoc-prod.herokuapp.com',
    'aocprod.alphadevops.org',
  ],
  test: ['aoc-tdd.herokuapp.com'],
};

export const environment = (() => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT) {
    return process.env.NEXT_PUBLIC_ENVIRONMENT;
  }
  if (process.env.NODE_ENV) {
    return process.env.NODE_ENV;
  }
  try {
    const { host } = window.location;
    const environmentKeys = Object.keys(
      environments
    ) as (keyof typeof environments)[];

    const current = find(
      (env) => any((v) => v === host, environments[env]),
      environmentKeys
    );
    if (!current) {
      throw new Error('No environment matching current url');
    }
    return current;
  } catch (err) {
    trackError('Environment error', err);
    return 'local';
  }
})();
