/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import Link from 'next/link';
import { Logo } from 'components/logo';
import { LanguageDropdown } from 'components/language-dropdown';
import { Dropdown } from 'components/dropdown';
import { url } from 'util/url';
import { useRequest } from 'util/use-request';
import { store } from 'store';
import React from 'react';
import { useAlphaHelp } from 'util/use-alpha-help';
import { IUserDto } from 'api/UserDto';
import { useStoreValue } from 'util/use-mapped-state';

const AdminMenuTrigger = ({ name = 'John', ...props }) => (
  <div className="admin-menu-trigger">
    <div className="trigger" {...props}>
      <div className="name">{name}</div>
      <div className="icon" />
    </div>
  </div>
);

interface HeaderProps {
  noLogo?: boolean;
  noSelf?: boolean;
  noLanguageSwitcher?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  noLogo,
  noSelf,
  noLanguageSwitcher,
  ...props
}) => {
  const token = useStoreValue(store, 'token');
  const { result: me } = useRequest<IUserDto>(
    store,
    token ? url('api.me') : undefined
  );
  const onClickLogout = () => {
    const languagePreference = localStorage.getItem('language');
    localStorage.clear();
    if (languagePreference) {
      localStorage.setItem('language', languagePreference);
    }
    window.location.href = '/';
  };
  const { handleClickContact } = useAlphaHelp();

  return (
    <div className="component-header">
      {!noLogo && <Logo />}
      {noLogo && <div />}
      <div className="actions">
        {props.children}
        {!noLanguageSwitcher && <LanguageDropdown />}
        {me && !noSelf && (
          <div className="menu-dropdown-container">
            <Dropdown
              uid="admin-menu"
              Trigger={(props) => (
                <AdminMenuTrigger
                  data-testid="Page_Dashboard_button_profile_menu"
                  name={me.fullName || 'Menu'}
                  {...props}
                />
              )}
            >
              <ul>
                <li
                  data-testid="Page_Dashboard_button_edit_profile"
                  className="icon settings"
                >
                  <Link href={url('common.profileEdit')}>
                    {i18n('text.edit_profile', 'Edit Profile')}
                  </Link>
                </li>
                <li
                  data-testid="Page_Dashboard_button_help_center"
                  className="icon help"
                >
                  <a onClick={handleClickContact}>
                    {i18n('text.help_center', 'Help Center')}
                  </a>
                </li>
                <li className="icon sign-out">
                  <button
                    onClick={onClickLogout}
                    data-testid="Page_Dashboard_button_logout"
                  >
                    {i18n('text.logout', 'Logout')}
                  </button>
                </li>
              </ul>
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
};
