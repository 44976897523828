/* eslint-disable camelcase */
import { ETranslations, SUPPORTED_LANGUAGES } from 'consts';
import languages from 'public/i18n/supported-languages.json';
import { useMemo } from 'react';
import { store } from 'store';
import { supportedLanguageNames } from 'util/supported-language-names';
import { useStoreValue } from 'util/use-mapped-state';

export const mapLanguageIfExists = (
  languageName: keyof typeof SUPPORTED_LANGUAGES_NAMING_MAPPING
) => {
  const SUPPORTED_LANGUAGES_NAMING_MAPPING = supportedLanguageNames();
  if (SUPPORTED_LANGUAGES_NAMING_MAPPING[languageName])
    return SUPPORTED_LANGUAGES_NAMING_MAPPING[languageName];
  return languageName;
};

export const useSupportedLanguages = () => {
  const allLanguages = useStoreValue(store, 'allLanguages');
  const supportedLangs = useMemo(() => {
    const appLanguages = languages
      .filter(
        (l) =>
          allLanguages || SUPPORTED_LANGUAGES.includes(l.code as ETranslations)
      )
      .map((l) => ({
        ...l,
        name: mapLanguageIfExists(
          l.name as unknown as keyof ReturnType<typeof supportedLanguageNames>
        ),
      }));
    const result = [];
    SUPPORTED_LANGUAGES.forEach((supported_lang) => {
      const langIndex = appLanguages.findIndex(
        (l) => l.code === supported_lang
      );
      if (langIndex >= 0) {
        result.push(appLanguages[langIndex]);
        appLanguages.splice(langIndex, 1);
      }
    });
    result.push(...appLanguages);
    return result;
  }, [allLanguages]);
  return {
    supportedLangs,
  };
};
