import { Modal } from 'components/modal';
import React from 'react';

export const BrowserCompatibilityModal: React.FC = () => {
  return (
    <Modal className="styled-modal terms-modal browser-compatibility">
      <div className="modal-content small alert-container">
        <h2 className="align-left">
          {i18n(
            'text.you_are_using_an_unsupported_browser',
            'You are about to use AlphaNow on an unsupported browser.'
          )}
        </h2>
        <p className="align-left terms-info">
          {i18n(
            'text.alphanow_supports_these_browsers',
            'AlphaNow supports the following web browsers'
          )}
        </p>
        <ol type="i" className="align-left terms-info">
          <li>
            {i18n(
              'hint.supported_browsers_listed_1',
              'Google Chrome on macOS, Microsoft Windows, and Android'
            )}
          </li>
          <li>{i18n('hint.supported_browsers_listed_2', 'Safari on iOS')}</li>
        </ol>
        <p className="align-left terms-info">
          {i18n(
            'hint.for_the_best_experience',
            'For the best user experience, please use the latest version of Chrome on macOS or Windows.'
          )}
        </p>
      </div>
    </Modal>
  );
};
