import { Modal } from 'components/modal';
import React from 'react';
import { store } from 'store';
import { useGetModalState } from './use-get-modal-state';

export interface IMinimumParticipantModalState {
  onTurnOffRule: () => void;
}

export const MinimumParticipantModal: React.FC = () => {
  const modalState = useGetModalState<IMinimumParticipantModalState>(
    'MinimumParticipantModal'
  );

  return (
    <Modal className="styled-modal">
      <div className="modal-content alert-container">
        <h2>
          {i18n(
            'text.turn_off_3_participant_minimum_rule',
            'Turn Off the 3-Participant Minimum Rule'
          )}
        </h2>
        <p>
          {i18n(
            'warn.default_setting_for_3_participant_minimum',
            'The default setting is for a minimum of 3 participants to start a small group session or to create a breakout room. Please only turn this setting off if you have permission from your organisation’s Safeguarding (Protection of Vulnerable People) Officer or person responsible for online safety as you and your organisation are at increased risk in this respect.'
          )}
        </p>
        <div className="actions">
          <button
            data-testid="Modals_Minimum_Participant_button_off"
            className="btn min-width"
            onClick={() => {
              store.setState({ modal: null });
              modalState?.onTurnOffRule();
            }}
          >
            {i18n('text.turn_off', 'Turn Off')}
          </button>
          <button
            data-testid="Modals_Minimum_Participant_button_cancel"
            type="button"
            className="btn btn-text"
            onClick={() => store.setState({ modal: null })}
          >
            {i18n('text.cancel', 'Cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
